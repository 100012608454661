import { useCommonState } from '@/hooks/app/useCommonState';
import { languageSettings, useTranslate } from '@/i18n';
import { Form, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect } from 'react';

export const ContChangeLanguage: React.FC = () => {
  const { language, setState: setCommonState } = useCommonState();
  const $t = useTranslate();
  const [form] = useForm();
  useEffect(() => {
    form.setFieldsValue({ language });
  }, []);

  return (
    <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
      <Form.Item label={$t('settings.language')} name='language'>
        <Select
          options={Object.keys(languageSettings).map((key) => ({
            value: key,
            label: languageSettings[key],
          }))}
          onChange={(language) => setCommonState({ language })}
        />
      </Form.Item>
    </Form>
  );
};
