export enum EInitializeStates {
  INIT_USER_INFO,
  FINISH,
}

export interface ICommonState {
  appInitialize: {
    state: EInitializeStates;
    msg: string;
    error: string;
  };
  theme: string;
  language: string;
  width: number;
  height: number;
  isMobileSize: boolean;
  contentHeight: number;
  page: number;
  pageSize: number;
}

export type Nullable<T> = T | null;
