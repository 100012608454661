import { storageApis } from '@/actions/storage';
import { IListDetailField } from '@/containers/common/ContListTable';
import { ContAppSettings } from '@/containers/settings/ContAppSettings';
import { ContChangeLanguage } from '@/containers/settings/ContChangeLanguage';
import { ContChangePassword } from '@/containers/settings/ContChangePassword';
import { ContUploadLogo } from '@/containers/settings/ContUploadLogo';
import { ContUserTable } from '@/containers/settings/ContUserTable';
import { LOYALTY_SETTING_NAME } from '@/hooks/app/useAppSettingState';
import { useCommonState } from '@/hooks/app/useCommonState';
import { useTranslate } from '@/i18n';
import { SwitchState } from '@/renderers/RendererSwitch';
import { IList } from '@/types/storage.types';
import { Card, Form, Space, Tabs } from 'antd';
import React from 'react';
import { ContCheckScopes } from '../containers/common/ContCheckScopes';

export const PageSettings: React.FC = () => {
  const $t = useTranslate();
  const { isMobileSize } = useCommonState();

  const renderBooleanField = (key: string, field: IListDetailField, row: IList) => (
    <Form.Item key={key} name={key} label={field.title}>
      <SwitchState initialChecked={row.value[key]} />
    </Form.Item>
  );

  return (
    <Card title={$t('settings.title')}>
      <Tabs
        tabBarStyle={{ margin: 0 }}
        defaultActiveKey='common'
        items={[
          {
            key: 'common',
            label: $t('settings.title'),
            children: (
              <Space style={{ width: '100%' }} direction='vertical'>
                <Card title={$t('settings.language')}>
                  <ContChangeLanguage />
                </Card>
                <Card title={$t('settings.changePassword.title')}>
                  <ContChangePassword />
                </Card>
                <ContCheckScopes scopes={['admin']} error={null}>
                  <Card
                    title={$t('user.title')}
                    {...(!isMobileSize ? {} : { bodyStyle: { padding: 0 } })}>
                    <ContUserTable />
                  </Card>
                  <div>&nbsp;</div>
                  <Card
                    title={$t('settings.logo')}
                    {...(!isMobileSize ? {} : { bodyStyle: { padding: 0 } })}>
                    <ContUploadLogo />
                  </Card>
                </ContCheckScopes>
              </Space>
            ),
          },
          {
            key: 'app-settings',
            label: $t('settings.app.title'),
            children: (
              <ContAppSettings
                detailFieldsDict={{
                  [LOYALTY_SETTING_NAME]: {
                    exchangeRate: {
                      title: 'Tỷ lệ chuyển đổi (VND)',
                      required: true,
                      display: false,
                      type: 'number',
                    },
                    pointExpireAfter: {
                      title: 'Thời hạn điểm (ngày)',
                      required: true,
                      display: false,
                      type: 'number',
                    },
                    diamondThreshold: {
                      title: 'Ngưỡng diamond',
                      required: true,
                      display: false,
                      type: 'number',
                    },
                    vipThreshold: {
                      title: 'Ngưỡng VIP',
                      required: true,
                      display: false,
                      type: 'number',
                    },
                    memberThreshold: {
                      title: 'Ngưỡng member',
                      required: true,
                      display: false,
                      type: 'number',
                    },
                    isActive: {
                      title: 'Trạng thái',
                      tableRender: (val, { key, mobileRowTitle, ...row }, refresh) => (
                        <SwitchState
                          initialChecked={val.isActive}
                          onChange={(val) =>
                            storageApis
                              .updateList({ ...row, value: { ...row.value, isActive: val } })
                              .then(refresh)
                          }
                        />
                      ),
                      formRender: renderBooleanField,
                    },
                  },
                }}
              />
            ),
          },
        ]}
      />
    </Card>
  );
};
