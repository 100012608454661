import { setState } from '@/actions/login';
import { useSelector } from 'redux-zero/react';
import { ILoginState } from '@/types/user.types';
import { IAppState } from '@/hooks/app/store';

export const USER_CREDENTIAL_TYPE = 'user';

export const ROLES = {
  operator: [
    'auth',
    'admin',
    'auth-admin',
    'banking-admin',
    'gift-admin',
    'profile',
    'topup',
    'report',
  ],
  admin: [
    'auth',
    'admin',
    'auth-admin',
    'banking-admin',
    'gift-admin',
    'profile',
    'topup',
    'report',
  ],
};

export const useLoginState = () => {
  return { ...useSelector((state: IAppState) => state.loginState), setState } as ILoginState & {
    setState: (data: Partial<ILoginState>) => void;
  };
};
