import { FC, useState } from 'react';
import { ContInventoryEditModal } from '@/containers/rewards/ContInventoryEditModal';
import { useGroupedPrizeList, useRewardState } from '@/hooks/app/useRewardState';
import { rewardActions } from '@/actions/reward';
import { useEffectAsync } from '@/hooks/common/useEffectAsync';
import { CompDataTable } from '@/components/CompDataTables';
import { useCommonState } from '@/hooks/app/useCommonState';

export const ContRewardsTable: FC = () => {
  const [refreshId, setRefreshId] = useState(0);
  const { prizeTypes, prizeList } = useRewardState();
  const { groupedPrizeList } = useGroupedPrizeList();
  const [inventories, setInventories] = useState({});
  const { isMobileSize } = useCommonState();
  const refresh = () => setRefreshId(Math.random());

  useEffectAsync(
    () => rewardActions.countWarehouse({ prizeIds: prizeList.map((item) => item.id) }),
    [prizeList, refreshId],
    {
      onSuccess: (arr) =>
        setInventories(arr.reduce((total, item) => ({ ...total, [item.label]: item.amount }), {})),
    }
  );

  return (
    <CompDataTable
      columns={[
        {
          dataIndex: 'name',
          key: 'name',
          title: 'Tên giải thưởng',
          render: (val, row: any) =>
            !row.children ? val : prizeTypes.find((item) => item.id === val)?.name || val,
        },
        {
          dataIndex: 'id',
          key: 'id',
          title: 'Mã giải thưởng',
        },
        {
          dataIndex: 'unlimited',
          title: 'Số lượng quà trong kho',
          align: 'center',
          render: (val, obj: any) =>
            val !== undefined && (
              <ContInventoryEditModal
                prizeItem={{ ...obj, inventory: inventories[obj.id] || 0 }}
                refresh={refresh}
              />
            ),
        },
      ]}
      datasource={groupedPrizeList.map((item) => ({
        ...item,
        children: item.children.map((item) => ({
          ...item,
          mobileRowTitle: `${item.name}`,
        })),
        mobileRowTitle: prizeTypes.find((prize) => prize.id === item.name)?.name || item.name,
      }))}
      totals={groupedPrizeList.length}
      mobileMode={isMobileSize}
    />
  );
};
