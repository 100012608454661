import React from 'react';
import { Pagination } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import { useCommonState } from '@/hooks/app/useCommonState';

interface IPaginationProps extends PaginationProps {
  displayTotal?: boolean;
}

export const CompPagination: React.FC<IPaginationProps> = (props: IPaginationProps) => {
  const { page, pageSize, setState } = useCommonState();
  const { total, displayTotal = true } = props;

  return (
    <>
      <Pagination
        {...props}
        size='small'
        showSizeChanger={true}
        current={page}
        pageSize={pageSize}
        onChange={(page: number, pageSize?: number) => setState({ page, pageSize })}
        onShowSizeChange={(current: number, size: number) => setState({ page: 1, pageSize: size })}
        pageSizeOptions={['10', '20', '30', '50']}
        style={{ display: 'inline-block' }}
      />
      {displayTotal && <span style={{ float: 'right' }}>&nbsp;of {total} items</span>}
    </>
  );
};
