import store from '@/hooks/app/store';
import { IList } from '@/types/storage.types';
import { storageApis } from './storage';
import { mutateRequest } from '@/wsgw';
import { BRANCH_SELECTOR, SHOP_ITEM_SELECTOR } from '@/hooks/app/useShopState';
import { IShopState } from '@/types/shop.types';
import { userProfileApis } from './users-profile';
import { USER_CREDENTIAL_TYPE } from '@/hooks/app/useLoginState';
import crypto from 'crypto-js';

export const setState = (state: Partial<IShopState>) => {
  const prevState = store.getState().shopState;
  store.setState({ shopState: { ...prevState, ...state } });
};

export const shopApis = {
  buyItem: (params: { id: number; credential: string }) => {
    return userProfileApis
      .searchUser({
        type: USER_CREDENTIAL_TYPE,
        credential: crypto.MD5(params.credential).toString(crypto.enc.Base64),
      })
      .then(({ id: userId }) =>
        mutateRequest('gami', 'buyItemForUser', { ...params, userId }, { timeout: 30000 })
      );
  },
};

export const shopActions = {
  listShopItem: () => {
    const { currentCampaignId } = store.getState().campaignState;
    const { clientId } = store.getState().loginState;
    return storageApis
      .getList({
        where: {
          client_id: clientId,
          user_id: SHOP_ITEM_SELECTOR.replace('%campaignId', currentCampaignId),
          label: {
            not: '',
          },
        },
        skip: 0,
        take: Number.MAX_SAFE_INTEGER,
      })
      .then((shopItemList) => setState({ shopItemList }));
  },
  listBranch: () => {
    const { clientId } = store.getState().loginState;
    return storageApis
      .getList({
        where: {
          client_id: clientId,
          user_id: BRANCH_SELECTOR,
          label: {
            not: '',
          },
        },
        skip: 0,
        take: Number.MAX_SAFE_INTEGER,
      })
      .then((branchList) => {
        setState({ branchList });
        return branchList;
      });
  },
  createShopItem: (params: Omit<IList, 'id'>) => {
    return storageApis.addList({
      unique: true,
      data: params,
    });
  },
  updateShopItem: (params: IList) => storageApis.updateList(params),
};
