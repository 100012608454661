import { Image, message } from 'antd';
import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger';
import axios from 'axios';
import { FC, useState } from 'react';
import { PictureOutlined } from '@ant-design/icons';

export interface ICompImageUpload extends DraggerProps {
  limitMb?: number;
  resizeWidth?: number;
  resizeHeight?: number;
  onFinish?: (data?: Blob) => void;
}

export const CompImageUpload: FC<ICompImageUpload> = ({
  action,
  limitMb = 5,
  resizeWidth,
  resizeHeight,
  onFinish,
  ...draggerProps
}) => {
  const [previewImage, setPreviewImage] = useState('');

  return (
    <Dragger
      {...draggerProps}
      method='PUT'
      action={action}
      accept={'.jpg, .jpeg, .png'}
      customRequest={async (option) => {
        const { file, action, onSuccess, onError, onProgress } = option;
        let uploadData = file as Blob;
        if (resizeWidth || resizeHeight) {
          uploadData = await new Promise<Blob>((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file as Blob);
            reader.onload = () => {
              const img = document.createElement('img');
              img.src = reader.result as string;
              img.onload = () => {
                const canvas = document.createElement('canvas');
                const ratio = img.naturalWidth / img.naturalHeight;
                const newWidth = resizeWidth ? resizeWidth : resizeHeight * ratio;
                const newHeight = resizeHeight ? resizeHeight : resizeWidth / ratio;
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext('2d')!;
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                canvas.toBlob((result) => resolve(result));
              };
            };
          });
        }

        let response: Blob;
        await axios
          .put(action, uploadData, {
            onUploadProgress: (e) => {
              onProgress({ percent: (e.loaded / e.total) * 100 });
            },
          })
          .then((res) => {
            response = uploadData;
            setPreviewImage(URL.createObjectURL(uploadData));
            onSuccess(res.data);
          })
          .catch((err) => onError(err));
        onFinish && onFinish(response);
      }}
      beforeUpload={async (file) => {
        if (file.size / 1024 / 1024 > limitMb) {
          message.error(`Image size must be less than ${limitMb}MB`);
          return false;
        }
        return true;
      }}>
      {!previewImage && (
        <p className='ant-upload-drag-icon'>
          <PictureOutlined />
        </p>
      )}
      {previewImage && <Image src={previewImage} preview={false} />}
    </Dragger>
  );
};
