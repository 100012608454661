import store from '@/hooks/app/store';
import { ILoginState } from '@/types/user.types';
import { mutateRequest, request, wsgw } from '@/wsgw';
import { Buffer } from 'buffer/';
import cookies from 'js-cookie';

export const setState = (state: Partial<ILoginState>) => {
  const prevState = store.getState().loginState;
  store.setState({ loginState: { ...prevState, ...state } });
};

export const loginApis = {
  login: (params: { clientId: string; type: string; credential: string; secret: string }) =>
    request('auth', 'login', params),
  listUser: (params: {}) => request('gami', 'listUser', params),
  createUser: (params) => mutateRequest('auth', 'registerOmsUser', params),
  activeUser: (params: { credential: string; type: string; active: boolean }) =>
    mutateRequest('gami', 'activeUser', params),
  changePassword: (params: { secret: string; SECRET: string }) =>
    mutateRequest('auth', 'updatePassword', params),
};

export const loginActions = {
  login: ({
    remember,
    ...params
  }: Parameters<typeof loginApis.login>[0] & {
    remember?: boolean;
  }) =>
    loginApis.login(params).then((res) => {
      const { token, info } = res;
      const { userId, credential, scopes, expiredAt } = info;
      const authInfo = Buffer.from(
        JSON.stringify({ username: params.credential, password: params.secret, expiredAt })
      ).toString('hex');
      !remember ? cookies.remove('token') : cookies.set('token', token, { path: '/', expires: 1 });
      !remember ? localStorage.removeItem('auth') : localStorage.setItem('auth', authInfo);
      setState({
        credential,
        userId,
        username: loginHelpers.parseUsername(userId),
        scopes,
      });
    }),
  initUserInfo: () => {
    const stateClientId = store.getState().loginState.clientId;
    return wsgw.then((wsgw) => {
      // @ts-ignore
      localStorage.debug && (window.wsgw = wsgw);
      return wsgw.getInfo().then(async (info) => {
        if (info) {
          const { clientId, userId, credential, scopes } = info;
          if (clientId !== stateClientId) {
            loginActions.logoutAndReload();
            return;
          }
          setState({
            credential,
            userId,
            username: loginHelpers.parseUsername(userId),
            scopes,
            isLogged: true,
          });
        }
      });
    });
  },
  logout: () => {
    cookies.remove('token');
    localStorage.removeItem('auth');
  },
  logoutAndReload: () => {
    loginActions.logout();
    location.reload();
  },
};

export const loginHelpers = {
  parseUsername: (userId: string) => userId.slice(userId.indexOf('-') + 1),
};
