import { commonHelpers } from '@/actions/common';
import { userProfileApis } from '@/actions/users-profile';
import { CompDataTable } from '@/components/CompDataTables';
import { useCommonState } from '@/hooks/app/useCommonState';
import { useDynamicState } from '@/hooks/app/useDynamicState';
import { useEffectAsync } from '@/hooks/common/useEffectAsync';
import { VN_DATETIME_FORMAT, format } from '@/moment';
import { EBankingAccountNames } from '@/types/campaign.types';
import { IUserProfileInfo } from '@/types/user.types';
import { FC } from 'react';

export const ContUserLoyalty: FC<{ info: IUserProfileInfo }> = ({ info }) => {
  const { id: userId } = info;
  const { isMobileSize } = useCommonState();
  const initialState = { items: [], totals: 0, loading: false };
  const {setState: setTransactions, ...transactions} = useDynamicState<typeof initialState>(
    'user-loyalty-points',
    initialState
  );

  useEffectAsync(
    async () => {
      setTransactions({ ...initialState, loading: true });
      return userProfileApis.loyaltyPoints({
        userId,
        accountName: EBankingAccountNames.LOYALTY,
      });
    },
    [],
    {
      onSuccess: (res) => setTransactions({ items: res, totals: res.length }),
      onFinally: () => setTransactions({ loading: false }),
    }
  );
  return (
    <>
      <div style={{ marginTop: 10, fontWeight: 'bold' }}>{`Khả dụng: ${
        transactions.items[0]?.total_point || 0
      } điểm`}</div>
      <CompDataTable
        columns={[
          {
            title: 'Điểm',
            dataIndex: 'amount',
            render: (val) => commonHelpers.toVnPrice(val),
          },
          {
            title: 'Đã dùng',
            dataIndex: 'redeemed',
            render: (val) => commonHelpers.toVnPrice(val),
          },
          {
            title: 'Còn lại',
            dataIndex: 'amount',
            render: (val, row: any) => commonHelpers.toVnPrice(val - row.redeemed),
          },
          {
            title: 'Ngày hết hạn',
            dataIndex: 'expired_at',
            render: (val) => format(val, VN_DATETIME_FORMAT),
          },
        ]}
        datasource={transactions.items.map((item) => ({
          ...item,
          mobileRowTitle: `${commonHelpers.toVnPrice(item.amount)}`,
        }))}
        totals={transactions.totals}
        pagination={false}
        editable={false}
        loading={transactions.loading}
        mobileMode={isMobileSize}
      />
    </>
  );
};
