import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Progress,
  Radio,
  Select,
  Space,
  message,
} from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useCommonState } from '@/hooks/app/useCommonState';
import { CompDataTable } from '@/components/CompDataTables';
import { useRewardState, useVoucherPrizeList } from '@/hooks/app/useRewardState';
import { CompReadExcel } from '@/components/CompReadExcel';
import { rewardApis } from '@/actions/reward';
import { useEffectAsync } from '@/hooks/common/useEffectAsync';
import { commonActions, commonHelpers } from '@/actions/common';
import { useForm } from 'antd/lib/form/Form';
import { CompInputNumber } from '@/components/CompInputNumber';

export const ContVoucherInventory: FC = () => {
  const { prizeList } = useRewardState();
  const { page, pageSize, isMobileSize } = useCommonState();
  const [vouchers, take] = useState({ items: [], totals: 0 });
  const [refreshId, setRefreshId] = useState(0);
  const voucherPrizes = useVoucherPrizeList().voucherPrizeList.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  const [voucherType, setVoucherType] = useState('');
  const [isAddingVoucher, setAddingVoucher] = useState(false);
  const [addVoucherForm] = useForm();
  const [addVoucherMode, setAddVoucherMode] = useState('file');
  const [generaVoucherProgress, setGeneraVoucherProgress] = useState('');
  const refresh = () => setRefreshId(Math.random());

  useEffect(() => {
    !voucherType && voucherPrizes.length && setVoucherType(voucherPrizes[0].value);
  }, [prizeList]);

  useEffectAsync(
    async () => {
      return !voucherType
        ? null
        : rewardApis.listVouchers({
            where: {
              prize_id: voucherType,
              status: 'ACTIVE',
            },
            skip: (page - 1) * pageSize,
            take: pageSize,
          });
    },
    [voucherType, page, pageSize, refreshId],
    { onSuccess: (res) => voucherType && take(res) }
  );

  const renderAddVoucherModal = () => {
    const closeModal = () => {
      setAddingVoucher(false);
    };

    return (
      <Modal
        style={{ maxWidth: !isMobileSize ? 500 : '90%' }}
        title={'Add vouchers'}
        open={isAddingVoucher}
        closable={true}
        onOk={async () => {
          addVoucherForm.submit();
        }}
        onCancel={closeModal}>
        <Form
          form={addVoucherForm}
          preserve={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          onFinish={async (values) => {
            if (!voucherType) {
              return message.warn('Please select voucher type.');
            }
            if (addVoucherMode === 'generate') {
              const { quantity, prefix, codeLength, value, expireAt } = values;
              const BATCH_SIZE = 500;
              const createdCodes = new Set<string>();
              let shouldBreak = false;
              setGeneraVoucherProgress('0');

              while (createdCodes.size < quantity) {
                const remaining = quantity - createdCodes.size;
                const batch = Math.min(BATCH_SIZE, remaining);
                await rewardApis
                  .generateVoucher({
                    prizeId: voucherType,
                    quantity: batch,
                    prefix,
                    codeLength,
                    value,
                    expireAt,
                  })
                  .then(({ successCodes }) => {
                    const prevSize = createdCodes.size;
                    successCodes.forEach((code) => createdCodes.add(code));
                    if (createdCodes.size === prevSize) {
                      // all generated codes duplicated
                      shouldBreak = true;
                    }
                    setGeneraVoucherProgress(((createdCodes.size / quantity) * 100).toFixed(1));
                  })
                  .catch((err) => {
                    shouldBreak = true;
                  });
                if (shouldBreak) {
                  break;
                }
              }
              if (createdCodes.size) {
                message.info(`Perform successfully for ${createdCodes.size} voucher(s).`, 5);
                commonHelpers.downloadCsv({
                  dataKeys: ['code', 'link', 'value', 'expire_at'],
                  headers: ['code', 'link', 'value', 'expireAt'],
                  fetchApi: async () => {
                    return Array.from(createdCodes).map((code) => ({
                      code,
                      link: undefined,
                      value,
                      expire_at: expireAt.toISOString(),
                    }));
                  },
                });
                refresh();
                closeModal();
              }
            }
          }}>
          <Form.Item name={'mode'} label='Mode'>
            <Select
              style={{ width: '100%' }}
              options={[
                { value: 'file', label: 'From file' },
                { value: 'generate', label: 'Generate' },
              ]}
              onChange={(val) => setAddVoucherMode(val)}
            />
          </Form.Item>
          {addVoucherMode === 'file' && (
            <Form.Item wrapperCol={{ offset: 8, span: 12 }}>
              <CompReadExcel
                onChange={async (data) => {
                  if (!voucherType) {
                    return message.warn('Please select voucher type.');
                  }

                  let successCount = 0;
                  await Promise.allSettled(
                    data.map((item) =>
                      rewardApis
                        .addVoucher({ ...item, link: item.link || '', prizeId: voucherType })
                        .then(
                          (codes) => codes.every((item) => item?.code === 200) && successCount++
                        )
                    )
                  )
                    .then(() => {
                      successCount !== data.length &&
                        message.error('Some or all voucher(s) already exist.');
                      successCount &&
                        message.info(`Add ${successCount} voucher(s) successfully.`, 5);
                    })
                    .finally(refresh);
                  closeModal();
                }}
              />
            </Form.Item>
          )}
          {addVoucherMode === 'generate' && (
            <>
              <Form.Item
                name='quantity'
                label='Quantity'
                rules={[{ required: true, message: 'bắt buộc' }]}>
                <CompInputNumber min={1} max={1e5} />
              </Form.Item>
              <Form.Item name='prefix' label='Prefix' extra='Use prefix to avoid duplicated codes'>
                <Input />
              </Form.Item>
              <Form.Item
                name='codeLength'
                label='Code length'
                rules={[
                  { required: true, message: 'bắt buộc' },
                  {
                    validator: async (_rule, val) => {
                      if (val < 4 || val > 32) {
                        return Promise.reject('Length must be between 4 and 32');
                      }
                    },
                  },
                ]}>
                <CompInputNumber min={0} />
              </Form.Item>
              <Form.Item
                name='value'
                label='Value'
                rules={[{ required: true, message: 'bắt buộc' }]}>
                <CompInputNumber min={0} />
              </Form.Item>
              <Form.Item
                name='expireAt'
                label='Expire at'
                rules={[{ required: true, message: 'bắt buộc' }]}>
                <DatePicker showTime={true} />
              </Form.Item>
              {!!generaVoucherProgress && (
                <>
                  adding...
                  <Progress showInfo={true} percent={+generaVoucherProgress} />
                </>
              )}
            </>
          )}
        </Form>
      </Modal>
    );
  };

  return (
    <>
      {renderAddVoucherModal()}
      <Space style={{ marginTop: 10 }}>
        <Radio.Group
          optionType='button'
          buttonStyle='solid'
          options={voucherPrizes}
          onChange={(e) => setVoucherType(e.target.value)}
          value={voucherType}
        />
      </Space>
      <CompDataTable
        columns={[
          { title: 'Prize', dataIndex: 'prize_id' },
          { title: 'Link', dataIndex: 'link' },
          { title: 'Code', dataIndex: 'code' },
          { title: 'Value', dataIndex: 'value' },
          {
            title: 'Created At',
            dataIndex: 'created_at',
            render: (createdAt) => moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
          },
          {
            title: 'Expire At',
            dataIndex: 'expire_at',
            render: (expireAt) => moment(expireAt).format('YYYY-MM-DD HH:mm:ss'),
          },
          { title: 'Status', dataIndex: 'status' },
        ]}
        editable={false}
        datasource={vouchers.items.map((item) => ({ ...item, mobileRowTitle: `${item.code}` }))}
        totals={vouchers.totals}
        mobileMode={isMobileSize}
        buttons={[
          <Button
            onClick={() => {
              setGeneraVoucherProgress('');
              setAddingVoucher(true);
              addVoucherForm.resetFields();
              addVoucherForm.setFieldValue('mode', addVoucherMode);
            }}>
            Add voucher
          </Button>,
        ]}
        onDelete={(items) => {
          if (items.some((item) => item.status !== 'ACTIVE')) {
            return message.warn('Cannot delete claimed voucher.');
          }
          Promise.allSettled(
            items.map(({ id, prize_id }) => rewardApis.delVoucher({ id, prizeId: prize_id }))
          ).then((res) => {
            const successCount = res.filter((item) => item.status === 'fulfilled').length;
            successCount && message.info(`Remove ${successCount} voucher(s) successfully.`);
            commonActions.cmsLog(
              `Xóa voucher ${Object.values(items)
                .map(({ id, prize_id }) => `${prize_id}:${id}`)
                .join('|')}`
            );
            refresh();
          });
        }}
      />
    </>
  );
};
