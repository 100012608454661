import cookies from 'js-cookie';

function getWsgwHost() {
  let wsgwHost = window.location.origin
    .replace(/[?#].*$/, '')
    .replace(/\/?(cms\/?)?$/, '/api/ws')
    .replace(/^http/, 'ws');
  if (['localhost', '127.0.0.1'].includes(window.location.hostname)) {
    wsgwHost = wsgwHost.replace(window.location.host, '127.0.0.1:8082');
  }

  const url = new URL(wsgwHost);
  cookies.get('token') && url.searchParams.set('token', cookies.get('token'));
  return url.toString();
}

export const WSGW_HOST = getWsgwHost();
