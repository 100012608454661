import { storageApis } from '@/actions/storage';
import { CompDataTable } from '@/components/CompDataTables';
import { useCampaignState, useMissionList } from '@/hooks/app/useCampaignState';
import { useCommonState } from '@/hooks/app/useCommonState';
import { useLoginState } from '@/hooks/app/useLoginState';
import { request } from '@/wsgw';
import { Form, Input, Modal, Select, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { commonHelpers } from '@/actions/common';
import { MISSION_GROUP_SELECTOR, useMissionGroupList } from '@/hooks/app/useCappingState';
import { cappingActions, cappingHelpers } from '@/actions/capping';

export const MissionGroupDetail: FC<{ groupId: string; onClose?: () => void }> = ({
  groupId,
  onClose,
}) => {
  const { page, pageSize, isMobileSize } = useCommonState();
  const [memberList, setMemberList] = useState({ total: 0, items: [] });
  const [refreshId, setRefreshId] = useState(0);
  const { missionList } = useMissionList();

  const refresh = () => setRefreshId(Math.random());

  useEffect(() => {
    groupId &&
      request('capping', 'mission-group/list', {
        group: groupId,
        skip: (page - 1) * pageSize,
        take: pageSize,
      }).then((res) => setMemberList(res));
  }, [groupId, refreshId]);

  const renderEditForm = ({ editState, editRow, ...props }) => (
    <Form {...props}>
      <Form.Item
        label='Member id'
        name='memberId'
        rules={[{ required: true, message: 'bắt buộc' }]}>
        <Select
          mode='multiple'
          options={missionList.items
            .map((item) => ({ value: item.label, label: item.value.displayName }))
            .sort((a, b) => (a.label > b.label ? 1 : -1))}
        />
      </Form.Item>
    </Form>
  );

  return (
    <Modal
      title={`Group details`}
      open={!!groupId}
      style={{ minWidth: !isMobileSize ? 800 : '90%', height: 'calc(100% + 20px)' }}
      closable={true}
      onOk={() => {
        onClose && onClose();
      }}
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={() => {
        onClose && onClose();
      }}>
      <CompDataTable
        columns={[
          {
            title: 'Member',
            dataIndex: 'itemId',
            render: (val) =>
              missionList.items.find((item) => item.label === val)?.value.displayName || val,
          },
        ]}
        datasource={memberList.items}
        totals={memberList.total}
        editingForm={renderEditForm}
        onCreate={(values) => {
          request('capping', 'mission-group/add', { group: groupId, ids: values.memberId }).then(
            refresh
          );
        }}
        onDelete={(rows) => {
          request('capping', 'mission-group/remove', {
            group: groupId,
            ids: rows.map((item) => item.itemId),
          }).then(refresh);
        }}
      />
    </Modal>
  );
};

export const ContMissionGroup: FC<{ active: boolean }> = ({ active }) => {
  const { clientId } = useLoginState();
  const { page, pageSize } = useCommonState();
  const { currentCampaignId } = useCampaignState();
  const { missionGroupList: listData } = useMissionGroupList(false);
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const selector = MISSION_GROUP_SELECTOR.replace('%campaignId', currentCampaignId);

  const refresh = () =>
    cappingActions.getMissionGroupList(
      cappingHelpers.getGroupParams(page, pageSize, clientId, selector)
    );

  useEffect(() => {
    active && !selectedGroupId && refresh();
  }, [page, pageSize, active, currentCampaignId]);

  if (!currentCampaignId) {
    return null;
  }

  const renderEditForm = ({ editState, editRow, ...props }) => (
    <Form {...props}>
      <Form.Item label='Tên' name='label' rules={[{ required: true, message: 'bắt buộc' }]}>
        <TextArea size='small' disabled />
      </Form.Item>
      <Form.Item
        label='Tên hiển thị'
        name='displayName'
        rules={[{ required: true, message: 'bắt buộc' }]}>
        <Input
          onChange={(val) =>
            props.form.setFieldValue(
              'label',
              `${selector}-${commonHelpers.normalizeTitle(val.target.value)}`
            )
          }
        />
      </Form.Item>
    </Form>
  );

  return (
    <>
      <MissionGroupDetail groupId={selectedGroupId} onClose={() => setSelectedGroupId('')} />
      <CompDataTable
        columns={[
          {
            title: 'Mã',
            dataIndex: 'id',
          },
          {
            title: 'Tên hiển thị',
            dataIndex: 'displayName',
            render: (val, row: any) => {
              return (
                <Typography.Link
                  onClick={() => {
                    setSelectedGroupId(row.label);
                  }}>
                  {val}
                </Typography.Link>
              );
            },
          },
        ]}
        datasource={listData.items.map(({ value, ...item }) => ({
          ...item,
          ...value,
          mobileRowTitle: value?.displayName,
        }))}
        totals={listData.totals}
        editingForm={renderEditForm}
        onCreate={({ label, displayName }) => {
          const listRecord = {
            label,
            client_id: clientId,
            user_id: selector,
            value: {
              displayName,
            },
          };
          return storageApis
            .addList({
              unique: true,
              data: listRecord,
            })
            .then(() => {
              refresh();
            });
        }}
      />
    </>
  );
};
