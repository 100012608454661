import store from '@/hooks/app/store';
import { APP_SETTINGS_SELECTOR } from '@/hooks/app/useAppSettingState';
import { setState as setDynamicState } from '@/hooks/app/useDynamicState';
import { IList } from '@/types/storage.types';
import { mutateRequest, request } from '@/wsgw';

export const setState = <T>(name: string, state: T) => {
  const appSettingState = store.getState().dynamicStates[APP_SETTINGS_SELECTOR];
  const prevState = (appSettingState || {})[name];
  setDynamicState(APP_SETTINGS_SELECTOR, {
    ...appSettingState,
    [name]: { ...prevState, ...state },
  });
};

export const settingApis = {
  getSetting: (params: { selector: string; name: string }) => request('gami', 'getSetting', params),
  updateSetting: ({ id, ...data }: Partial<IList>) =>
    mutateRequest('gami', 'updateSetting', { id, data }),
};

export const settingActions = {
  getSetting: (params: { name: string }) => {
    return settingApis
      .getSetting({ selector: APP_SETTINGS_SELECTOR, name: params.name })
      .then((list) => setState(params.name, list));
  },
};
