import { useSelector } from 'redux-zero/react';
import { initRewardState } from '@/hooks/app/initState';
import { IPrize, IRewardState } from '@/types/reward.types';
import { IAppState } from '@/hooks/app/store';
import { rewardActions, setState } from '@/actions/reward';
import { useEffect } from 'react';

export const PRIZE_DELIVERY_METHOD_SELECTOR = 'prize-delivery-method';

const activeFilter = (item: IPrize) => item.active;
const limitedFilter = (item: IPrize) => !item.unlimited;
const unlimitedFilter = (item: IPrize) => item.unlimited;
const voucherFilter = (item: IPrize) => item.prizeType?.voucher;

export const useRewardState = () => {
  return {
    ...useSelector((state: IAppState) =>
      Object.keys(initRewardState).reduce(
        (total, key) => ({ ...total, [key]: state.rewardState[key] }),
        {}
      )
    ),
    setState,
  } as IRewardState & { setState: (data: Partial<IRewardState>) => void };
};

export const usePrizeListWithTypes = (refresh = true, deps: Array<string> = []) => {
  const { prizeList } = useRewardState();
  refresh &&
    useEffect(() => {
      rewardActions.getPrizeTypes().then(rewardActions.getPrizeList);
    }, deps);
  return { prizeList };
};

export const usePrizeType = (refresh = true) => {
  const { prizeTypes } = useRewardState();
  refresh &&
    useEffect(() => {
      rewardActions.getPrizeTypes();
    }, []);
  return { prizeTypes };
};

export const usePrizeList = (refresh = true) => {
  const { prizeList } = useRewardState();
  refresh &&
    useEffect(() => {
      rewardActions.getPrizeList();
    }, []);
  return { prizeList };
};

export const useActivePrizeList = (refresh = true) => {
  return { activePrizeList: usePrizeList(refresh).prizeList.filter(activeFilter) };
};

export const useLimitedPrizeList = (refresh = true) => {
  return { limitedPrizeList: usePrizeList(refresh).prizeList.filter(limitedFilter) };
};

export const useUnlimitedPrizeList = (refresh = true) => {
  return { unlimitedPrizeList: usePrizeList(refresh).prizeList.filter(unlimitedFilter) };
};

export const useVoucherPrizeList = (refresh = true) => {
  return { voucherPrizeList: usePrizeList(refresh).prizeList.filter(voucherFilter) };
};

export const useActiveLimitedPrizeList = (refresh = true) => {
  return {
    activeLimitedPrizeList: useLimitedPrizeList(refresh).limitedPrizeList.filter(activeFilter),
  };
};

export function useGroupedPrizeList(refresh = true): {
  groupedPrizeList: Array<{
    key: string;
    name: string;
    children: Array<IPrize>;
  }>;
} {
  return {
    groupedPrizeList: Object.values(
      usePrizeList(refresh).prizeList.reduce((total, prize) => {
        return {
          ...total,
          [prize.type]: {
            key: prize.type,
            name: prize.prizeType?.id,
            children: [...(total[prize.type]?.children || []), prize],
          },
        };
      }, {})
    ),
  };
}
