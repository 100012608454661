export interface IPrizeType {
  id: string;
  name: string;
  description: string;
  unit: string;
  voucher: boolean;
  collectible: boolean;
}

export interface IPrize {
  id: string;
  name: string;
  amount: number;
  type: string;
  unlimited: boolean;
  active: boolean;
  prizeType: IPrizeType;
  priority: number;
  droprate: number;
}
export type ICreatePrize = Omit<IPrize, 'prizeType'>;

export enum EPrizeDeliveryMethods {
  BALANCE = 'balance',
  REWARD = 'reward',
  THIRD_PARTY_API = 'third_party_api',
  MANUAL = 'manual',
  COMPLETE = 'complete',
}

export enum RepeatType {
  NONE = 'NONE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export interface ISchedulerTask {
  id?: string;
  name: string;
  description: string;
  repeat_type: RepeatType;
  start_date: Date;
  end_date: Date;
  info?: any;
}

export interface IRewardState {
  prizeTypes: Array<IPrizeType>;
  prizeList: Array<IPrize>;
  scheduleList: Array<ISchedulerTask>;
  refreshScheduleId: number;
  isRewardLoading: boolean;
}
