import { commonHelpers } from '@/actions/common';
import { userProfileActions, userProfileApis } from '@/actions/users-profile';
import { ContAccountTransaction } from '@/containers/user-profile/ContAccountTransaction';
import { useLoyaltySettings } from '@/hooks/app/useAppSettingState';
import { useCommonState } from '@/hooks/app/useCommonState';
import { useLoginState } from '@/hooks/app/useLoginState';
import { useRequestActionState } from '@/hooks/common/useRequestActionState';
import { useTranslate } from '@/i18n';
import { IUserProfileInfo } from '@/types/user.types';
import {
  Button,
  Descriptions,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FC, useEffect, useState } from 'react';
import { ModalUpdatedForm } from './UpdateProfileModal';
import { useDynamicState } from '@/hooks/app/useDynamicState';
import { EBankingAccountNames } from '@/types/campaign.types';
import { CompInputNumber } from '@/components/CompInputNumber';
import { useBranchList } from '@/hooks/app/useShopState';

export const ContUserProfileInfo: FC<{ info: IUserProfileInfo }> = ({ info }) => {
  const { id: userId } = info;
  const $t = useTranslate();
  const { isMobileSize } = useCommonState();
  const { branchList } = useBranchList();
  const {
    request: getUserBalances,
    setState: _,
    ...userBalanceState
  } = useRequestActionState(userProfileApis.userBalances);
  const [transactionAccountName, setTransactionAccountName] = useState('');
  const [isUpdatingUser, setUpdatingUser] = useState(false);
  const [isToppingUpPoint, setToppingUpPoint] = useState(false);
  const [topupPointForm] = useForm();
  const loyaltySettings = useLoyaltySettings();
  const { clientId } = useLoginState();
  const { setState, ...state } = useDynamicState('page-user-profile', {} as any);
  const { credentialType, input } = state;

  const getMemberType = () => {
    const paymentConsumed =
      (userBalanceState.data || {})[EBankingAccountNames.PAYMENT_CONSUMED] || 0;
    const members = [
      { type: 'diamond', color: 'gold' },
      { type: 'vip', color: 'green' },
      { type: 'member', color: 'default' },
    ];
    for (const item of members) {
      if (paymentConsumed >= loyaltySettings[`${item.type}Threshold`]) {
        return item;
      }
    }
    return members[members.length - 1];
  };
  const memberType = getMemberType();

  useEffect(() => {
    getUserBalances({ userId });
  }, [userId]);

  const renderModalUpdatedProfile = () => {
    return (
      <ModalUpdatedForm
        isOpen={isUpdatingUser}
        profile={info}
        onFinish={() => {
          userProfileActions.searchUser(input, credentialType);
        }}
        onClose={() => setUpdatingUser(false)}
      />
    );
  };

  const renderTopupPointModal = () => {
    const closeModal = () => {
      setToppingUpPoint(false);
    };
    const submitForm = async () => {
      await topupPointForm.validateFields().then(async (values) => {
        const { paymentValue, branch } = values;
        await userProfileApis.topupBalance({
          userId,
          clientId,
          accountName: EBankingAccountNames.PAYMENT_CONSUMED,
          amount: paymentValue,
          serviceCode: `CMS_TOPUP`,
          description: 'Topup for user.',
          origin: branch,
        });
        getUserBalances({ userId });
      });
      closeModal();
    };

    return (
      <Modal
        style={{ maxWidth: !isMobileSize ? 500 : '90%' }}
        title={'Tích điểm'}
        open={isToppingUpPoint}
        closable={true}
        onOk={async () => {
          submitForm();
        }}
        onCancel={closeModal}>
        <Form
          form={topupPointForm}
          preserve={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}>
          <Form.Item
            key='paymentValue'
            name='paymentValue'
            label='Giá trị giao dịch'
            rules={[{ required: true, message: 'bắt buộc' }]}>
            <CompInputNumber min={0} step={1000} />
          </Form.Item>
          <Form.Item key='branch' name='branch' label='Chi nhánh'>
            {!branchList.items.length ? (
              <Input />
            ) : (
              <Select
                options={branchList.items.map(({ value }: any) => ({
                  value: value.code,
                  label: value.displayName,
                }))}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const renderUserBalances = () => {
    if (userBalanceState.loading) {
      return (
        <Descriptions.Item key={'user-balance-loading'} label={`Số dư`}>
          <Spin></Spin>
        </Descriptions.Item>
      );
    }

    if (!userBalanceState.data) {
      if (userBalanceState.error) {
        return (
          <Descriptions.Item key={'user-balance-error'} label={`Số dư`}>
            <div>{userBalanceState.error}</div>
            <br />
            <Button onClick={() => getUserBalances({ userId })}>Refresh</Button>
          </Descriptions.Item>
        );
      }
      return null;
    }

    return Object.keys(userBalanceState.data).map((key) => (
      <Descriptions.Item key={key} label={`Số dư ${key}`}>
        <Typography.Link
          onClick={() => {
            setTransactionAccountName(key);
          }}
          style={{ marginRight: 8 }}>
          {commonHelpers.toVnPrice(userBalanceState.data[key])}
        </Typography.Link>
      </Descriptions.Item>
    ));
  };

  return (
    <div style={{ marginTop: 10 }}>
      {renderModalUpdatedProfile()}
      {renderTopupPointModal()}
      <ContAccountTransaction
        userId={userId}
        accountName={transactionAccountName}
        onCancel={() => {
          setTransactionAccountName('');
          getUserBalances({ userId });
        }}
      />
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
        }}>
        <Space wrap={true}>
          <Button
            type='primary'
            style={{ minWidth: 110 }}
            onClick={() => {
              setUpdatingUser(true);
            }}>
            {'Update'}
          </Button>
          <Button
            type='primary'
            style={{ minWidth: 110 }}
            onClick={() => {
              topupPointForm.resetFields();
              setToppingUpPoint(true);
            }}>
            Tích điểm
          </Button>
        </Space>
      </div>
      <Descriptions column={1} bordered size='small'>
        <Descriptions.Item label='User ID'>{userId}</Descriptions.Item>
        <Descriptions.Item label='Member type'>
          <Tag key={'member-type'} color={memberType.color}>
            {memberType.type}
          </Tag>
        </Descriptions.Item>
        {Object.entries(info).map(([key, value]) => (
          <Descriptions.Item key={`user.info.${key}`} label={$t(`user.info.${key}`)}>
            {value}
          </Descriptions.Item>
        ))}
        {renderUserBalances()}
      </Descriptions>
    </div>
  );
};
