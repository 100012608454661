import store from '@/hooks/app/store';
import { mutateRequest, request } from '@/wsgw';
import { tz } from '@/moment';
import { message } from 'antd';
import { commonHelpers } from '@/actions/common';
import { ICreatePrize, IPrize, IPrizeType, IRewardState, RepeatType } from '@/types/reward.types';
import { campaignHelpers } from './campaign';
import { IList } from '@/types/storage.types';
import { storageApis } from './storage';

export const setState = (state: Partial<IRewardState>) => {
  const prevState = store.getState().rewardState;
  store.setState({ rewardState: { ...prevState, ...state } });
};

export const rewardApis = {
  getPrizeTypes: (params?: { where: any }) => request('gift', 'listPrizeTypes', params),
  getPrizeList: (params?: { where: any }) => request('gami', 'listPrizes', params),
  upsertPrizeType: (params: IPrizeType) =>
    mutateRequest(
      'gift',
      'upsertPrizeType',
      ['id', 'name', 'description', 'unit', 'voucher', 'collectible'].reduce(
        (total, key) => ({ ...total, [key]: params[key] }),
        {}
      )
    ),
  upsertPrize: (params: ICreatePrize) =>
    mutateRequest(
      'gami',
      'upsertPrize',
      ['id', 'name', 'amount', 'type', 'unlimited', 'active', 'priority', 'droprate'].reduce(
        (total, key) => ({ ...total, [key]: params[key] }),
        {}
      )
    ),
  addWarehouse: (params) => mutateRequest('gift', 'addWarehouse', params),
  subtractWarehouse: (params) => mutateRequest('gift', 'subtractWarehouse', params),
  createTask: (params: any) => mutateRequest('gift', 'addScheduleTask', params),
  deleteTask: (params: { id: string }) =>
    mutateRequest('gift', 'delScheduleTask', { taskId: params.id }),
  getScheduleList: (params: { active?: boolean; prizeIds: Array<string> }) =>
    request('gift', 'listScheduleTasks', params),
  countWarehouse: (params: { prizeIds: Array<string> }) =>
    request('gift', 'countWarehouse', params),
  countReward: (params: { prizeIds: Array<string> }) => request('gift', 'countReward', params),
  countStock: (params: { prizeIds: Array<string> }) => request('gift', 'countStock', params),
  clearStock: (params: { prizeIds: Array<string> }) => mutateRequest('gift', 'clearStock', params),
  listVouchers: (params: { where: any; skip: number; take: number }) =>
    request('gift', 'listVouchers', params),
  addVoucher: (params: {
    prizeId: string;
    code: number;
    link: string;
    value: number;
    expireAt: string;
  }) => request('gift', 'addVoucher', params),
  generateVoucher: (params: {
    prizeId: string;
    quantity: number;
    prefix?: string;
    codeLength: number;
    link?: string;
    value: number;
    expireAt: Date;
  }) =>
    request('gami', 'generateVouchers', params, {
      timeout: 60000,
      indicator: {
        start: false,
      },
    }),
  delVoucher: (params: { id: number; prizeId: string }) => request('gift', 'delVoucher', params),
  getFallbackPrizes: (params: { campaignId: string; skip?: number; take?: number }) =>
    request('gami', 'getFallbackPrizes', params),
  addFallbackPrizes: (params: { campaignId: string; prizeIds: Array<string> }) =>
    mutateRequest('gami', 'addFallbackPrizes', params),
  removeFallbackPrizes: (params: { campaignId: string; listIds: Array<string> }) =>
    mutateRequest('gami', 'removeFallbackPrizes', params),
  addPrizeDeliveryMethod: (params: Omit<IList, 'id'>) =>
    mutateRequest('gami', 'addPrizeDeliveryMethod', params),
  updatePrizeDeliveryMethod: (params: IList) =>
    mutateRequest('gami', 'updatePrizeDeliveryMethod', params),
};

export const rewardActions = {
  getPrizeTypes: commonHelpers.debounce(() =>
    rewardApis
      .getPrizeTypes({ where: { id: { startsWith: campaignHelpers.getCampaignPrefix() } } })
      .then((prizeTypes) => setState({ prizeTypes }))
  ),
  getPrizeList: commonHelpers.debounce(() =>
    rewardApis
      .getPrizeList({ where: { id: { startsWith: campaignHelpers.getCampaignPrefix() } } })
      .then((prizeList) => {
        const { prizeTypes } = store.getState().rewardState;
        setState({
          prizeList: prizeList.map((item) => ({
            ...item,
            key: item.id,
            prizeType: prizeTypes.find((prizeType) => prizeType.id === item.type),
          })),
        });
      })
  ),
  upsertPrizeType: (...params: Parameters<typeof rewardApis.upsertPrizeType>) => {
    return rewardApis.upsertPrizeType(...params).then(rewardActions.getPrizeTypes);
  },
  upsertPrize: (...params: Parameters<typeof rewardApis.upsertPrize>) => {
    return rewardApis.upsertPrize(...params).then(rewardActions.getPrizeList);
  },
  getScheduleList: (activeOnly = false) => {
    const { prizeList } = store.getState().rewardState;
    setState({ isRewardLoading: true });
    rewardApis
      .getScheduleList({ active: activeOnly, prizeIds: prizeList.map((item) => item.id) })
      .then((scheduleList) =>
        setState({
          scheduleList,
        })
      )
      .finally(() => setState({ isRewardLoading: false }));
  },
  refreshScheduleList: () => setState({ refreshScheduleId: Date.now() }),
  createScheduler: async (prize: IPrize, values) => {
    const { allDay, prizeId, quantity, description } = values;
    if (!prizeId || !quantity || !prize) {
      return false;
    }
    const text = [`[${quantity}]`, prize.name, description || ''].filter((a) => !!a).join(' | ');
    const [startDate, endDate] = allDay
      ? [tz(values.startDate).startOf('day'), tz(values.startDate).endOf('day')]
      : [tz(values.startDate).startOf('minute'), tz(values.endDate).endOf('minute')];
    if (tz(startDate.toDate()).endOf('day').isBefore(endDate)) {
      return message.error('Invalid End Date!');
    }

    const [repeatType, recurrenceRule] = await Promise.resolve(
      values.recurrenceRule?.match(/FREQ=(DAILY)/)
    ).then((freq) => (freq ? [freq[1], values.recurrenceRule] : [RepeatType.NONE, undefined]));
    const task = {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      name: [prize.name, quantity, startDate.valueOf(), endDate.valueOf()].join('|'),
      description,
      info: {
        text,
        prizeName: prize.name,
        quantity,
        description,
        recurrenceRule,
        prizeId: prize.id,
      },
      repeatType,
      execService: 'gift',
      execApi: 'populateRewardByPrizeId',
      execPayload: {
        prizeId: prize.id,
        amount: Number(quantity),
      },
    };
    const { execService, execApi, execPayload, ...data } = task;
    await rewardApis.createTask(data);
  },
  countWarehouse: async (...params: Parameters<typeof rewardApis.countWarehouse>) =>
    !params[0].prizeIds.length ? [] : rewardApis.countWarehouse(...params),
  countReward: async (...params: Parameters<typeof rewardApis.countReward>) =>
    !params[0].prizeIds.length ? [] : rewardApis.countReward(...params),
  countStock: async (...params: Parameters<typeof rewardApis.countStock>) =>
    !params[0].prizeIds.length ? [] : rewardApis.countStock(...params),
};
