import { Button } from 'antd';
import React, { useRef } from 'react';
import { read, utils } from 'xlsx';

interface ICompReadExcelProps {
  label?: string | JSX.Element;
  readLines?: boolean;
  onChange: (data) => any;
}

export const CompReadExcel: React.FC<ICompReadExcelProps> = ({
  label = 'Upload file',
  readLines = false,
  onChange,
}: ICompReadExcelProps) => {
  const input = useRef(null);
  const readFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const workbook = read(event.target.result, { type: 'buffer' });
      const name = workbook.SheetNames[0];
      const sheet = workbook.Sheets[name];
      const data = readLines ? utils.sheet_to_txt(sheet).split('\n') : utils.sheet_to_json(sheet);
      input.current.value = '';
      onChange(data);
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <Button type='primary' onClick={() => input.current?.click()}>
      <input ref={input} type='file' onChange={readFile} style={{ display: 'none' }} />
      {label}
    </Button>
  );
};
