import { useSelector } from 'redux-zero/react';
import store, { IAppState } from '@/hooks/app/store';

export const setState = <T>(name: string, data: Partial<T>) => {
  const state = store.getState();
  store.setState({
    dynamicStates: {
      ...state.dynamicStates,
      [name]: { ...state.dynamicStates[name], ...data },
    },
  });
};

export const useDynamicState = <T>(name: string, initialState: T) => {
  return {
    ...useSelector((state: IAppState) => {
      if (!state.dynamicStates[name]) {
        state.dynamicStates[name] = initialState;
      }
      return state.dynamicStates[name];
    }),
    setState: (data: Partial<T>) => setState(name, data),
  } as T & { setState: (data: Partial<T>) => void };
};
