import { commonHelpers } from '@/actions/common';
import { userProfileApis } from '@/actions/users-profile';
import { CompDataTable } from '@/components/CompDataTables';
import { CompInputNumber } from '@/components/CompInputNumber';
import { useCommonState } from '@/hooks/app/useCommonState';
import { useDynamicState } from '@/hooks/app/useDynamicState';
import { useLoginState } from '@/hooks/app/useLoginState';
import { useEffectAsync } from '@/hooks/common/useEffectAsync';
import { VN_DATETIME_FORMAT, format } from '@/moment';
import { Button, Form, Input, InputNumber, Modal, Space } from 'antd';
import { FC, useRef, useState } from 'react';

export const ContAccountTransaction: FC<{
  userId: string;
  accountName: string;
  onCancel: () => void;
}> = ({ userId, accountName, onCancel }) => {
  const topupForm = useRef(null);
  const { page, pageSize, isMobileSize } = useCommonState();
  const { clientId } = useLoginState();
  const [showTopupModal, setShowTopupModal] = useState(false);
  const [refreshId, setRefreshId] = useState(0);
  const initialState = { items: [], totals: 0, loading: false };
  const { setState: setTransactions, ...transactions } = useDynamicState<typeof initialState>(
    'user-account-transactions',
    initialState
  );
  const refresh = () => setRefreshId(Math.random());

  const renderTopupForm = () => (
    <Modal
      style={{ minWidth: !isMobileSize ? 800 : '90%' }}
      open={showTopupModal}
      title={`Topup ${accountName}`}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      footer={false}
      onCancel={() => setShowTopupModal(false)}>
      <Form
        ref={topupForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={{ amount: 1 }}
        onFinish={(values) => {
          const { transactionId, amount, description } = values;
          userProfileApis
            .topupBalance({
              userId,
              clientId,
              accountName,
              amount,
              serviceCode: `CMS_TOPUP:${transactionId}`,
              description,
            })
            .then(() => {
              setShowTopupModal(false);
              refresh();
            });
        }}>
        <Form.Item
          name='transactionId'
          label='Mã giao dịch'
          rules={[{ required: true, message: 'Please input.' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name='description'
          label='Mô tả'
          rules={[{ required: true, message: 'Please input.' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name='amount'
          label='Giá trị'
          rules={[{ required: true, message: 'Please input.' }]}>
          <CompInputNumber min={1} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
          <Space>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
            <Button onClick={() => setShowTopupModal(false)}>Cancel</Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );

  const renderTable = () => {
    return (
      <Modal
        style={{ minWidth: !isMobileSize ? 800 : '90%' }}
        title={`Account ${accountName}'s transactions`}
        open={!!accountName}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        closable={true}
        footer={false}
        onCancel={onCancel}>
        <CompDataTable
          buttons={[
            <Button
              key='topup'
              type='primary'
              onClick={() => {
                setShowTopupModal(true);
              }}>
              Topup
            </Button>,
          ]}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
            },
            {
              title: 'Giao dịch',
              dataIndex: 'amount',
              render: (val) => commonHelpers.toVnTransactionPrice(val),
            },
            {
              title: 'Ngày tạo',
              dataIndex: 'created_at',
              render: (val) => format(val, VN_DATETIME_FORMAT),
            },
            {
              title: 'Mã giao dịch',
              dataIndex: 'service_code',
            },
            {
              title: 'Mô tả',
              dataIndex: 'description',
            },
          ]}
          datasource={transactions.items.map((item) => ({
            ...item,
            mobileRowTitle: `${commonHelpers.toVnTransactionPrice(item.amount)} (${format(
              item.created_at,
              VN_DATETIME_FORMAT
            )})`,
          }))}
          totals={transactions.totals}
          editable={false}
          loading={transactions.loading}
          mobileMode={isMobileSize}
        />
      </Modal>
    );
  };

  useEffectAsync(
    async () => {
      setTransactions({ ...initialState, loading: true });
      if (accountName) {
        return userProfileApis.transactionLogs({
          userId,
          accountName,
          skip: (page - 1) * pageSize,
          take: pageSize,
        });
      }
    },
    [page, pageSize, accountName, refreshId],
    { onSuccess: (res) => accountName && setTransactions({ ...res, loading: false }) }
  );

  return (
    <>
      {renderTopupForm()}
      {renderTable()}
    </>
  );
};
