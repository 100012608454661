import { FC, useState } from 'react';
import { ContListTable, IListDetailField } from '../common/ContListTable';
import { Form } from 'antd';
import { IList } from '@/types/storage.types';
import { SwitchState } from '@/renderers/RendererSwitch';
import { storageApis } from '@/actions/storage';
import { APP_SETTINGS_SELECTOR } from '@/hooks/app/useAppSettingState';
import { settingApis } from '@/actions/setting';

export const ContAppSettings: FC<{
  detailFieldsDict: { [key: string]: { [key: string]: IListDetailField } };
}> = ({ detailFieldsDict }) => {
  const [editRow, setEditRow] = useState(null);

  const renderTable = () => {
    const renderBooleanField = (key: string, field: IListDetailField, row: IList) => (
      <Form.Item key={key} name={key} label={field.title}>
        <SwitchState initialChecked={row.value[key]} />
      </Form.Item>
    );
    const detailFields = detailFieldsDict[editRow?.label] || {
      description: { title: 'Mô tả', required: false, display: false },
      props: { title: 'Thuộc tính', required: false, display: false },
      isActive: {
        title: 'Trạng thái',
        tableRender: (val, { key, mobileRowTitle, ...row }, refresh) => (
          <SwitchState
            initialChecked={val.isActive}
            onChange={(val) =>
              storageApis
                .updateList({ ...row, value: { ...row.value, isActive: val } })
                .then(refresh)
            }
          />
        ),
        formRender: renderBooleanField,
      },
    };

    return (
      <ContListTable
        selector={`${APP_SETTINGS_SELECTOR}`}
        detailFields={detailFields}
        onCreate={(record) =>
          storageApis.addList({
            unique: true,
            data: {
              ...record,
              value: { ...record.value },
            },
          })
        }
        onEdit={settingApis.updateSetting}
        onStartEdit={setEditRow}
        allowDelete={true}
      />
    );
  };

  return <>{renderTable()}</>;
};
