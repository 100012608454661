import { commonApis, commonHelpers } from '@/actions/common';
import { loginActions } from '@/actions/login';
import { MOBILE_SIZE_MAX_WIDTH, defaultRoute } from '@/hooks/app/initState';
import { CAMPAIGN_SELECTOR, useCampaignState } from '@/hooks/app/useCampaignState';
import { useAppInitialzing, useCommonState } from '@/hooks/app/useCommonState';
import { useLoginState } from '@/hooks/app/useLoginState';
import { useNavState } from '@/hooks/app/useNavState';
import { EInitializeStates } from '@/types/common.types';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CompEmptyCard } from './CompEmptyCard';

let unlockRouter: any;
let entryPath: string;
export const CompInitApp: React.FC = () => {
  const { setState: setCommonState } = useCommonState();
  const { appInitialize } = useAppInitialzing();
  const { clientId, isLogged, setState: setLoginState } = useLoginState();
  const { setState: setCampaignState } = useCampaignState();
  const history = useHistory();
  const location = useLocation();
  const initState = appInitialize.state;
  const updateInitState = (state: Partial<typeof appInitialize>) => {
    setCommonState({ appInitialize: { ...appInitialize, ...state } });
  };
  const { setState: setNavState } = useNavState();
  const urlClientId = window.location.pathname.split('/').slice(1)[0];

  useEffect(() => {
    const onResize = () => {
      setCommonState({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
        contentHeight: Math.max(600, document.documentElement.clientHeight - 240),
        isMobileSize: document.documentElement.clientWidth < MOBILE_SIZE_MAX_WIDTH,
      });
    };
    addEventListener('resize', onResize);

    const url = new URL(window.location.href);
    const campaignId = !url.searchParams.get(CAMPAIGN_SELECTOR)
      ? ''
      : `${CAMPAIGN_SELECTOR}-${url.searchParams.get(CAMPAIGN_SELECTOR)}`;
    url.searchParams.delete(CAMPAIGN_SELECTOR);
    commonApis
      .getObjectUrl(`icons/${urlClientId}/logo`)
      .then((logoImg) => setLoginState({ logoImg }));
    setLoginState({ clientId: urlClientId });
    setCampaignState({ currentCampaignId: campaignId });
    if (window.history.pushState) {
      window.history.pushState({ path: url.toString() }, '', url.toString());
    }

    history.listen(({ pathname }) => {
      setNavState({ currentPathName: pathname });
    });

    return () => {
      removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (!entryPath) {
      entryPath = location.pathname;
      if (!urlClientId) {
        loginActions.logout();
        history.replace('/signup');
        return;
      }
    }

    if (!isLogged) {
      history.replace('/login');
      unlockRouter = history.block(); //block navigator
      return;
    }

    if (unlockRouter) {
      const currentPathName = `/${defaultRoute}`;
      unlockRouter();
      setNavState({ currentPathName });
      history.replace(entryPath.replace('/login', currentPathName));
      unlockRouter = undefined;
    }
  }, [isLogged]);

  useEffect(() => {
    switch (initState) {
      case EInitializeStates.INIT_USER_INFO:
        commonHelpers.promiseRace(loginActions.initUserInfo(), 2000).finally(() => {
          updateInitState({
            state: EInitializeStates.FINISH,
            msg: 'Initializing...',
          });
        });
        break;
    }
  }, [initState]);

  if (!appInitialize.finish) {
    if (appInitialize.error) {
      return <CompEmptyCard description={appInitialize.error} />;
    }

    return (
      <div
        style={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          position: 'absolute',
        }}>
        <Spin>
          <div style={{ paddingTop: '60px' }}>{appInitialize.msg}</div>
        </Spin>
      </div>
    );
  }

  return null;
};
