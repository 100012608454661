import { Button, Popconfirm, Space, Tag } from 'antd';
import { FC, useState } from 'react';
import { commonActions } from '@/actions/common';
import { rewardApis } from '@/actions/reward';
import { IPrize } from '@/types/reward.types';
import { useCommonState } from '@/hooks/app/useCommonState';
import { CompInputNumber } from '@/components/CompInputNumber';

export const ContInventoryEditModal: FC<{
  prizeItem: IPrize & {
    inventory: number;
  };
  refresh: () => void;
}> = ({ prizeItem: { id: prizeId, unlimited, prizeType, inventory }, refresh }) => {
  const { isMobileSize } = useCommonState();
  const [addAmount, add] = useState<number | null>(null);
  const [delAmount, del] = useState<number | null>(null);

  if (!prizeType) {
    return null;
  }

  if (unlimited) {
    return <Tag color='orange'>{`không giới hạn`}</Tag>;
  }

  return (
    <Space size={'small'} align='baseline'>
      <Popconfirm
        disabled={prizeType.voucher}
        icon={null}
        title={
          <Space>
            <label>{`Số lượng`}</label>
            <CompInputNumber
              autoFocus={true}
              max={inventory}
              value={delAmount}
              onFocus={() => del(null)}
              onChange={(val) => del(Number(val))}
              min={0}
            />
          </Space>
        }
        onConfirm={() => {
          if (!delAmount) {
            return;
          }
          return rewardApis
            .subtractWarehouse({ prizeId, amount: delAmount })
            .then(() => {
              commonActions.cmsLog(`Xóa ${delAmount} quà ${prizeId} khỏi kho quà.`);
            })
            .finally(() => {
              del(null);
              refresh();
            });
        }}
        okText='Xóa khỏi kho'
        cancelText='Bỏ qua'>
        <Button size='small' disabled={prizeType.voucher}>{`-`}</Button>
      </Popconfirm>
      <Tag
        color={(inventory && 'green') || 'error'}
        style={{ minWidth: isMobileSize ? 55 : 150, textAlign: 'center', margin: 0 }}>
        {inventory}
      </Tag>
      <Popconfirm
        disabled={prizeType.voucher}
        icon={null}
        title={
          <Space>
            <label>{`Số lượng`}</label>
            <CompInputNumber
              autoFocus={true}
              value={addAmount}
              onChange={(val) => add(+val)}
              min={0}
            />
          </Space>
        }
        onConfirm={() => {
          if (!addAmount) {
            return;
          }
          return rewardApis
            .addWarehouse({ prizeId, amount: addAmount })
            .then(() => {
              commonActions.cmsLog(`Thêm ${addAmount} quà ${prizeId} vào kho quà`);
            })
            .finally(() => {
              add(null);
              refresh();
            });
        }}
        okText='Thêm vào kho'
        cancelText='Bỏ qua'>
        <Button size='small' disabled={prizeType.voucher}>{`+`}</Button>
      </Popconfirm>
    </Space>
  );
};
