import { useAppInitialzing, useCommonState } from '@/hooks/app/useCommonState';
import { useNavState } from '@/hooks/app/useNavState';
import { FC } from 'react';
import { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';

export const MOBILE_NAV_BAR_HEIGHT = 55;
const flexContainerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
};
const flexItemStyle: CSSProperties = {
  width: '100px',
  margin: '10px',
  textAlign: 'center',
  lineHeight: '35px',
  fontSize: '30px',
};

export const CompNavMenuMobile: FC = (props) => {
  const history = useHistory();
  const { isMobileSize } = useCommonState();
  const { items, currentPathName, setState: setNavState } = useNavState();
  const { appInitialize } = useAppInitialzing();
  const [, currentNav, ..._subPaths] = currentPathName.split('/');

  if (!appInitialize.initialized || !isMobileSize) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: '#1f1f1f',
        position: 'fixed',
        bottom: '0px',
        width: '100%',
        height: MOBILE_NAV_BAR_HEIGHT,
      }}>
      <div style={flexContainerStyle}>
        {items.map((item) => (
          <div
            className={[
              'nav-item-mobile',
              ...(`/${currentNav}` !== item.path ? [] : ['selected']),
            ].join(' ')}
            key={item.path}
            style={flexItemStyle}
            onClick={() => {
              history.push(item.path);
              setNavState({ currentPathName: item.path });
            }}>
            {item.icon}
          </div>
        ))}
      </div>
    </div>
  );
};
