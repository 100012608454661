import store from '@/hooks/app/store';
import { INavState } from '@/types/nav.types';

export const setState = (state: Partial<INavState>) => {
  const prevState = store.getState().navState;
  const previousPathName = state.currentPathName
    ? prevState.currentPathName
    : prevState.previousPathName;
  store.setState({
    navState: { ...prevState, ...state, previousPathName },
  });
};
