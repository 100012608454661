import { campaignApis } from '@/actions/campaign';
import { ScanOutlined } from '@ant-design/icons';
import { CompQrScanner } from '@/components/CompQrScanner';
import { useCommonState } from '@/hooks/app/useCommonState';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { Form, Input, Modal } from 'antd';
import { CompInputNumber } from '@/components/CompInputNumber';

export const CompleteMissionModal: FC<{ completeMissionRow?; onClose: () => void }> = ({
  completeMissionRow,
  onClose,
}) => {
  const { isMobileSize } = useCommonState();
  const [scannerActive, setScannerActive] = useState(false);
  const [completeMissionForm] = useForm();

  const closeModal = () => {
    setTimeout(
      () => {
        onClose();
      },
      scannerActive ? 200 : 0
    );
    setScannerActive(false);
  };
  const submitForm = async () => {
    await completeMissionForm.validateFields().then(async (values) => {
      const { credential, paymentValue } = values;
      await campaignApis.completeMission({ id: completeMissionRow.id, credential, paymentValue });
    });
    closeModal();
  };

  useEffect(() => {
    if (completeMissionRow) {
      completeMissionForm.resetFields();
      setScannerActive(false);
    }
  }, [completeMissionRow]);

  return (
    <Modal
      style={{ maxWidth: !isMobileSize ? 500 : '90%' }}
      title={'Thực hiện nhiệm vụ'}
      open={!!completeMissionRow}
      closable={true}
      onOk={async () => {
        submitForm();
      }}
      onCancel={closeModal}>
      <CompQrScanner
        active={scannerActive}
        onDetected={(val) => {
          completeMissionForm.setFieldValue('credential', val);
          setScannerActive(false);
        }}
      />
      <Form
        form={completeMissionForm}
        preserve={false}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}>
        <Form.Item
          key='credential'
          name='credential'
          label='Số điện thoại'
          rules={[{ required: true, message: 'bắt buộc' }]}>
          <Input
            addonAfter={
              <ScanOutlined
                style={{
                  fontSize: 16,
                  color: '#1677ff',
                }}
                onClick={() => {
                  !scannerActive && completeMissionForm.setFieldValue('credential', '');
                  setScannerActive(!scannerActive);
                }}
              />
            }
          />
        </Form.Item>
        <Form.Item key='paymentValue' name='paymentValue' label='Giá trị giao dịch'>
          <CompInputNumber min={0} step={1000} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
