// import SliderCaptcha from '@slider-captcha/react';
import { Button, Checkbox, Form, Input, Modal, Spin, Typography, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const CompSignUp = () => {
  const [form] = useForm();

  const { Text, Link, Paragraph } = Typography;

  const [isConfirming, setConfirm] = useState<boolean>(true);
  const [done, isDone] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (type: NotificationType, title: string, message: string) => {
    api[type]({
      message: title,
      description: message,
      placement: 'topRight',
    });
  };

  return (
    <Spin spinning={loading} size='large'>
      {contextHolder}
      <Modal
        title={'Sign Up'}
        open={isConfirming}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        closable={false}
        footer={false}>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          name='signup'
          onFinish={async (values) => {
            console.debug(values);
            setData(values);
            setConfirm(!isConfirming);
          }}>
          <Form.Item label={'Store Name'} name={'clientId'} required>
            <Input placeholder='Please let us know your shop' />
          </Form.Item>
          <Form.Item label={'Password'} name={'password'} required>
            <Input.Password placeholder='Password' />
          </Form.Item>
          <Form.Item
            label={'Confirm Password'}
            name={'reconfirmpassword'}
            required
            rules={[
              { required: true, message: "Couldn't be emty!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The two passwords that you entered do not match!')
                  );
                },
              }),
            ]}>
            <Input.Password placeholder='Input password again' />
          </Form.Item>
          <Form.Item label={'EncryptData'} name={'confidental'} required valuePropName='checked'>
            <Checkbox defaultChecked> If this checked, your user data will be encrypted</Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 18 }} labelAlign='right'>
            <Button type='primary' htmlType='submit' style={{ width: 120 }}>
              Sign Up
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={!isConfirming && !done}
        onCancel={() => setConfirm(!isConfirming)}
        title={'Save this link and username'}
        okButtonProps={{
          disabled: !checked,
        }}
        onOk={async () => {
          const { clientId, password: secret, confidental } = data;
          setChecked(false);
          setLoading(true);
          return await fetch('https://gami.zeta.elofun.net/api/gami/registerOmsUser', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ credential: 'admin', clientId, secret, confidental }),
          })
            .then(async (res) => {
              const message = await res.text();
              if (res.status !== 200) {
                throw message;
              }
              return message;
            })
            .then((message) => {
              openNotification('info', 'success', message);
              setTimeout(() => isDone(true), 3000);
              setTimeout(
                (url) => window.location.assign(url),
                3500,
                `https://${window.location.hostname}/${data.clientId}/`
              );
            })
            .catch((err) => {
              openNotification('error', 'Error', err.toString());
              setConfirm(true);
            })
            .finally(() => setLoading(false));
        }}>
        {!isConfirming && (
          <Typography>
            {'Your dashboard link is: '}
            <Link
              href={`https://${window.location.hostname}/${data.clientId}/`}
              target='_blank'>{`https://${window.location.hostname}/${data.clientId}/`}</Link>
            <Paragraph>
              With your userName:
              <Text strong type='danger'>
                admin
              </Text>
            </Paragraph>
            <Checkbox onChange={() => setChecked(true)}>
              <Text>
                Are you saved your link ? When you click OK, your account are creating, after this
                form shutting down, you can login to your dashboard
              </Text>
            </Checkbox>
          </Typography>
        )}
      </Modal>
    </Spin>
  );
};
