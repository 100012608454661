import { FC, useEffect, useState } from 'react';
import { CompDataTable } from '@/components/CompDataTables';
import { Form, Input, Modal, Typography } from 'antd';
import { useCommonState } from '@/hooks/app/useCommonState';
import { useLoginState } from '@/hooks/app/useLoginState';
import { storageApis as storageApis } from '@/actions/storage';
import { useEffectAsync } from '@/hooks/common/useEffectAsync';
import { IList } from '@/types/storage.types';
import { FormProps, useForm } from 'antd/lib/form/Form';
import { tz } from '@/moment';
import { commonHelpers } from '@/actions/common';
import { CompInputNumber } from '@/components/CompInputNumber';
import TextArea from 'antd/lib/input/TextArea';

const requiredFields: { [key: string]: IListDetailField } = {
  displayName: { title: 'Tên hiển thị' },
};
export interface IListDetailField {
  title: string;
  type?: string;
  required?: boolean;
  display?: boolean;
  tableRender?: (
    val,
    row: IList & { key: string; mobileRowTitle?: string },
    refresh?: () => void
  ) => JSX.Element;
  formRender?: (key: string, detailField: IListDetailField, row?: IList) => JSX.Element;
}

export const ContListTable: FC<{
  selector: string;
  detailFields: { [key: string]: IListDetailField };
  additionalTableFields?: { [key: string]: IListDetailField };
  allowDelete?: boolean;
  datasourceTransform?: (items: Array<IList>) => Array<IList>;
  onCreate?: (values: Omit<IList, 'id'>) => Promise<void>;
  onEdit?: (values: IList) => Promise<void>;
  onDelete?: (params: { ids: Array<number> }) => Promise<void>;
  onStartEdit?: (values: IList) => void;
}> = ({
  selector,
  detailFields,
  additionalTableFields = {},
  allowDelete = false,
  datasourceTransform,
  onCreate,
  onEdit,
  onDelete: deleteApi,
  onStartEdit,
}) => {
  const { clientId } = useLoginState();
  const { page, pageSize, isMobileSize } = useCommonState();
  const [refreshId, setRefreshId] = useState(0);
  const [selectedListName, setSelectedListName] = useState('');
  const [editRow, setEditRow] = useState(null);
  const [listData, setListData] = useState<{
    items: Array<IList>;
    totals: number;
  }>({
    items: [],
    totals: 0,
  });
  const [form] = useForm();
  const detailListFields = { ...requiredFields, ...detailFields };

  const getParams = () => {
    return {
      where: {
        client_id: clientId,
        user_id: selector,
        label: {
          not: '',
        },
      },
      skip: (page - 1) * pageSize,
      take: pageSize,
    };
  };

  const refresh = () => setRefreshId(Math.random());
  useEffectAsync(
    async () => (selectedListName ? null : storageApis.getList(getParams())),
    [selector, page, pageSize, refreshId],
    {
      onSuccess: (res) => {
        if (res) {
          res.items = !datasourceTransform ? res.items : datasourceTransform(res.items);
          setListData({
            ...res,
            items: (res.items as Array<IList>).map((item) => ({
              ...item,
              value: Object.entries(item.value).reduce((total, [key, value]) => {
                const getValue = () => {
                  const detailField = detailListFields[key];
                  switch (detailField?.type) {
                    case 'number':
                      return Number(value);
                    case 'date':
                      return !value ? value : tz(value);
                    default:
                      return value;
                  }
                };
                return { ...total, [key]: getValue() };
              }, {}),
            })),
          });
        }
      },
    }
  );
  useEffect(() => {
    if (selectedListName && !editRow) {
      const row = listData.items.find((item) => item.label === selectedListName);
      if (row) {
        setEditRow(row);
        onStartEdit && onStartEdit(row);
        form.setFieldsValue(row.value);
      }
    }
    !selectedListName && setEditRow(null);
  }, [listData, selectedListName]);

  const renderEditForm = ({ editState, editRow, ...props }) => (
    <Form {...props}>
      <Form.Item label='Tên' name='label' rules={[{ required: true, message: 'bắt buộc' }]}>
        <TextArea size='small' disabled />
      </Form.Item>
      <Form.Item
        label='Tên hiển thị'
        name='displayName'
        rules={[{ required: true, message: 'bắt buộc' }]}>
        <Input
          onChange={(val) =>
            props.form.setFieldValue(
              'label',
              `${selector}-${commonHelpers.normalizeTitle(val.target.value)}`
            )
          }
        />
      </Form.Item>
    </Form>
  );

  const renderTable = () => {
    return (
      <CompDataTable
        columns={[
          {
            title: 'Mã',
            dataIndex: 'id',
          },
          ...Object.entries(detailListFields)
            .filter(([, value]) => value.display ?? true)
            .map(([key, value]) => ({
              title: value.title,
              dataIndex: 'value',
              render: (val, row) =>
                key === 'displayName' ? (
                  <Typography.Link
                    onClick={() => {
                      setSelectedListName(row.label);
                    }}>
                    {val[key]}
                  </Typography.Link>
                ) : value.tableRender ? (
                  value.tableRender(val, row, refresh)
                ) : (
                  val[key]
                ),
            })),
          ...Object.entries(additionalTableFields).map(([, value]) => ({
            title: value.title,
            dataIndex: 'value',
            render: (val, row) => value.tableRender(val, row, refresh),
          })),
        ]}
        datasource={listData.items.map((item) => ({
          ...item,
          mobileRowTitle: item.value?.displayName,
        }))}
        totals={listData.totals}
        editingForm={renderEditForm}
        mobileMode={isMobileSize}
        onCreate={({ label, displayName }) => {
          const listRecord = {
            label,
            client_id: clientId,
            user_id: selector,
            value: {
              displayName,
            },
          };
          return (
            onCreate
              ? onCreate(listRecord)
              : storageApis.addList({
                  unique: true,
                  data: listRecord,
                })
          ).then(() => {
            refresh();
            setSelectedListName(label);
          });
        }}
        onDelete={
          !allowDelete
            ? undefined
            : (rows) => {
                (deleteApi
                  ? deleteApi({ ids: rows.map((item) => item.id) })
                  : storageApis.deleteManyList({
                      ids: rows.map((item) => item.id),
                    })
                ).then(refresh);
              }
        }
      />
    );
  };

  const renderDetailModal = () => {
    return (
      <Modal
        title={`${editRow?.value.displayName || selectedListName} details`}
        open={!!editRow}
        style={{ minWidth: !isMobileSize ? 800 : '90%', height: 'calc(100% + 20px)' }}
        closable={true}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setSelectedListName('');
          form.resetFields();
        }}>
        {renderDetailForm({
          labelCol: { span: 6 },
          wrapperCol: { span: 12 },
          form,
          onFinish: (values) => {
            const getValue = (key: string, value: any) => {
              const detailField = detailListFields[key];
              switch (detailField?.type) {
                case 'number':
                  return Number(value);
                default:
                  return value;
              }
            };
            onEdit &&
              onEdit({
                ...editRow,
                value: Object.entries(values).reduce(
                  (total, [key, val]) => ({ ...total, [key]: getValue(key, val) }),
                  {}
                ),
              }).then(() => {
                setSelectedListName('');
                refresh();
              });
          },
        })}
      </Modal>
    );
  };

  const renderDetailForm = (props?: FormProps) => {
    if (!editRow) {
      return null;
    }

    const renderFormInput = (type: string) => {
      switch (type) {
        case 'number':
          return <CompInputNumber />;
      }
      return <Input />;
    };

    const formItems = Object.keys(detailListFields).map((key, index) => {
      const { type, title, formRender } = detailListFields[key];
      const required = detailListFields[key].required ?? true;

      return formRender ? (
        formRender(key, detailFields[key], editRow)
      ) : (
        <Form.Item
          key={`${key}-${index}`}
          label={title}
          name={key}
          hasFeedback
          rules={[
            {
              required,
              message: `Please input ${title}!`,
            },
          ]}>
          {renderFormInput(type)}
        </Form.Item>
      );
    });
    return <Form {...props}>{formItems}</Form>;
  };

  return (
    <>
      {renderTable()}
      {renderDetailModal()}
    </>
  );
};
