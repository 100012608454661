import { setState as setDynamicState } from '@/hooks/app/useDynamicState';
import { mutateRequest, request } from '@/wsgw';
import crypto from 'crypto-js';

export const userProfileApis = {
  transactionLogs: ({
    accountName,
    ...params
  }: {
    userId: string;
    accountName: string;
    skip: number;
    take: number;
  }) => request('gami', 'userTransactions', { ...params, name: accountName }),
  loyaltyPoints: ({ accountName, ...params }: { userId: string; accountName: string }) =>
    request('gami', 'userLoyaltyPoints', { ...params, name: accountName }),
  searchUser: (params: { type: string; credential: string }) =>
    request('auth', 'profileByAdmin', params),
  registerUser: (params: { type: string; credential: string; profile: any }) =>
    request('auth', 'register', params),
  updateProfile: (params: {
    type: string;
    credential: string;
    profile: {
      full_name: string;
      dob: string;
      gender: string;
      email?: string;
      phone_number?: string;
      clientId: string;
    };
  }) => request('auth', 'updateProfileByAdmin', params),
  userBalances: (params: { userId: string }) => request('gami', 'userBalances', params),
  topupBalance: (params: {
    userId: string;
    clientId: string;
    accountName: string;
    amount: number;
    serviceCode: string;
    description: string;
    origin?: string;
  }) => mutateRequest('gami', 'topupBalance', params),
  userRewardList: (params: { where: any; skip: number; take: number }) =>
    request('gami', 'userRewardList', params),
};

export const userProfileActions = {
  searchUser: (input: string, credentialType: string) => {
    const setState = (state) => setDynamicState('page-user-profile', state);
    setState({ user: null, input, isLoading: true });
    return userProfileApis
      .searchUser({
        type: credentialType,
        credential: crypto.MD5(input).toString(crypto.enc.Base64),
      })
      .then((user) => {
        user && user.id && setState({ user });
        return user;
      })
      .catch((error) => error && error.code !== 404 && setState({ error }))
      .finally(() => setState({ isLoading: false }));
  },
};
