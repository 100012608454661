import { FC, useState } from 'react';
import { ContListTable, IListDetailField } from '../common/ContListTable';
import { Button, Form, Input, Modal, Select, Tag } from 'antd';
import { IList } from '@/types/storage.types';
import { SwitchState } from '@/renderers/RendererSwitch';
import { usePrizeList } from '@/hooks/app/useRewardState';
import { useCampaignState } from '@/hooks/app/useCampaignState';
import { useForm } from 'antd/lib/form/Form';
import { ScanOutlined } from '@ant-design/icons';
import { CompQrScanner } from '@/components/CompQrScanner';
import { useCommonState } from '@/hooks/app/useCommonState';
import { shopActions, shopApis } from '@/actions/shop';
import { SHOP_ITEM_INIT_VALUES, SHOP_ITEM_SELECTOR } from '@/hooks/app/useShopState';
import { EBankingAccountNames } from '@/types/campaign.types';
import { CompCampaignSelect } from '../rewards/CompCampaignSelect';

export const ContShop: FC<{}> = ({}) => {
  const { currentCampaignId } = useCampaignState();
  const { isMobileSize } = useCommonState();
  const { prizeList } = usePrizeList();
  const [purchaseRow, setPurchaseRow] = useState(null);
  const [scannerActive, setScannerActive] = useState(false);
  const [purchaseForm] = useForm();

  const renderTable = () => {
    const renderBooleanField = (key: string, field: IListDetailField, row: IList) => (
      <Form.Item key={key} name={key} label={field.title}>
        <SwitchState initialChecked={row.value[key]} />
      </Form.Item>
    );
    const detailFields: { [key: string]: IListDetailField } = {
      description: { title: 'Mô tả', required: false, display: false },
      accountName: {
        title: 'Tài khoản',
        required: true,
        display: true,
        formRender: (key, field) => (
          <Form.Item key={key} name={key} label={field.title}>
            <Select
              style={{ width: '100%' }}
              options={[EBankingAccountNames.LOYALTY].map((name) => ({
                value: name,
                label: { [EBankingAccountNames.LOYALTY]: 'Loyalty point' }[name],
              }))}
            />
          </Form.Item>
        ),
      },
      price: { title: 'Giá', required: true, display: true, type: 'number' },
      rewards: {
        title: 'Giải thưởng',
        tableRender: (val) =>
          val.rewards
            .sort((a, b) => (a > b ? 1 : -1))
            .map((item) => (
              <Tag key={item} color='default' style={{ whiteSpace: 'pre-line' }}>
                {prizeList.find((prize) => prize.id === item)?.name || item}
              </Tag>
            )),
        formRender: (key, field) => (
          <Form.Item key={key} name={key} label={field.title}>
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              options={prizeList.map(({ id: value, name: label }) => ({ value, label }))}
            />
          </Form.Item>
        ),
      },
      props: { title: 'Thuộc tính', required: false, display: false },
      isActive: {
        title: 'Trạng thái',
        tableRender: (val, { key, mobileRowTitle, ...row }, refresh) => (
          <SwitchState
            initialChecked={val.isActive}
            onChange={(val) =>
              shopActions
                .updateShopItem({ ...row, value: { ...row.value, isActive: val } })
                .then(refresh)
            }
          />
        ),
        formRender: renderBooleanField,
      },
    };

    const additionalTableFields: { [key: string]: IListDetailField } = {
      actions: {
        title: '',
        tableRender: (_val, row) => {
          return (
            <>
              <Button
                type='primary'
                onClick={() => {
                  purchaseForm.resetFields();
                  setScannerActive(false);
                  setPurchaseRow(row);
                }}>
                Mua
              </Button>
            </>
          );
        },
      },
    };

    return (
      <>
        <div style={{ marginTop: 8, marginBottom: 8 }}>
          <CompCampaignSelect />
        </div>
        <ContListTable
          selector={SHOP_ITEM_SELECTOR.replace('%campaignId', currentCampaignId)}
          detailFields={detailFields}
          additionalTableFields={additionalTableFields}
          onCreate={(record) =>
            shopActions.createShopItem({
              ...record,
              value: { ...SHOP_ITEM_INIT_VALUES, ...record.value },
            })
          }
          onEdit={shopActions.updateShopItem}
          allowDelete={true}
        />
      </>
    );
  };

  const renderPurchaseModal = () => {
    const closeModal = () => {
      setTimeout(
        () => {
          setPurchaseRow(null);
        },
        scannerActive ? 200 : 0
      );
      setScannerActive(false);
    };
    const submitForm = async () => {
      await purchaseForm.validateFields().then(async (values) => {
        const { credential } = values;
        await shopApis.buyItem({ id: purchaseRow.id, credential });
      });
      closeModal();
    };

    return (
      <Modal
        style={{ maxWidth: !isMobileSize ? 500 : '90%' }}
        title={'Purchase item'}
        open={!!purchaseRow}
        closable={true}
        onOk={async () => {
          submitForm();
        }}
        onCancel={closeModal}>
        <CompQrScanner
          active={scannerActive}
          onDetected={(val) => {
            purchaseForm.setFieldValue('credential', val);
            setScannerActive(false);
          }}
        />
        <Form form={purchaseForm} preserve={false} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
          <Form.Item
            key='credential'
            name='credential'
            label='Số điện thoại'
            rules={[{ required: true, message: 'bắt buộc' }]}>
            <Input
              addonAfter={
                <ScanOutlined
                  style={{
                    fontSize: 16,
                    color: '#1677ff',
                  }}
                  onClick={() => {
                    !scannerActive && purchaseForm.setFieldValue('credential', '');
                    setScannerActive(!scannerActive);
                  }}
                />
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <>
      {renderPurchaseModal()}
      {renderTable()}
    </>
  );
};
