import { cappingApis } from '@/actions/capping';
import { CompDataTable, IEditFormProps } from '@/components/CompDataTables';
import { CompInputNumber } from '@/components/CompInputNumber';
import { useCampaignState, useMissions } from '@/hooks/app/useCampaignState';
import {
  CappingGroup,
  CappingPeriod,
  CappingTarget,
  useMissionGroupList,
  useUserGroupList,
} from '@/hooks/app/useCappingState';
import { useCommonState } from '@/hooks/app/useCommonState';
import { Select, Form, message } from 'antd';
import { FC, useEffect, useState } from 'react';

const EditForm: React.FC<IEditFormProps> = ({ editState, editRow, ...props }) => {
  const { missionGroupList } = useMissionGroupList();
  const { missions } = useMissions();
  const missionSubjects = missions.reduce(
    (total, item) => ({ ...total, [item.name]: item.displayName }),
    {}
  );
  const missionGroupSubjects = missionGroupList.items.reduce(
    (total, item) => ({ ...total, [item.label]: `Nhóm ${item.value.displayName}` || item.label }),
    {}
  );
  const { userGroupList } = useUserGroupList();
  const cappingSubjects = { ...missionSubjects, ...missionGroupSubjects };
  const userGroupSelections = [
    ...userGroupList.items.map(({ label, value }) => ({
      label: `Nhóm ${value.displayName}` || label,
      value: label,
    })),
  ];
  const cappingTargetSelections = [
    ...[CappingTarget.NORMAL].map((key) => ({
      value: key,
      label: key,
    })),
    ...userGroupSelections,
  ];
  const cappingGroupSelections = [
    ...[CappingGroup.INDIVIDUAL, CappingGroup.OVERALL].map((key) => ({
      value: key,
      label: key,
    })),
    ...userGroupSelections,
  ];

  return (
    <Form {...props}>
      <Form.Item
        label='Chu kỳ'
        name='period'
        rules={[
          {
            required: true,
            message: 'Please input.',
          },
        ]}>
        <Select
          options={[
            CappingPeriod.DAILY,
            CappingPeriod.MONTHLY,
            CappingPeriod.WEEKLY,
            CappingPeriod.OVERALL,
          ].map((key) => ({
            value: key,
            label: key,
          }))}
        />
      </Form.Item>
      <Form.Item
        label='Mục tiêu'
        name='subject'
        rules={[
          {
            required: true,
            message: 'Please input.',
          },
        ]}>
        <Select
          options={Object.keys(cappingSubjects).map((key) => ({
            value: key,
            label: cappingSubjects[key],
          }))}
        />
      </Form.Item>
      <Form.Item
        label='Đối tượng'
        name='target'
        rules={[
          {
            required: true,
            message: 'Please input.',
          },
          ({ getFieldValue }) => ({
            validator: async (_, value) => {
              const group = getFieldValue('group');
              if (
                value === CappingTarget.NORMAL &&
                group &&
                ![CappingGroup.INDIVIDUAL, CappingGroup.OVERALL].includes(group)
              ) {
                throw new Error('Normal target should not belong to any group');
              }
            },
          }),
        ]}>
        <Select options={cappingTargetSelections} />
      </Form.Item>
      <Form.Item
        label='Nhóm'
        name='group'
        rules={[
          {
            required: true,
            message: 'Please input.',
          },
        ]}>
        <Select options={cappingGroupSelections} />
      </Form.Item>
      <Form.Item label='Giá trị' name='amount' rules={[{ required: true, message: 'bắt buộc' }]}>
        <CompInputNumber min={-1} />
      </Form.Item>
    </Form>
  );
};

export const ContMissionCapping: FC<{ active: boolean }> = ({ active }) => {
  const { isMobileSize } = useCommonState();
  const { missionGroupList } = useMissionGroupList();
  const { missions } = useMissions();
  const missionSubjects = missions.reduce(
    (total, item) => ({ ...total, [item.name]: item.displayName }),
    {}
  );
  const missionGroupSubjects = missionGroupList.items.reduce(
    (total, item) => ({ ...total, [item.label]: `Nhóm ${item.value.displayName}` || item.label }),
    {}
  );
  const { userGroupList } = useUserGroupList();
  const { currentCampaignId } = useCampaignState();
  const cappingSubjects = { ...missionSubjects, ...missionGroupSubjects };
  const [cappings, setCappings] = useState([]);
  const [refreshId, setRefreshId] = useState(0);

  const refresh = () => setRefreshId(Math.random());

  useEffect(() => {
    active &&
      cappingApis.fetchMissionCappings({}).then((arr: any) => {
        setCappings(arr.filter((item) => item.subject.startsWith(currentCampaignId)));
      });
  }, [active, refreshId]);

  return (
    <CompDataTable
      columns={[
        {
          title: 'Id',
          dataIndex: ['id'],
        },
        {
          title: 'Mục tiêu',
          dataIndex: ['subject'],
          render: (val) => cappingSubjects[val] || val,
        },
        {
          title: 'Chu kỳ',
          dataIndex: ['period'],
        },
        {
          title: 'Đối tượng',
          dataIndex: ['target'],
        },
        {
          title: 'Nhóm',
          dataIndex: ['group'],
          render: (val) => {
            return !val
              ? null
              : userGroupList.items.find((group) => group.label === val)?.value.displayName || val;
          },
        },
        {
          title: `Giới hạn`,
          dataIndex: 'amount',
        },
      ]}
      datasource={cappings
        .map((item: any) => ({
          ...item,
          mobileRowTitle: `${cappingSubjects[item.subject] || item.cappingSubject} (${
            item.period
          })`,
        }))
        .sort((a, b) => (`${a.user_id}.${a.label}` > `${b.user_id}.${b.label}` ? 1 : -1))}
      totals={cappings.length}
      mobileMode={isMobileSize}
      editingForm={(props) => <EditForm {...props} />}
      onCreate={({ subject, period, target, group, amount }) => {
        cappingApis
          .addMisisonCapping({
            subject,
            period,
            target,
            group: !group ? undefined : group,
            value: amount,
          })
          .finally(refresh);
      }}
      onDelete={(rows) => {
        let count = 0;
        Promise.all(
          rows.map((item) =>
            cappingApis
              .removeMissionCapping({
                id: item.id,
              })
              .then(() => count++)
              .catch((err) => {})
          )
        ).finally(() => {
          count > 0 && message.info(`Remove ${count} row(s) successfully.`);
          refresh();
        });
      }}
    />
  );
};
