import { Button, ButtonProps } from 'antd';
import { FC } from 'react';
import { FileExcelOutlined } from '@ant-design/icons';
import { commonHelpers } from '@/actions/common';

export interface ICompDownloadCsvProps {
  fileName?: string;
  dataKeys: Array<string>;
  headers?: boolean | Array<string>;
  validator?: () => { isValid: boolean; message?: string };
  fetchApi: () => Promise<Array<any>>;
}

export const CompDownloadCsv: FC<ButtonProps & ICompDownloadCsvProps> = ({
  fileName,
  dataKeys,
  headers = true,
  validator,
  fetchApi,
  ...buttonProps
}) => {
  return (
    <Button
      {...buttonProps}
      type='primary'
      icon={<FileExcelOutlined />}
      onClick={() => {
        commonHelpers.downloadCsv({
          fileName,
          dataKeys,
          headers,
          validator,
          fetchApi,
        });
      }}>
      Export
    </Button>
  );
};
