import { DashboardOutlined, PermDataSettingOutlined, SpeedOutlined } from '@material-ui/icons';
import { SettingOutlined, SoundOutlined, ShopOutlined } from '@ant-design/icons';
import { GiftOutlined, UserOutlined } from '@ant-design/icons';
import { EInitializeStates, ICommonState } from '@/types/common.types';
import { ILoginState } from '@/types/user.types';
import { ICompNavMenuItem, INavState } from '@/types/nav.types';
import { IRewardState } from '@/types/reward.types';
import { ICappingState } from '@/types/capping.types';
import { ICampaignState } from '@/types/campaign.types';
import { CSSProperties } from 'react';
import { IShopState } from '@/types/shop.types';

export const MOBILE_SIZE_MAX_WIDTH = 768;

export const initCommonState: ICommonState = {
  appInitialize: { state: EInitializeStates.INIT_USER_INFO, msg: 'Authenticating...', error: '' },
  theme: window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light',
  language: 'en',
  width: document.documentElement.clientWidth,
  height: document.documentElement.clientHeight,
  isMobileSize: document.documentElement.clientWidth < MOBILE_SIZE_MAX_WIDTH,
  contentHeight: Math.max(600, document.documentElement.clientHeight - 240),
  page: 1,
  pageSize: 10,
};

export const navIconStyle: CSSProperties = {
  position: 'inherit',
  ...(initCommonState.theme === 'dark' ? { color: '#fff' } : {}),
  fontSize: 24,
  left: '-4px',
};

export const initLoginState: ILoginState = {
  clientId: undefined,
  credential: undefined,
  userId: undefined,
  username: undefined,
  scopes: [],
  isLogged: undefined,
  credentialTypes: ['user'],
};

export const defaultRoute = 'users';
const defaultRoutes = [
  // {
  //   label: 'dashboard.title',
  //   path: '/dashboard',
  //   icon: <DashboardOutlined style={navIconStyle} />,
  //   scopes: ['admin'],
  // },
  {
    label: 'user.title',
    path: '/users',
    icon: <UserOutlined style={navIconStyle} />,
    scopes: ['admin'],
  },
  {
    label: 'reward.title',
    path: '/rewards',
    icon: <GiftOutlined style={navIconStyle} />,
    scopes: ['admin', 'gift-admin'],
  },
  {
    label: 'capping.title',
    path: '/cappings',
    icon: <SpeedOutlined style={navIconStyle} />,
    scopes: ['admin'],
  },
  {
    label: 'campaign.title',
    path: '/campaign',
    icon: <SoundOutlined style={navIconStyle} />,
    scopes: ['admin'],
  },
  {
    label: 'shop.title',
    path: '/shop',
    icon: <ShopOutlined style={navIconStyle} />,
    scopes: ['admin'],
  },
  {
    label: 'settings.title',
    path: '/settings',
    icon: <SettingOutlined style={navIconStyle} />,
    scopes: ['admin'],
  },
  // {
  //   label: 'Test reward',
  //   path: '/test-reward',
  //   icon: <PermDataSettingOutlined style={navIconStyle} />,
  //   scopes: ['admin'],
  // },
  // {
  //   label: 'Test play game',
  //   path: '/test-play-game',
  //   icon: <PermDataSettingOutlined style={navIconStyle} />,
  //   scopes: ['admin'],
  // },
];

export const initNavState: INavState = {
  collapsed: initCommonState.isMobileSize,
  previousPathName: '',
  currentPathName: '',
  items: defaultRoutes.map((item) => ({ ...item, key: item.path } as unknown as ICompNavMenuItem)),
};

export const initRewardState: IRewardState = {
  prizeTypes: [],
  prizeList: [],
  scheduleList: [],
  refreshScheduleId: 0,
  isRewardLoading: false,
};

export const initCappingState: ICappingState = {
  userGroupList: {
    items: [],
    totals: 0,
  },
  prizeGroupList: {
    items: [],
    totals: 0,
  },
  missionGroupList: {
    items: [],
    totals: 0,
  },
};

export const initCampaignState: ICampaignState = {
  currentCampaignId: '',
  campaignList: {
    items: [],
    totals: 0,
  },
  missionList: {
    items: [],
    totals: 0,
  },
};

export const initShopState: IShopState = {
  shopItemList: {
    items: [],
    totals: 0,
  },
  branchList: {
    items: [],
    totals: 0,
  },
};
