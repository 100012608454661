import { SyncOutlined } from '@ant-design/icons';
import { Button, Select, Space, Spin, Tabs } from 'antd';
import Scheduler, { Resource } from 'devextreme-react/scheduler';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { getTimeZones } from 'devextreme/time_zone_utils';
import dxForm from 'devextreme/ui/form';
import moment from 'moment-timezone';
import { FC, useEffect, useState } from 'react';
import { ContRewardPool } from '@/containers/rewards/ContRewardPool';
import { useCommonState } from '@/hooks/app/useCommonState';
import { useLimitedPrizeList, useRewardState } from '@/hooks/app/useRewardState';
import { rewardApis, rewardActions } from '@/actions/reward';
import { commonHelpers } from '@/actions/common';

export const ContRewardScheduler: FC<{}> = () => {
  const { scheduleList, refreshScheduleId, isRewardLoading } = useRewardState();
  const { contentHeight } = useCommonState();
  const [tab, changeTab] = useState<string>('reward-pool');
  const [timezone, setTimezone] = useState(moment.tz.guess());
  const timeZones = getTimeZones();
  const { limitedPrizeList } = useLimitedPrizeList();

  const onAppointmentFormOpening = (e) => {
    const form: dxForm = e.form;
    const mainGroupItems = form.itemOption('mainGroup').items;

    // remove unused recurrence rules
    const recurrenceGroup = form.itemOption('recurrenceGroup').items;
    recurrenceGroup[0].editorOptions.onContentReady = function (args) {
      setTimeout(() => {
        const freqEditor = args.component._editors.find((x) => x.name == 'FREQ');
        freqEditor.editorOptions.items.reduceRight(
          (_, { value }, i) => value != 'daily' && freqEditor.editorOptions.items.splice(i, 1),
          null
        );
      }, 100);
    };

    form.itemOption('mainGroup.prizeId', 'validationRules', [
      { type: 'required', message: 'Please choose prize config' },
    ]);
    if (!mainGroupItems.find((i: any) => i.dataField === 'quantity')) {
      mainGroupItems.unshift({
        colSpan: 2,
        label: { text: 'Số lượng' },
        editorType: 'dxNumberBox',
        dataField: 'quantity',
        validationRules: [
          { type: 'required' },
          { type: 'range', min: 1, message: 'Quantity should be >= 1' },
        ],
      });
      form.itemOption('mainGroup', 'items', mainGroupItems);
      form.getEditor('quantity')?.option('min', 1);
    }

    mainGroupItems.forEach((item: any, i: number) => {
      if (item.dataField === 'text') {
        mainGroupItems.splice(i, 1);
      }
      if (item.dataField === 'prizeId') {
        mainGroupItems.splice(i, 1);
        mainGroupItems.unshift(item);
      }
    });
    mainGroupItems[2].items[0].label.text = 'Bắt đầu';
    mainGroupItems[2].items[2].label.text = 'Kết thúc';
    form.repaint();
  };

  useEffect(() => {
    rewardActions.getScheduleList();
  }, [refreshScheduleId]);

  return (
    <Tabs
      tabBarStyle={{ marginBottom: 10 }}
      activeKey={tab}
      onChange={changeTab}
      items={[
        {
          key: 'scheduler',
          label: 'Cài đặt',
          children: (
            <Spin spinning={isRewardLoading}>
              <Space>
                <Select
                  defaultValue={moment.tz.guess()}
                  onSelect={(tz) => {
                    setTimezone(tz);
                  }}>
                  {timeZones.map((t) => (
                    <Select.Option key={t.id} value={t.id}>
                      {t.title}
                    </Select.Option>
                  ))}
                </Select>
                <Button
                  type='primary'
                  icon={<SyncOutlined />}
                  onClick={rewardActions.refreshScheduleList}
                />
              </Space>
              <Scheduler
                shadeUntilCurrentTime={true}
                showAllDayPanel={true}
                disabled={isRewardLoading}
                views={[
                  { type: 'week' },
                  { type: 'month' },
                  { type: 'timelineMonth' },
                  { type: 'agenda' },
                  //
                ]}
                defaultCurrentView='month'
                height={contentHeight}
                startDayHour={0}
                endDayHour={23}
                cellDuration={60}
                timeZone={timezone}
                dataSource={
                  new DataSource(
                    new CustomStore({
                      key: 'id',
                      load: () =>
                        scheduleList
                          .map(({ id, start_date, end_date, info }) => ({
                            id,
                            startDate: start_date,
                            endDate: end_date,
                            ...JSON.parse(info),
                          }))
                          .filter((item) =>
                            limitedPrizeList.find((prize) => prize.id === item.prizeId)
                          ),
                      remove: (id) =>
                        rewardApis.deleteTask({ id }).finally(rewardActions.refreshScheduleList),
                      insert: (values) => {
                        const prize = limitedPrizeList.find((i) => i.id === values.prizeId);
                        return rewardActions
                          .createScheduler(prize, values)
                          .finally(rewardActions.refreshScheduleList);
                      },
                    })
                  )
                }
                onAppointmentFormOpening={onAppointmentFormOpening}
                editing={{
                  allowAdding: true,
                  allowDeleting: true,
                  allowUpdating: false,
                  allowDragging: false,
                  allowResizing: false,
                }}
                onContentReady={(e) => {
                  if (e.component.option('currentView') !== 'agenda') {
                    e.component.scrollTo(new Date());
                  }
                }}>
                <Resource
                  dataSource={limitedPrizeList.map((item, i) => ({
                    ...item,
                    display: `${item.name}`,
                    color: commonHelpers.randomColors(),
                  }))}
                  fieldExpr='prizeId'
                  displayExpr={'display'}
                  label='Loại quà'
                  valueExpr={'id'}
                  key='name'
                  colorExpr={'color'}
                  useColorAsDefault={true}
                />
              </Scheduler>
            </Spin>
          ),
        },
        {
          key: 'reward-pool',
          label: 'Danh sách chờ',
          children: <ContRewardPool />,
        },
      ]}></Tabs>
  );
};
