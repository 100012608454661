import { FC, useState } from 'react';
import { Button, Space, Spin } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { commonActions } from '@/actions/common';
import { CompDataTable } from '@/components/CompDataTables';
import { useLimitedPrizeList, useRewardState } from '@/hooks/app/useRewardState';
import { rewardApis, rewardActions } from '@/actions/reward';
import { useEffectAsync } from '@/hooks/common/useEffectAsync';
import { useCommonState } from '@/hooks/app/useCommonState';

export const ContRewardPool: FC = () => {
  const [pooledRewards, setPooledRewards] = useState<{ [prizeId: string]: number }>({});
  const [refreshId, setRefreshId] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const { prizeList } = useRewardState();
  const { limitedPrizeList } = useLimitedPrizeList();
  const { isMobileSize } = useCommonState();
  const refresh = () => setRefreshId(Math.random());

  useEffectAsync(
    () => {
      setLoading(true);
      return rewardActions.countStock({ prizeIds: limitedPrizeList.map((item) => item.id) });
    },
    [prizeList, refreshId],
    {
      onSuccess: (arr) =>
        setPooledRewards(
          arr.reduce((total, item) => ({ ...total, [item.label]: item.amount }), {})
        ),
      onFinally: () => setLoading(false),
    }
  );

  return (
    <Spin spinning={false}>
      <Space>
        <Button type='primary' icon={<SyncOutlined />} onClick={() => refresh()}>
          {' Refresh '}
        </Button>
      </Space>
      <CompDataTable
        columns={[
          { title: 'Id', dataIndex: 'id' },
          { title: 'Name', dataIndex: 'name' },
          { title: 'Count', dataIndex: 'stock' },
        ]}
        editable={false}
        datasource={limitedPrizeList
          .filter((prize) => pooledRewards[prize.id])
          .map((prize) => ({
            ...prize,
            stock: pooledRewards[prize.id],
            mobileRowTitle: `${prize.name}`,
          }))}
        totals={Object.keys(pooledRewards).length}
        onDelete={(items) => {
          rewardApis
            .clearStock({
              prizeIds: Object.values(items).map(({ id }) => id),
            })
            .then(() => {
              commonActions.cmsLog(
                `Xóa quà ${Object.values(items)
                  .map(({ name }) => name)
                  .join('|')} khỏi danh sách chờ thả quà`
              );
              refresh();
            });
        }}
        pagination={false}
        loading={isLoading}
        mobileMode={isMobileSize}
      />
    </Spin>
  );
};
