import { useCommonState } from '@/hooks/app/useCommonState';
import en from '@/i18n/en.json';
import vi from '@/i18n/vi.json';

export const languages = { en, vi };
export const languageSettings = {
  en: 'English',
  vi: 'Tiếng Việt',
};

const trans = (key: string, translates: any): string => {
  return (
    key.split('.').reduce((prop, item: string) => {
      if (prop) {
        return prop[item];
      }
    }, translates) || key
  );
};

export const translate = (key: string, language: string): string => {
  return trans(key, languages[language]);
};

export const useTranslate = () => {
  const { language } = useCommonState();
  return (key) => trans(key, languages[language]);
};
