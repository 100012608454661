import store from '@/hooks/app/store';
import { ICommonState } from '@/types/common.types';
import { request } from '@/wsgw';
import { message } from 'antd';

export const setState = (state: Partial<ICommonState>) => {
  const prevState = store.getState().commonState;
  store.setState({ commonState: { ...prevState, ...state } });
};

export const commonApis = {
  ping: () => request('gami', 'ping', {}, { indicator: false }),
  cmsLog: (message: string) => request('admin', 'track', { data: message }, { indicator: false }),
  getUploadUrl: (name: string) => request('gami', 'getUploadUrl', { name }, { indicator: false }),
  getObjectUrl: (name: string) => request('gami', 'getObjectUrl', { name }, { indicator: false }),
};

export const commonActions = {
  cmsLog: (...params: Parameters<typeof commonApis.cmsLog>) => {},
  // commonApis.cmsLog(...params).catch(() => {});
  getObjectUrl: async (name: string) => {
    const objectUrl = await commonApis.getObjectUrl(name);
    return objectUrl;
  },
  firstPage: () => {
    const { page } = store.getState().commonState;
    page !== 1 && setState({ page: 1 });
  },
  prevPage: () => {
    const { page } = store.getState().commonState;
    page > 1 && setState({ page: page - 1 });
  },
  nextPage: (total: number) => {
    const { page, pageSize } = store.getState().commonState;
    const lastPage = Math.max(1, Math.ceil(total / pageSize));
    page < lastPage && setState({ page: page + 1 });
  },
  lastPage: (total: number) => {
    const { page, pageSize } = store.getState().commonState;
    const lastPage = Math.max(1, Math.ceil(total / pageSize));
    page !== lastPage && setState({ page: lastPage });
  },
  toPageAfterCreate: (total: number) => {
    const page = commonHelpers.getPageAfterCreate(total);
    page && setState({ page });
    return page;
  },
  toPageAfterDelete: (currentPageRecordCount: number, deleteCount: number) => {
    const page = commonHelpers.getPageAfterDelete(currentPageRecordCount, deleteCount);
    page && setState({ page });
    return page;
  },
};

export const commonHelpers = {
  randomColors: () => `#${Math.floor(Math.random() * 16777215).toString(16)}`,
  removeAccents: (str: string) =>
    str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\u0111/g, 'd')
      .replace(/\u0110/g, 'D'),
  normalizeString: (str: string) =>
    commonHelpers
      .removeAccents(str.trim())
      .replace(/[^\w\s]/gi, '')
      .replace(/\s+/g, '-')
      .toLowerCase(),
  normalizeTitle: (
    str: string,
    transforms: Array<{ from: string; to: string }> = [{ from: '%', to: ' percent' }]
  ) =>
    commonHelpers.normalizeString(
      transforms.reduce((total, { from, to }) => total.replace(new RegExp(`${from}`, 'g'), to), str)
    ),
  toVnPrice: (val: number) => val.toLocaleString('en'),
  toVnTransactionPrice: (val: number) =>
    `${val > 0 ? '+' : '-'}${Math.abs(val).toLocaleString('en')}`,
  parseVnPrice: (val: string) => val.replace(/,/g, ''),
  debounce: <T, P>(action: (params?: P) => Promise<T>, time = 100) => {
    const pendingActions = [];
    let pid: NodeJS.Timeout;

    return ((params?: P) =>
      new Promise((resolve, reject) => {
        clearTimeout(pid);
        pendingActions.push({ resolve, reject });

        pid = setTimeout(() => {
          const handlePendings = [...pendingActions];
          pendingActions.length = 0;
          action(params)
            .then((res) => handlePendings.forEach(({ resolve }) => resolve(res)))
            .catch((err) => handlePendings.forEach(({ reject }) => reject(err)));
        }, time);
      })) as typeof action;
  },
  promiseRace: <T>(promise: Promise<T>, time: number) => {
    return Promise.race([
      promise,
      new Promise((_, reject) => setTimeout(() => reject('Timeout'), time)),
    ]);
  },
  downloadFile: (content: string, fileName = 'export.csv') => {
    const ele = document.createElement('a');
    ele.href = 'data:attachment/text,' + encodeURI(content);
    ele.target = '_blank';
    ele.download = fileName;
    ele.click();
  },
  downloadCsv: async ({
    fileName,
    dataKeys,
    headers = true,
    validator,
    fetchApi,
  }: {
    fileName?: string;
    dataKeys: Array<string>;
    headers?: boolean | Array<string>;
    validator?: () => { isValid: boolean; message?: string };
    fetchApi: () => Promise<Array<any>>;
  }) => {
    if (validator) {
      const validation = validator();
      const { isValid, message: validationMessage } = validation;
      if (!isValid) {
        validationMessage &&
          message.open({
            content: validationMessage,
            type: 'info',
            duration: 3,
          });
        return;
      }
    }

    const { data, error }: any = await fetchApi()
      .then((data) => ({ data }))
      .catch((error) => ({ error }));
    if (error || !data.length) {
      return message.open({
        content: !error
          ? 'No data'
          : error.error
          ? JSON.stringify(error.error)
          : `${error.message || error}`.slice(0, 150),
        type: 'error',
        duration: 3,
      });
    }

    const downloadContent = [];
    if (headers) {
      if (typeof headers === 'boolean') {
        headers = dataKeys;
      }
      downloadContent.push(headers.join());
    }
    data.forEach((item) => downloadContent.push(dataKeys.map((key) => item[key]).join()));
    commonHelpers.downloadFile(downloadContent.join('\n'), fileName);
  },
  getPageAfterCreate: (total: number) => {
    const { page, pageSize } = store.getState().commonState;
    const isLastPageFull = total % pageSize === 0;
    const lastPage = Math.max(1, Math.ceil(total / pageSize));
    if (isLastPageFull) {
      return lastPage + 1;
    }
    if (page !== lastPage) {
      return lastPage;
    }
    return 0;
  },
  getPageAfterDelete: (currentPageRecordCount: number, deleteCount: number) => {
    const { page } = store.getState().commonState;
    if (deleteCount === currentPageRecordCount) {
      return Math.max(1, page - 1);
    }
    return 0;
  },
};
