import { IRequestState } from '@/wsgw';
import { useEffect, useState } from 'react';

export const useRequestActionState = <T>(action: (params?: any) => Promise<T>) => {
  const [state, setState] = useState<IRequestState & { active: boolean }>({
    loading: false,
    error: '',
    data: null,
    active: true,
  });
  const updateState = (newState: Partial<typeof state>) => {
    setState({ ...state, ...newState });
  };

  useEffect(() => {
    return () => {
      updateState({ active: false });
    };
  }, []);

  const request = (params?: any) => {
    state.active && updateState({ data: null, error: '', loading: true });
    return action(params)
      .then((res) => {
        state.active && updateState({ loading: false, data: res });
        return res;
      })
      .catch((err) => {
        state.active &&
          updateState({
            loading: false,
            error: `${err?.error || err?.message || err}`.slice(0, 150),
          });
        throw err;
      });
  };

  return { ...state, request, setState: updateState } as IRequestState & {
    request: (params?: any) => Promise<T>;
    setState: typeof updateState;
  };
};
