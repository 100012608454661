import { useRequestActionState } from '@/hooks/common/useRequestActionState';
import { Image, ImageProps, Spin } from 'antd';
import { FC, useEffect } from 'react';
import { CompImageUpload, ICompImageUpload } from './CompImageUpload';

export const CompImageAsync: FC<
  ImageProps & {
    uploadOptions?: ICompImageUpload;
    action: () => Promise<string>;
  }
> = ({ action, uploadOptions, ...imgProps }) => {
  const { request, setState, ...state } = useRequestActionState(action);

  useEffect(() => {
    request();
  }, [action]);

  if (state.loading) {
    return <Spin />;
  }

  if (!state.data) {
    if (!state.error && uploadOptions) {
      return (
        <CompImageUpload
          {...uploadOptions}
          style={{ width: imgProps.width }}
          onFinish={(data) => {
            if (data) {
              const src = URL.createObjectURL(data);
              setState({ data: src });
            }
          }}
        />
      );
    }
    if (!imgProps.fallback) return null;
  }

  return (
    <Image
      onError={() => {
        setState({ data: '' });
      }}
      {...imgProps}
      src={state.data}
      placeholder={<Spin />}
    />
  );
};
