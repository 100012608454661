import { FC } from 'react';
import { ContListTable, IListDetailField } from '../common/ContListTable';
import { storageApis } from '@/actions/storage';
import { commonActions } from '@/actions/common';
import { BRANCH_SELECTOR } from '@/hooks/app/useShopState';

export const ContBranch: FC<{}> = ({}) => {
  const renderTable = () => {
    const detailFields: { [key: string]: IListDetailField } = {
      code: {
        title: 'Mã chi nhánh',
      },
      address: {
        title: 'Địa chỉ',
        display: false,
      },
    };

    return (
      <ContListTable
        selector={BRANCH_SELECTOR}
        detailFields={detailFields}
        onEdit={(values) =>
          storageApis.updateList(values).then(() => {
            commonActions.cmsLog(`Update branch ${values.label}`);
          })
        }
      />
    );
  };

  return <>{renderTable()}</>;
};
