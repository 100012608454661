import { CSSProperties, FC, useEffect } from 'react';
import { Input, Form, Modal, Button, message, Checkbox } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { loginActions } from '@/actions/login';
import { useLoginState } from '@/hooks/app/useLoginState';
import { useRequestActionState } from '@/hooks/common/useRequestActionState';
import { Buffer } from 'buffer/';
import { useCommonState } from '@/hooks/app/useCommonState';

export const CompLogin: FC = () => {
  const [form] = Form.useForm(null);
  const { isMobileSize } = useCommonState();
  const { clientId, userId, setState: setLoginState } = useLoginState();
  const { loading, request: loginAction } = useRequestActionState(loginActions.login);
  const controlProps = !isMobileSize
    ? {
        wrapperCol: { offset: 6, span: 18 },
      }
    : { style: { textAlign: 'center' } as CSSProperties };

  useEffect(() => {
    const auth = Buffer.from(localStorage.getItem('auth') ?? '', 'hex').toString();
    const { username, password, expiredAt = 0 } = JSON.parse(auth || '{}');
    Date.now() < expiredAt &&
      form.setFieldsValue({
        username,
        password,
      });
    form.setFieldValue('remember', true);
  });

  return (
    <Modal
      title='Login'
      open={!userId}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={false}
      footer={false}>
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        name='login'
        form={form}
        onFinish={async (values) => {
          if (loading) {
            return;
          }
          const { username, password, remember } = values;
          return loginAction({
            clientId,
            type: 'oms',
            credential: username,
            secret: password,
            remember,
          })
            .then(() => {
              setLoginState({ isLogged: true });
            })
            .catch(
              (err) =>
                err?.code !== 408 &&
                message.error({
                  title: 'Login failed!',
                  content: 'Incorrect username or password!',
                })
            );
        }}>
        <Form.Item
          label='Username'
          name='username'
          rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input
            prefix={<UserOutlined className='site-form-item-icon' />}
            placeholder='Username'
            disabled={loading}
            autoComplete={'off'}
          />
        </Form.Item>
        <Form.Item
          label='Password'
          name='password'
          rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password
            prefix={<LockOutlined className='site-form-item-icon' />}
            placeholder='Password'
            disabled={loading}
            autoComplete={'off'}
          />
        </Form.Item>
        <Form.Item {...controlProps} name='remember' valuePropName='checked'>
          <Checkbox>Remember</Checkbox>
        </Form.Item>
        <Form.Item {...controlProps}>
          <Button type='primary' htmlType='submit' style={{ width: 120 }}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
