import { Card, Tabs } from 'antd';
import React, { useState } from 'react';
import { useTranslate } from '@/i18n';
import { ContRewardCapping } from '@/containers/capping/ContRewardCapping';
import { ContMissionCapping } from '@/containers/capping/ContMissionCapping';

export const PageCappingsSetting: React.FC = () => {
  const $t = useTranslate();
  const [tab, setTab] = useState('reward-capping');

  return (
    <Card title={$t('capping.title')}>
      <Tabs
        tabBarStyle={{ margin: 0 }}
        defaultActiveKey='reward-capping'
        onChange={setTab}
        items={[
          {
            key: 'reward-capping',
            label: $t('capping.reward.title'),
            children: <ContRewardCapping active={tab === 'reward-capping'} />,
          },
          {
            key: 'mission-capping',
            label: $t('capping.mission.title'),
            children: <ContMissionCapping active={tab === 'mission-capping'} />,
          },
        ]}
      />
    </Card>
  );
};
