import { userProfileApis } from '@/actions/users-profile';
import { useCommonState } from '@/hooks/app/useCommonState';
import { useDynamicState } from '@/hooks/app/useDynamicState';
import { useTranslate } from '@/i18n';
import { format, tz } from '@/moment';
import { IUserProfileInfo } from '@/types/user.types';
import { DatePicker, Form, Input, Modal, Select } from 'antd';
import crypto from 'crypto-js';
import { FC, useEffect, useMemo, useState } from 'react';

export const ModalUpdatedForm: FC<{
  isOpen: boolean;
  profile: IUserProfileInfo;
  onFinish: () => void;
  onClose: () => void;
}> = ({ isOpen, profile, onFinish, onClose }) => {
  const $t = useTranslate();
  const { isMobileSize } = useCommonState();
  const { setState, ...state } = useDynamicState('page-user-profile', {} as any);
  const [provinces, setProvince] = useState([]);
  const [districts, setDisctrict] = useState([]);
  const [wards, setWards] = useState([]);
  const [provinceId, setProvinceId] = useState('');
  const [districtId, setdistrictId] = useState('');
  const [profileForm] = Form.useForm();
  const { credentialType, input } = state;

  useEffect(() => {
    profileForm.resetFields();
    profileForm.setFieldsValue({
      ...profile,
      phone_number: input,
      dob: !profile.dob ? undefined : tz(profile.dob),
    });
  }, [profile]);

  useMemo(() => {
    fetch(`https://vapi.vnappmob.com/api/province/ward/${districtId}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) =>
        res.results.reduce(
          (initial, { ward_name, ward_id }, ind) => [
            { label: ward_name, value: ward_name, ward_id },
            ...initial,
          ],
          []
        )
      )
      .then(setWards)
      .catch(() => {});
  }, [districtId]);

  useMemo(() => {
    fetch(`https://vapi.vnappmob.com/api/province/district/${provinceId}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) =>
        res.results.reduce(
          (initial, { district_name, district_id }, ind) => [
            { label: district_name, value: district_name, district_id },
            ...initial,
          ],
          []
        )
      )
      .then(setDisctrict)
      .catch(() => {});
  }, [provinceId]);

  useEffect(() => {
    fetch('https://vapi.vnappmob.com/api/province/', {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) =>
        res.results.reduce(
          (initial, { province_name, province_id }, ind) => [
            { label: province_name, value: province_name, province_id },
            ...initial,
          ],
          []
        )
      )
      .then((province) => setProvince(province))
      .catch(() => {});
  }, []);

  return (
    <Modal
      open={isOpen}
      title={$t('user.modalUpdateTitle')}
      onCancel={() => {
        onClose();
      }}
      onOk={() => {
        profileForm.submit();
      }}
      style={{ minWidth: !isMobileSize ? 800 : '90%' }}
      okText={'OK'}>
      <Form
        form={profileForm}
        onFinish={(data) => {
          const _data = {
            ...data,
            dob: data.dob ? format(data.dob, 'YYYY-MM-DD') : undefined,
          };
          userProfileApis
            .updateProfile({
              type: credentialType,
              credential: crypto.MD5(input).toString(crypto.enc.Base64),
              profile: _data,
            })
            .then((_) => {
              onFinish();
            })
            .finally(() => {
              onClose();
            });
        }}>
        <Form.Item
          label={$t('user.updateForm.fullName')}
          name={'full_name'}
          rules={[{ required: true, message: 'User need an identification' }]}>
          <Input size={'middle'} />
        </Form.Item>
        <Form.Item
          label={$t('user.updateForm.dob')}
          name={'dob'}
          style={{ width: '50%', float: 'right', marginLeft: '10px' }}>
          <DatePicker format={'DD/MM/YYYY'} />
        </Form.Item>
        <Form.Item
          label={$t('user.updateForm.gender.title')}
          name={'gender'}
          style={{ width: 'max-content' }}>
          <Select
            defaultValue={$t('user.updateForm.gender.other')}
            options={[
              { label: $t('user.updateForm.gender.other'), value: 'other' },
              { label: $t('user.updateForm.gender.male'), value: 'male' },
              { label: $t('user.updateForm.gender.female'), value: 'female' },
            ]}
          />
        </Form.Item>
        <Form.Item
          label={$t('user.updateForm.phoneNumber')}
          name={'phone_number'}
          rules={[
            { required: true, message: 'User need an phone number' },
            {
              required: true,
              pattern: /^\d+$/,
              message: 'Phone number must not be string!!!',
            },
          ]}>
          <Input style={{ float: 'right', maxWidth: '620px' }} />
        </Form.Item>
        <Form.Item label={$t('user.updateForm.email')} name={'email'}>
          <Input style={{ float: 'right', maxWidth: '620px' }} />
        </Form.Item>
        <Form.Item label={$t('user.updateForm.city')} name={'city'}>
          <Select
            style={{ float: 'right', maxWidth: '620px' }}
            showSearch
            options={provinces}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onSelect={(_, option) => {
              profileForm.resetFields(['ward', 'district', 'address']);
              setProvinceId(option.province_id);
            }}
          />
        </Form.Item>
        <Form.Item label={$t('user.updateForm.district')} name={'district'}>
          <Select
            style={{ float: 'right', maxWidth: '620px' }}
            showSearch
            options={districts}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onSelect={(_, option) => {
              setdistrictId(option.district_id);
            }}
          />
        </Form.Item>
        <Form.Item label={$t('user.updateForm.address')} name={'address'}>
          <Input style={{ float: 'right', maxWidth: '620px' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
