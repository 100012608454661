import { Empty } from 'antd';
import { ReactNode, FC } from 'react';
import { useLoginState } from '@/hooks/app/useLoginState';

export interface IContCheckScopesProps {
  scopes: string[];
  children: ReactNode;
  error?: JSX.Element;
}

export const ContCheckScopes: FC<IContCheckScopesProps> = (props: IContCheckScopesProps) => {
  const { scopes } = useLoginState();
  const accessible = props.scopes.every((scope) => scopes.includes(scope));
  if (!accessible) {
    return props.error || <Empty description={'No permission!'} />;
  }
  return <>{props.children}</>;
};
