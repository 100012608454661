import { FC, useState } from 'react';
import { ContListTable, IListDetailField } from '../common/ContListTable';
import { Button, Form, Select, Tag } from 'antd';
import { IList } from '@/types/storage.types';
import { SwitchState } from '@/renderers/RendererSwitch';
import { usePrizeList } from '@/hooks/app/useRewardState';
import {
  CAMPAIGN_MISSION_SELECTOR,
  MISSION_INIT_VALUES,
  useCampaignState,
} from '@/hooks/app/useCampaignState';
import { campaignActions } from '@/actions/campaign';
import { CompleteMissionModal } from './CompleteMissionModal';

export const ContCampaignMission: FC<{ active: boolean }> = ({ active }) => {
  const { currentCampaignId } = useCampaignState();
  const { prizeList } = usePrizeList();
  const [completeMissionRow, setCompleteMissionRow] = useState(null);

  const renderBooleanField = (key: string, field: IListDetailField, row: IList) => (
    <Form.Item key={key} name={key} label={field.title}>
      <SwitchState initialChecked={row.value[key]} />
    </Form.Item>
  );
  const renderTable = () => {
    const detailFields: { [key: string]: IListDetailField } = {
      category: { title: 'Category', required: false },
      description: { title: 'Mô tả', required: false, display: false },
      rewards: {
        title: 'Giải thưởng',
        tableRender: (val) =>
          val.rewards
            .sort((a, b) => (a > b ? 1 : -1))
            .map((item) => (
              <Tag key={item} color='default' style={{ whiteSpace: 'pre-line' }}>
                {prizeList.find((prize) => prize.id === item)?.name || item}
              </Tag>
            )),
        formRender: (key, field) => (
          <Form.Item key={key} name={key} label={field.title}>
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              options={prizeList.map(({ id: value, name: label }) => ({ value, label }))}
            />
          </Form.Item>
        ),
      },
      deeplink: { title: 'Deeplink', required: false, display: false },
      props: { title: 'Thuộc tính', required: false, display: false },
      display: {
        title: 'Display',
        tableRender: (val, { key, mobileRowTitle, ...row }, refresh) => (
          <SwitchState
            initialChecked={val.display}
            onChange={(val) =>
              campaignActions
                .updateMission({ ...row, value: { ...row.value, display: val } })
                .then(refresh)
            }
          />
        ),
        formRender: renderBooleanField,
      },
      isActive: {
        title: 'Trạng thái',
        tableRender: (val, { key, mobileRowTitle, ...row }, refresh) => (
          <SwitchState
            initialChecked={val.isActive}
            onChange={(val) =>
              campaignActions
                .updateMission({ ...row, value: { ...row.value, isActive: val } })
                .then(refresh)
            }
          />
        ),
        formRender: renderBooleanField,
      },
    };

    const additionalTableFields: { [key: string]: IListDetailField } = {
      actions: {
        title: '',
        tableRender: (_val, row) => {
          return (
            <>
              <Button
                type='primary'
                onClick={() => {
                  setCompleteMissionRow(row);
                }}>
                Thực hiện
              </Button>
            </>
          );
        },
      },
    };

    return (
      <ContListTable
        selector={CAMPAIGN_MISSION_SELECTOR.replace('%campaignId', currentCampaignId)}
        detailFields={detailFields}
        additionalTableFields={additionalTableFields}
        onCreate={(record) =>
          campaignActions.createMission({
            ...record,
            value: { ...MISSION_INIT_VALUES, ...record.value },
          })
        }
        onEdit={campaignActions.updateMission}
        onDelete={campaignActions.deleteMissions}
        allowDelete={true}
      />
    );
  };

  const renderCompleteMissionModal = () => {
    return (
      <CompleteMissionModal
        completeMissionRow={completeMissionRow}
        onClose={() => setCompleteMissionRow(null)}
      />
    );
  };

  return (
    <>
      {renderCompleteMissionModal()}
      {renderTable()}
    </>
  );
};
