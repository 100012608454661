import createStore from 'redux-zero';
import {
  initCampaignState,
  initCommonState,
  initLoginState,
  initNavState,
  initRewardState,
  initCappingState,
  initShopState,
} from '@/hooks/app/initState';

const initialState = {
  commonState: { ...initCommonState },
  loginState: { ...initLoginState },
  navState: { ...initNavState },
  rewardState: { ...initRewardState },
  cappingState: { ...initCappingState },
  campaignState: { ...initCampaignState },
  shopState: { ...initShopState },
  dynamicStates: {},
};
const store = createStore(initialState);

export type IAppState = typeof initialState;
export const resetStore = () => {
  store.reset();
};
export default store;
