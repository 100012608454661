import { useSelector } from 'redux-zero/react';
import { EInitializeStates, ICommonState } from '@/types/common.types';
import { IAppState } from '@/hooks/app/store';
import { setState } from '@/actions/common';

export const useCommonState = () => {
  return { ...useSelector((state: IAppState) => state.commonState), setState } as ICommonState & {
    setState: (data: Partial<ICommonState>) => void;
  };
};

export const useAppInitialzing = () => {
  const { appInitialize } = useCommonState();
  return {
    appInitialize: {
      ...appInitialize,
      finish: appInitialize.state === EInitializeStates.FINISH,
      initialized: appInitialize.state === EInitializeStates.FINISH && !appInitialize.error,
    },
  };
};
