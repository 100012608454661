import { IList } from '@/types/storage.types';
import { mutateRequest, request } from '@/wsgw';

export const storageApis = {
  getList: (params: { where: any; skip: number; take: number }) =>
    request('gami', 'getList', params),
  addList: (params: { unique?: boolean; data: Partial<IList> }) =>
    request('gami', 'addList', params),
  updateList: ({ id, ...data }: Partial<IList>) =>
    mutateRequest('gami', 'updateList', { where: { id }, data }),
  deleteManyList: (params: { ids: Array<number> }) =>
    mutateRequest('gami', 'deleteManyList', params),
};
