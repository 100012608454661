import { Button } from 'antd';
import React from 'react';
import { HashRouter as Router, Link, useLocation } from 'react-router-dom';

import { LinkProps } from 'react-router-dom/umd/react-router-dom';

export const CompLinkButton: React.FC<LinkProps<any>> = (props: LinkProps<any>) => {
  const { to } = props;
  const location = useLocation();
  const isCurrentPath = to === location.pathname;
  return (
    <Router>
      <Button type={isCurrentPath ? 'primary' : 'link'}>
        {/* @ts-ignore */}
        <Link {...props}>{props.children}</Link>
      </Button>
    </Router>
  );
};
