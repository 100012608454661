import { userProfileActions, userProfileApis } from '@/actions/users-profile';
import { ContUserLoyalty } from '@/containers/user-profile/ContUserLoyalty';
import { ContUserProfileInfo } from '@/containers/user-profile/ContUserProfileInfo';
import { ContUserRewardLogs } from '@/containers/user-profile/ContUserRewardLogs';
import { useCommonState } from '@/hooks/app/useCommonState';
import { useDynamicState } from '@/hooks/app/useDynamicState';
import { useLoginState } from '@/hooks/app/useLoginState';
import { useTranslate } from '@/i18n';
import { Nullable } from '@/types/common.types';
import { IUserProfileInfo } from '@/types/user.types';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Empty, Form, Input, Modal, Select, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';

export const PageUsersProfile: React.FC = () => {
  const $t = useTranslate();
  const { credentialTypes } = useLoginState();
  const { isMobileSize } = useCommonState();
  const [tab, setTab] = useState('info');
  const [searchForm] = Form.useForm();
  const [registerForm] = Form.useForm();
  const initialState = {
    credentialType: credentialTypes[0],
    input: '',
    isLoading: false,
    user: null as Nullable<IUserProfileInfo>,
    error: null,
  };
  const { setState, ...state } = useDynamicState('page-user-profile', initialState);
  const [isRegisteringUser, setRegisteringUser] = useState(false);
  const { credentialType, input, user, error, isLoading } = state;

  const searchUser = (input: string) => {
    return userProfileActions.searchUser(input, credentialType);
  };

  useEffect(() => {
    return () => {
      setState(initialState);
    };
  }, []);

  const renderRegisterForm = () => {
    return (
      <Modal
        open={isRegisteringUser}
        title={'Register user'}
        onCancel={() => setRegisteringUser(false)}
        onOk={() => {
          registerForm.submit();
        }}
        style={{ minWidth: !isMobileSize ? 800 : '90%' }}
        okText={'OK'}>
        <Form
          form={registerForm}
          onFinish={({ credential }) => {
            userProfileApis
              .registerUser({
                type: 'user',
                credential,
                profile: {
                  phone_number: credential,
                },
              })
              .then((res) => {
                setRegisteringUser(false);
                searchUser(credential);
              });
          }}>
          <Form.Item
            label='Credential'
            name='credential'
            hasFeedback
            rules={[{ required: true, message: 'Please input credential' }]}>
            <Input prefix={<UserOutlined />} placeholder='Credential' autoComplete={'off'} />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const renderSearchForm = () => {
    if (!credentialType) {
      return null;
    }
    return (
      <Form
        form={searchForm}
        layout='vertical'
        initialValues={{ input }}
        onFinish={({ input }) => {
          if (!input) {
            return;
          }
          searchUser(input);
        }}>
        <Form.Item name='input' noStyle>
          <Input.Search
            placeholder={$t('user.search.placeholder')}
            size='large'
            addonBefore={
              <Select
                defaultValue={credentialType}
                onChange={(credentialType) => setState({ credentialType })}>
                {credentialTypes.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            }
            onSearch={() => searchForm.submit()}
          />
        </Form.Item>
      </Form>
    );
  };

  const renderUserInfos = () => {
    if (!input) {
      return null;
    }
    if (isLoading) {
      return <Spin></Spin>;
    }
    if (!user) {
      if (!error) {
        return (
          <>
            <Empty description={`Không tìm thấy user ${input}!`} />
            <div style={{ textAlign: 'center' }}>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => {
                  registerForm.resetFields();
                  registerForm.setFieldValue('credential', input);
                  setRegisteringUser(true);
                }}>
                {'Register user'}
              </Button>
            </div>
          </>
        );
      }
      return null;
    }
    return (
      <Tabs
        tabBarStyle={{ margin: 0 }}
        activeKey={tab}
        onChange={(key) => {
          setTab(key);
        }}
        items={[
          {
            key: 'info',
            label: $t('user.info.title'),
            children: <ContUserProfileInfo info={user} />,
          },
          {
            key: 'loyalty',
            label: $t('user.loyalty.title'),
            children: <ContUserLoyalty info={user} />,
          },
          {
            key: 'reward-history',
            label: $t('user.rewardHistory.title'),
            children: <ContUserRewardLogs info={user} />,
          },
        ]}
      />
    );
  };

  return (
    <Card title={$t('user.title')}>
      {renderRegisterForm()}
      {renderSearchForm()}
      {renderUserInfos()}
    </Card>
  );
};
