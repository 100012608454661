import { Card, CardProps, Empty } from 'antd';
import { FC } from 'react';

export const CompEmptyCard: FC<CardProps & { description: string }> = ({
  description,
  ...props
}) => {
  return (
    <Card {...props}>
      <Empty description={description} />
      {props.children}
    </Card>
  );
};
