import { mutateRequest, request } from '@/wsgw';
import { storageApis as storageApis } from '@/actions/storage';
import { ICappingState } from '@/types/capping.types';
import store from '@/hooks/app/store';

export const setState = (state: Partial<ICappingState>) => {
  const prevState = store.getState().cappingState;
  store.setState({ cappingState: { ...prevState, ...state } });
};

export const cappingApis = {
  fetchPrizeCappings: (params: {}): Promise<{}> => request('capping', 'prize/list', params),
  fetchMissionCappings: (params: {}): Promise<{}> => request('capping', 'mission/list', params),
  addPrizeCapping: (params: {
    subject: string;
    period: string;
    target: string;
    group: string;
    value: number;
  }) => mutateRequest('capping', 'prize/add-rule', params),
  removePrizeCapping: (params: { id: string }) =>
    mutateRequest('capping', 'prize/remove-rule', params),
  addMisisonCapping: (params: {
    subject: string;
    period: string;
    target: string;
    group: string;
    value: number;
  }) => mutateRequest('capping', 'mission/add-rule', params),
  removeMissionCapping: (params: { id: string }) =>
    mutateRequest('capping', 'mission/remove-rule', params),
};

export const cappingActions = {
  getUserGroupList: (params: { where: any; skip: number; take: number }) => {
    storageApis.getList(params).then((userGroupList) => setState({ userGroupList }));
  },
  getPrizeGroupList: (params: { where: any; skip: number; take: number }) => {
    storageApis.getList(params).then((prizeGroupList) => setState({ prizeGroupList }));
  },
  getMissionGroupList: (params: { where: any; skip: number; take: number }) => {
    storageApis.getList(params).then((missionGroupList) => setState({ missionGroupList }));
  },
};

export const cappingHelpers = {
  getGroupParams: (page: number, pageSize: number, clientId: string, selector: string) => {
    return {
      where: {
        client_id: clientId,
        user_id: selector,
        label: {
          not: '',
        },
      },
      skip: (page - 1) * pageSize,
      take: pageSize,
    };
  },
};
