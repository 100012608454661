import { FC } from 'react';
import { CompDataTable, EEditStates, IEditFormProps } from '@/components/CompDataTables';
import { usePrizeType } from '@/hooks/app/useRewardState';
import { Form, Input } from 'antd';
import { RendererSwitch, SwitchState } from '@/renderers/RendererSwitch';
import { rewardActions } from '@/actions/reward';
import { commonActions, commonHelpers } from '@/actions/common';
import { campaignHelpers } from '@/actions/campaign';
import { useCommonState } from '@/hooks/app/useCommonState';
import TextArea from 'antd/lib/input/TextArea';

export const ContPrizeTypeTable: FC = () => {
  const { prizeTypes } = usePrizeType();
  const campaignPrefix = campaignHelpers.getCampaignPrefix();
  const { isMobileSize } = useCommonState();

  const renderEditForm = ({ editState, editRow, ...formProps }: IEditFormProps) => (
    <Form {...formProps}>
      <Form.Item label='Mã loại quà' name='id' rules={[{ required: true, message: 'bắt buộc' }]}>
        <TextArea size='small' disabled />
      </Form.Item>
      <Form.Item label='Tên loại quà' name='name' rules={[{ required: true, message: 'bắt buộc' }]}>
        <Input
          onChange={(val) => {
            editState === EEditStates.ADD &&
              formProps.form.setFieldValue(
                'id',
                `${campaignPrefix}${commonHelpers.normalizeTitle(val.target.value)}`
              );
          }}
        />
      </Form.Item>
      <Form.Item label='Mô tả' name='description'>
        <Input />
      </Form.Item>
      <Form.Item label='Đơn vị' name='unit' rules={[{ required: true, message: 'bắt buộc' }]}>
        <Input />
      </Form.Item>
      <Form.Item label='Voucher' name='voucher'>
        <SwitchState
          disabled={editState === EEditStates.EDIT}
          initialChecked={editRow?.voucher ?? false}
        />
      </Form.Item>
      <Form.Item label='Bộ sưu tập' name='collectible'>
        <SwitchState
          disabled={editState === EEditStates.EDIT}
          initialChecked={editRow?.collectible ?? false}
        />
      </Form.Item>
    </Form>
  );

  return (
    <CompDataTable
      columns={[
        {
          title: 'Mã loại quà',
          dataIndex: 'id',
        },
        {
          title: 'Tên loại quà',
          dataIndex: 'name',
        },
        {
          title: 'Mô tả',
          dataIndex: 'description',
        },
        {
          title: 'Đơn vị',
          dataIndex: 'unit',
        },
        {
          title: 'Voucher',
          dataIndex: 'voucher',
          render: RendererSwitch,
        },
        {
          title: 'Bộ sưu tập',
          dataIndex: 'collectible',
          render: RendererSwitch,
        },
      ]}
      datasource={prizeTypes.map((item) => ({ ...item, mobileRowTitle: item.name }))}
      totals={prizeTypes.length}
      pagination={false}
      editable={true}
      editingForm={renderEditForm}
      onCreate={(data) =>
        rewardActions
          .upsertPrizeType(data)
          .then(() => commonActions.cmsLog(`Thêm loại quà ${data.name}`))
      }
      onEdit={({ key, ...data }) =>
        rewardActions
          .upsertPrizeType(data)
          .then(() => commonActions.cmsLog(`Sửa loại quà ${data.name}`))
      }
      mobileMode={isMobileSize}
    />
  );
};
