import { useNavState } from '@/hooks/app/useNavState';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Redirect, Route, Switch } from 'react-router-dom';
// import { PageDashboard } from '@/pages/PageDashboard';
import { CompInitApp } from '@/components/CompInitApp';
import { CompNavMenu } from '@/components/CompNavMenu';
import { PageCampaign } from '@/pages/PageCampaign';
import { PageCappingsSetting } from '@/pages/PageCappingsSetting';
import { PageLogin } from '@/pages/PageLogin';
import { PageRewards } from '@/pages/PageRewards';
import { PageSettings } from '@/pages/PageSettings';
import { PageSignUp } from '@/pages/PageSignUp';
import { PageUsersProfile } from '@/pages/PageUsersProfile';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { CompNavMenuMobile, MOBILE_NAV_BAR_HEIGHT } from './components/CompNavMenuMobile';
import { defaultRoute, navIconStyle } from './hooks/app/initState';
import { useAppInitialzing, useCommonState } from './hooks/app/useCommonState';
import { PageShop } from './pages/PageShop';

// import { PageTestReward } from '@/pages/PageTestReward';
import { PageTestPlayGame } from './pages/PageTestPlayGame';

function App() {
  const { collapsed, setState: setNavState } = useNavState();
  const { isMobileSize } = useCommonState();
  const { appInitialize } = useAppInitialzing();

  return (
    <Layout hasSider={true} style={{ minHeight: '100vh' }}>
      <CompNavMenu />
      <Layout>
        {appInitialize.initialized && !isMobileSize && (
          <Row>
            <Col>
              <Button
                style={{ zIndex: 9999 }}
                shape='round'
                icon={
                  collapsed ? (
                    <MenuUnfoldOutlined style={navIconStyle} />
                  ) : (
                    <MenuFoldOutlined style={navIconStyle} />
                  )
                }
                type='text'
                onClick={() => {
                  setNavState({ collapsed: !collapsed });
                }}
              />
            </Col>
          </Row>
        )}
        <Content
          style={{
            padding: !isMobileSize ? '1em' : '0',
            ...(!isMobileSize ? {} : { paddingBottom: MOBILE_NAV_BAR_HEIGHT }),
          }}>
          <CompInitApp />
          {appInitialize.initialized && (
            <Switch>
              <Route path='/login'>
                <PageLogin />
              </Route>
              {/* <Route path='/dashboard'>
                  <PageDashboard />
                </Route> */}
              <Route path='/users'>
                <PageUsersProfile />
              </Route>
              <Route path={`/rewards`}>
                <PageRewards />
              </Route>
              <Route path='/cappings'>
                <PageCappingsSetting />
              </Route>
              {/* <Route path='/activitylogs'>
                  <PageActivityLogs />
                </Route> */}
              <Route path='/settings'>
                <PageSettings />
              </Route>
              <Route path='/campaign'>
                <PageCampaign />
              </Route>
              <Route path='/shop'>
                <PageShop />
              </Route>
              {/* <Route path={`/test-reward`}>
                  <PageTestReward />
                </Route> */}
              <Route path={`/test-play-game`}>
                <PageTestPlayGame />
              </Route>
              <Route path='/signup'>
                <PageSignUp />
              </Route>
              <Route path={'/'} exact>
                <Redirect to={`/${defaultRoute}`} />
              </Route>
            </Switch>
          )}
        </Content>
        <CompNavMenuMobile />
      </Layout>
    </Layout>
  );
}

export default App;
