import { commonHelpers } from '@/actions/common';
import { InputNumber, InputNumberProps } from 'antd';

export const CompInputNumber = (
  props: InputNumberProps & {
    formatter?: (val: number) => string;
    parser?: (val: string) => number;
  }
) => {
  return (
    <InputNumber
      style={{ width: '100%' }}
      {...props}
      formatter={props.formatter || ((val) => commonHelpers.toVnPrice(Number(val)))}
      parser={commonHelpers.parseVnPrice}
    />
  );
};
