import { IList } from '@/types/storage.types';

export interface ICampaignState {
  currentCampaignId: string;
  campaignList: {
    items: Array<IList>;
    totals: number;
  };
  missionList: {
    items: Array<IList>;
    totals: number;
  };
}

export interface IMission {
  name: string;
  displayName: string;
  category: string;
  description: string;
  deeplink: string;
  display: boolean;
  isActive: boolean;
  rewards: Array<string>;
  props?: string;
}

export enum EBankingAccountNames {
  PAYMENT_CONSUMED = 'payment-consumed',
  LOYALTY = 'loyalty-point',
  GAME_TURN = 'game-turn',
}
