import { useSelector } from 'redux-zero/react';
import { INavState } from '@/types/nav.types';
import { setState } from '@/actions/nav';
import { IAppState } from '@/hooks/app/store';

export const useNavState = () => {
  return { ...useSelector((state: IAppState) => state.navState), setState } as INavState & {
    setState: (data: Partial<INavState>) => void;
  };
};
