import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Radio, Space, Switch, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { CompDataTable } from '@/components/CompDataTables';
import { loginApis, loginHelpers } from '@/actions/login';
import { ROLES, useLoginState } from '@/hooks/app/useLoginState';
import { commonActions } from '@/actions/common';
import { useTranslate } from '@/i18n';
import { ContQuery } from '../common/ContQuery';
import { useCommonState } from '@/hooks/app/useCommonState';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { VN_DATETIME_FORMAT, format } from '@/moment';

export const ContUserTable: React.FC = () => {
  const $t = useTranslate();
  const { clientId, userId } = useLoginState();
  const { page, pageSize, isMobileSize } = useCommonState();
  const getUserParams = () => ({
    where: {
      client_id: clientId,
      type: 'oms',
    },
    skip: (page - 1) * pageSize,
    take: pageSize,
  });
  const [listUserParams, setListUserParams] = useState(getUserParams());

  useEffect(() => {
    setListUserParams(getUserParams());
  }, [page, pageSize]);

  const renderEditForm = ({ editState, editRow, ...props }) => {
    return (
      <Form {...props} name='Add account'>
        <Form.Item
          label='Username'
          name='username'
          hasFeedback
          rules={[{ required: true, message: 'Please input username!' }]}>
          <Input
            prefix={<UserOutlined className='site-form-item-icon' />}
            placeholder='Username'
            autoComplete={'off'}
          />
        </Form.Item>
        <Form.Item
          label='Password'
          name='password'
          hasFeedback
          rules={[{ required: true, message: 'Please input password!' }]}>
          <Input.Password
            prefix={<LockOutlined className='site-form-item-icon' />}
            placeholder='Password'
            autoComplete={'off'}
          />
        </Form.Item>
        <Form.Item
          label='Confirm Password'
          name='confirmPassword'
          dependencies={['password']}
          hasFeedback
          rules={[
            { required: true, message: 'Please input password again!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              },
            }),
          ]}>
          <Input.Password
            prefix={<LockOutlined className='site-form-item-icon' />}
            placeholder='Confirm Password'
            autoComplete={'off'}
          />
        </Form.Item>
        <Form.Item
          label='Role'
          name='role'
          initialValue={'customer'}
          rules={[{ required: true, message: 'Please select role!' }]}>
          <Space direction='vertical' style={{ maxWidth: 120 }}>
            <Radio.Group
              buttonStyle='outline'
              options={Object.keys(ROLES).map((a) => ({
                label: a,
                value: a,
              }))}
            />
          </Space>
        </Form.Item>
      </Form>
    );
  };

  const renderTable = (dataSource, totals, refresh) => {
    return (
      <CompDataTable
        columns={[
          {
            title: $t('common.id'),
            dataIndex: 'id',
          },
          {
            title: $t('settings.user.username'),
            dataIndex: 'user_id',
            render: loginHelpers.parseUsername,
          },
          {
            title: $t('settings.user.type'),
            dataIndex: 'type',
          },
          {
            title: $t('settings.user.clientId'),
            dataIndex: 'client_id',
          },
          {
            title: $t('settings.user.scope'),
            dataIndex: ['user', 'scopes'],
            render: (val) => val.split(',').map((item, i) => <Tag key={i}>{item}</Tag>),
          },
          {
            title: $t('common.createdAt'),
            dataIndex: ['user', 'created_at'],
            render: (val) => format(val, VN_DATETIME_FORMAT),
          },
          {
            title: $t('settings.user.active'),
            dataIndex: 'active',
            render: (val, row: any) =>
              val !== undefined && (
                <Switch
                  disabled={userId === row.userId}
                  checked={val}
                  title={(val && 'Bật') || 'Tắt'}
                  onChange={(checked) => {
                    Modal.confirm({
                      title: `Bạn có chắc chắn?`,
                      icon: <ExclamationCircleOutlined />,
                      okText: `Có (${checked ? 'Bật' : 'Tắt'})`,
                      okType: 'primary',
                      cancelText: 'Không (bỏ qua)',
                      onOk: () =>
                        loginApis
                          .activeUser({
                            credential: row.credential,
                            type: 'oms',
                            active: checked,
                          })
                          .then(() => {
                            refresh();
                            commonActions.cmsLog(`${checked ? 'Bật' : 'Tắt'} user ${row.name}`);
                          }),
                    });
                  }}
                />
              ),
          },
        ]}
        datasource={dataSource.map((item) => ({
          ...item,
          mobileRowTitle: loginHelpers.parseUsername(item.user_id),
        }))}
        totals={totals}
        editingForm={renderEditForm}
        mobileMode={isMobileSize}
        onCreate={({ username, password, role }) => {
          loginApis
            .createUser({
              credential: username,
              type: 'oms',
              secret: password,
              clientId,
              profile: {},
              scopes: ROLES[role].join(),
            })
            .finally(refresh);
        }}
      />
    );
  };

  return (
    <ContQuery
      cacheKey='list-user'
      action={loginApis.listUser}
      params={listUserParams}
      render={({ data, refresh }) => renderTable(data?.items || [], data?.totals, refresh)}
    />
  );
};
