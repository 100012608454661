import { Card, Tabs } from 'antd';
import React, { useState } from 'react';
import { useTranslate } from '@/i18n';
import { ContCampaignTable } from '@/containers/campaign/ContCampaignTable';
import { ContCampaignMission } from '@/containers/campaign/ContCampaignMission';
import { ContUserGroup } from '@/containers/capping/ContUserGroup';
import { ContPrizeGroup } from '@/containers/capping/ContPrizeGroup';
import { ContMissionGroup } from '@/containers/capping/ContMissionGroup';
import { CompCampaignSelect } from '@/containers/rewards/CompCampaignSelect';

export const PageCampaign: React.FC = () => {
  const $t = useTranslate();
  const [tab, setTab] = useState('reward-capping');

  return (
    <Card title={$t('campaign.title')}>
      <div style={{ marginBottom: 8 }}>
        <CompCampaignSelect />
      </div>
      <Tabs
        tabBarStyle={{ margin: 0 }}
        defaultActiveKey='reward-capping'
        onChange={setTab}
        items={[
          {
            key: 'campaign-list',
            label: $t('campaign.title'),
            children: <ContCampaignTable />,
          },
          {
            key: 'mission',
            label: $t('mission.title'),
            children: <ContCampaignMission active={tab === 'mission'} />,
          },
          {
            key: 'user-group',
            label: $t('capping.userGroup.title'),
            children: <ContUserGroup active={tab === 'user-group'} />,
          },
          {
            key: 'prize-group',
            label: $t('capping.prizeGroup.title'),
            children: <ContPrizeGroup active={tab === 'prize-group'} />,
          },
          {
            key: 'mission-group',
            label: $t('capping.missionGroup.title'),
            children: <ContMissionGroup active={tab === 'mission-group'} />,
          },
        ]}
      />
    </Card>
  );
};
