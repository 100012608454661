import { FC } from 'react';
import { ContListTable, IListDetailField } from '../common/ContListTable';
import { Form, Select, Tag } from 'antd';
import { PRIZE_DELIVERY_METHOD_SELECTOR, usePrizeList } from '@/hooks/app/useRewardState';
import { rewardApis } from '@/actions/reward';

export const ContRewardDeliverMethod: FC<{}> = ({}) => {
  const { prizeList } = usePrizeList();

  const renderTable = () => {
    const detailFields: { [key: string]: IListDetailField } = {
      prizes: {
        title: 'Loại giải áp dụng',
        tableRender: (val) =>
          val.prizes
            .sort((a, b) => (a > b ? 1 : -1))
            .map((item) => (
              <Tag style={{ whiteSpace: 'pre-line' }} key={item} color='default'>
                {item}
              </Tag>
            )),
        formRender: (key, field) => (
          <Form.Item key={key} name={key} label={field.title}>
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              options={prizeList.map(({ id: value, name: label }) => ({ value, label }))}
            />
          </Form.Item>
        ),
      },
    };

    return (
      <ContListTable
        selector={`${PRIZE_DELIVERY_METHOD_SELECTOR}`}
        detailFields={detailFields}
        onCreate={(record) =>
          rewardApis.addPrizeDeliveryMethod({
            ...record,
            value: {
              prizes: [],
              ...record.value,
            },
          })
        }
        onEdit={rewardApis.updatePrizeDeliveryMethod}
        allowDelete={true}
      />
    );
  };

  return <>{renderTable()}</>;
};
