import { FC } from 'react';
import { ContListTable, IListDetailField } from '../common/ContListTable';
import { storageApis } from '@/actions/storage';
import { commonActions } from '@/actions/common';
import { DatePicker, Form, Modal } from 'antd';
import { CAMPAIGN_SELECTOR, useCampaignState } from '@/hooks/app/useCampaignState';
import { SwitchState } from '@/renderers/RendererSwitch';
import { VN_DATETIME_FORMAT, format, tz } from '@/moment';
import { campaignActions, campaignApis } from '@/actions/campaign';

export const ContCampaignTable: FC<{}> = ({}) => {
  const { currentCampaignId, setState: setCampaignState } = useCampaignState();
  const renderTable = () => {
    const toDateTime = (val) => (val ? format(val, VN_DATETIME_FORMAT) : val);
    const detailFields: { [key: string]: IListDetailField } = {
      campaignTime: {
        title: 'Thời hạn',
        tableRender: (val) =>
          !val['startDate'] || !val['endDate'] ? null : (
            <>
              {`${toDateTime(val['startDate'])} -> `}
              <br />
              {toDateTime(val['endDate'])}
            </>
          ),
        formRender: (key: string, field: IListDetailField) => (
          <Form.Item
            key={key}
            name={key}
            label={field.title}
            rules={[{ required: true, message: 'bắt buộc' }]}>
            <DatePicker.RangePicker showTime={true} />
          </Form.Item>
        ),
      },
    };
    const additionalTableFields: { [key: string]: IListDetailField } = {
      isCurrent: {
        title: 'Đang chọn',
        type: 'string',
        tableRender: (_val, row) => (
          <SwitchState
            disabled={row.label === currentCampaignId}
            initialChecked={row.label === currentCampaignId}
            onChange={() => {
              if (!currentCampaignId) {
                setCampaignState({ currentCampaignId: row.label });
                return;
              }

              Modal.confirm({
                content: 'Bạn có chắc?',
                okText: 'OK',
                cancelText: 'Cancel',
                onOk: () => {
                  campaignActions.changeCampaign(row.label);
                },
              });
            }}
          />
        ),
      },
    };

    return (
      <ContListTable
        selector={CAMPAIGN_SELECTOR}
        detailFields={detailFields}
        additionalTableFields={additionalTableFields}
        datasourceTransform={(items) =>
          items.map((item) => ({
            ...item,
            value: {
              ...item.value,
              campaignTime: [
                !item.value.startDate ? null : tz(item.value.startDate),
                !item.value.endDate ? null : tz(item.value.endDate),
              ],
            },
          }))
        }
        onCreate={(listRecord) => {
          return storageApis
            .addList({
              unique: true,
              data: listRecord,
            })
            .then(async () => {
              await campaignApis.initCampaign({
                campaignName: listRecord.label.slice(`${CAMPAIGN_SELECTOR}-`.length),
              });
            });
        }}
        onEdit={(listRecord) => {
          const { campaignTime, ...listValues } = listRecord.value;
          listValues.startDate = campaignTime[0];
          listValues.endDate = campaignTime[1];
          return storageApis.updateList({ ...listRecord, value: listValues }).then(() => {
            commonActions.cmsLog(`Update campaign ${listRecord.label}`);
          });
        }}
      />
    );
  };

  return <>{renderTable()}</>;
};
