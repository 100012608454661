import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Descriptions,
  Form,
  FormProps,
  Input,
  message,
  Modal,
  Space,
  Spin,
  Table,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { CompPagination } from '@/components/CompPagination';
import { useTranslate } from '@/i18n';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useCommonState } from '@/hooks/app/useCommonState';

const DEFAULT_TEXTS = ($t: ReturnType<typeof useTranslate>) => ({
  addTitle: $t('common.form.add'),
  editTitle: $t('common.form.edit'),
  addButton: $t('common.form.add'),
  deleteButton: $t('common.form.remove'),
  okButton: $t('common.form.ok'),
  cancelButton: $t('common.form.cancel'),
  searchPlaceholder: $t('common.form.search'),
});

export enum EEditStates {
  NONE = 0,
  ADD,
  EDIT,
}

export interface IEditFormProps extends FormProps {
  editState: EEditStates;
  editRow?: any;
}

export interface ICompDataTableProps<T = any> {
  datasource: Array<T>;
  columns?: ColumnsType<ColumnType<T>> & { required?: boolean };
  totals: number;
  loading?: boolean;
  pagination?: boolean;
  editable?: boolean;
  buttons?: Array<JSX.Element>;
  countTotal?: boolean;
  texts?: Partial<ReturnType<typeof DEFAULT_TEXTS>>;
  mobileMode?: boolean;
  editingForm?: (props: IEditFormProps) => JSX.Element;
  onCreate?: (item: T) => void;
  onDelete?: (rows: T[]) => void;
  onEdit?: (row: T) => void;
  onChange?: (
    pagination: any | null,
    filters: any | null,
    sorter: any | null,
    extray: any | null
  ) => void;
  onPagination?: (page: number, pageSize?: number) => void;
  onSearch?: (search: string) => void;
  onSearchEdit?: (search: string) => void;
  onCountTotalCheckChange?: (isChecked: boolean) => void;
}

export const CompDataTable: React.FC<ICompDataTableProps> = (props: ICompDataTableProps) => {
  const {
    datasource = [],
    editable,
    loading,
    buttons,
    pagination = true,
    totals,
    countTotal,
    columns = Object.keys(datasource[0] || {}).map((key) => ({
      title: key,
      dataIndex: key,
    })),
    mobileMode = false,
    editingForm,
    onCountTotalCheckChange,
    onSearch,
    onSearchEdit,
    onChange,
    onCreate,
    onEdit,
    onDelete,
  } = props;
  const $t = useTranslate();
  const [state, setState] = useState(EEditStates.NONE);
  const [editRow, setEditRow] = useState(undefined);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [form] = Form.useForm();
  const [mobileSelectedRowKeys] = useState(new Set());
  const { isMobileSize } = useCommonState();
  const tableSource = datasource.map((a, i) => ({ ...a, key: a.id || i }));
  const texts = {
    ...DEFAULT_TEXTS($t),
    ...props.texts,
  };

  const selectAllMobileRows = (select: boolean) => {
    if (select) {
      tableSource.forEach((row) => mobileSelectedRowKeys.add(row.key));
    } else {
      mobileSelectedRowKeys.clear();
    }
  };
  useEffect(() => {
    selectAllMobileRows(false);
  }, [datasource]);

  const renderEditForm =
    editingForm ||
    (({ editState, editRow, ...args }: IEditFormProps) => {
      return (
        <Form {...args}>
          {columns.map((col: any, index: number) => {
            return (
              <Form.Item
                key={`${col.dataIndex}-${index}`}
                label={col.title}
                name={col.dataIndex}
                hasFeedback
                rules={[
                  {
                    required: col.required,
                    message: `Please input ${col.title}!`,
                  },
                ]}>
                <Input />
              </Form.Item>
            );
          })}
        </Form>
      );
    });

  const tableColumns = [
    ...columns,
    ...(editable
      ? [
          {
            width: 30,
            render(_: any, row: any) {
              if (_?.children) {
                return null;
              }
              return (
                <Button
                  type='link'
                  onClick={() => {
                    setState(EEditStates.EDIT);
                    setEditRow(row);
                    form.setFieldsValue(row);
                  }}>
                  <EditOutlined />
                </Button>
              );
            },
          },
        ]
      : []),
  ];

  const renderEditModal = () => {
    return (
      <Modal
        open={[EEditStates.ADD, EEditStates.EDIT].includes(state)}
        title={state === EEditStates.ADD ? texts.addTitle : texts.editTitle}
        onCancel={() => {
          setState(EEditStates.NONE);
        }}
        onOk={() => {
          form.submit();
        }}
        style={{ minWidth: !isMobileSize ? 800 : '90%' }}
        okText={texts.okButton}>
        {renderEditForm({
          editState: state,
          editRow,
          labelCol: { span: 6 },
          wrapperCol: { span: 12 },
          form,
          name: state === EEditStates.ADD ? texts.addTitle : texts.editTitle,
          onFinish: async (values: any) => {
            const { mobileRowTitle, ...editValues } = editRow || {};
            if (state === EEditStates.ADD) {
              onCreate && onCreate(values);
            }
            if (state === EEditStates.EDIT) {
              onEdit &&
                onEdit({
                  ...editValues,
                  ...values,
                });
            }
            setState(EEditStates.NONE);
            form.resetFields();
          },
          onFinishFailed: () => {},
        })}
      </Modal>
    );
  };

  const renderSearchInput = () => {
    if (!onSearch && !onSearchEdit) {
      return null;
    }

    const inputProps = {
      size: 'middle' as SizeType,
      placeholder: texts.searchPlaceholder,
      value: searchValue,
      onChange: (val) => {
        setSearchValue(val.target.value);
        onSearchEdit && onSearchEdit(val.target.value);
      },
      prefix: <SearchOutlined />,
    };

    return onSearch ? (
      <Input.Search {...inputProps} onSearch={(val) => onSearch(val)} />
    ) : (
      <Input {...inputProps} />
    );
  };

  const renderTopComponents = () => {
    if (loading) {
      return null;
    }

    const topButtons: Array<JSX.Element> = [];
    onDelete &&
      topButtons.push(
        <Button
          key='btn-delete'
          type='primary'
          icon={<DeleteOutlined />}
          danger
          onClick={() => {
            if (!selectedRows.length) {
              message.warn(`${$t('common.form.noSelectedRows')}.`);
              return;
            }
            Modal.confirm({
              title: `${$t('common.form.removeRowsConfirm').replace(
                '%count',
                `${selectedRows.length}`
              )}?`,
              icon: <ExclamationCircleOutlined />,
              okText: texts.okButton,
              okType: 'danger',
              cancelText: texts.cancelButton,
              onOk: () => {
                onDelete && onDelete(selectedRows);
                selectAllMobileRows(false);
                setSelectedRows([]);
              },
            });
          }}>
          {texts.deleteButton}
        </Button>
      );
    onCreate &&
      topButtons.push(
        <Button
          key='btn-create'
          type='primary'
          icon={<PlusOutlined />}
          onClick={() => {
            form && form.resetFields();
            setEditRow(undefined);
            setState(EEditStates.ADD);
          }}>
          {texts.addButton}
        </Button>
      );
    buttons && topButtons.push(...buttons.map((item, i) => ({ ...item, key: `top-button-${i}` })));

    return (
      <>
        <div
          style={{
            paddingTop: 10,
            paddingBottom: !onDelete && !onCreate ? 0 : 10,
            backgroundColor: 'transparent',
          }}>
          <Space wrap>{topButtons}</Space>
        </div>
        {renderSearchInput()}
      </>
    );
  };

  const renderTable = () => {
    if (loading) {
      return (
        <div>
          <Spin></Spin>
        </div>
      );
    }

    if (mobileMode) {
      const renderCards = (rows) => {
        return (
          <Space
            className='table-mobile-space'
            direction='horizontal'
            size={16}
            wrap={true}
            style={{ width: '100%', alignItems: 'flex-start' }}>
            {rows.map((row) => {
              const title = row.mobileRowTitle || row.label;
              return (
                <Card
                  key={row.key}
                  title={
                    !onDelete ? (
                      title
                    ) : (
                      <Checkbox
                        checked={mobileSelectedRowKeys.has(row.key)}
                        onChange={(e) => {
                          e.target.checked
                            ? mobileSelectedRowKeys.add(row.key)
                            : mobileSelectedRowKeys.delete(row.key);
                          setSelectedRows(
                            Array.from(mobileSelectedRowKeys)
                              .map((key) => tableSource.find((row) => row.key === key))
                              .filter((item) => item)
                          );
                        }}>
                        {title}
                      </Checkbox>
                    )
                  }
                  style={{ borderRadius: 10 }}
                  bodyStyle={{ padding: 0 }}>
                  {renderRow(row)}
                </Card>
              );
            })}
          </Space>
        );
      };

      const renderRow = (row) => {
        return (
          <Descriptions key={row.key} column={1} bordered size='small'>
            {tableColumns.map((column: any, i) => {
              const data = (
                Array.isArray(column.dataIndex) ? column.dataIndex : [column.dataIndex]
              ).reduce((total, key) => {
                if (total) {
                  return total[key];
                }
              }, row);

              return (
                <Descriptions.Item key={i} label={column.title}>
                  {!column.render ? data : column.render(data, row)}
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        );
      };

      const render = () => {
        if (tableSource.some((item) => item.children)) {
          return (
            <Collapse bordered={false} style={{ background: 'transparent' }}>
              {tableSource.map((row) => (
                <Collapse.Panel key={row.key} header={`${row.mobileRowTitle || row.label}`}>
                  {renderCards(row.children)}
                </Collapse.Panel>
              ))}
            </Collapse>
          );
        }
        return renderCards(tableSource);
      };
      return render();
    }

    return (
      <Table
        expandable={{
          defaultExpandAllRows: !isMobileSize,
        }}
        dataSource={tableSource}
        size='small'
        columns={tableColumns}
        tableLayout={'auto'}
        style={{ maxHeight: '60vh', overflow: 'auto' }}
        rowSelection={
          onDelete && {
            type: 'checkbox',
            selectedRowKeys: selectedRows.map(({ key }) => key),
            onChange: (_, rows: any[]) => {
              setSelectedRows(rows);
            },
          }
        }
        pagination={false}
        onChange={onChange ? onChange : () => {}}
      />
    );
  };

  const renderPagination = () => {
    return (
      <div>
        <Space style={{ marginTop: 12, float: 'right' }}>
          <div>
            {pagination && <CompPagination total={totals || 0} />}
            {onCountTotalCheckChange && (
              <>
                <br />
                <Checkbox
                  style={{ marginTop: 6, float: 'right' }}
                  checked={countTotal}
                  onChange={(event) => onCountTotalCheckChange(event.target.checked)}>
                  Count total
                </Checkbox>
              </>
            )}
          </div>
        </Space>
        &nbsp;
      </div>
    );
  };

  return (
    <>
      {renderEditModal()}
      {renderTopComponents()}
      {renderTable()}
      {renderPagination()}
    </>
  );
};
