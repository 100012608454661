import { useEffect } from 'react';
import { useDynamicState } from './useDynamicState';
import { settingActions } from '@/actions/setting';
import { ILoyaltySettings } from '@/types/setting.types';

export const APP_SETTINGS_SELECTOR = 'app-settings';
export const LOYALTY_SETTING_NAME = 'app-settings-loyalty';

export const useSetting = (name: string) => {
  const { setState, ...state } = useDynamicState(APP_SETTINGS_SELECTOR, {});
  useEffect(() => {
    settingActions.getSetting({ name });
  }, []);
  return state[name];
};

export const useLoyaltySettings = () => {
  const setting = useSetting(LOYALTY_SETTING_NAME);
  return {
    name: setting?.label,
    ...setting?.value,
  } as ILoyaltySettings;
};
