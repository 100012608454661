import { FC, useState } from 'react';
import { useEffectAsync } from '@/hooks/common/useEffectAsync';
import { Button, Spin } from 'antd';

export interface IContQueryProps {
  cacheKey?: string;
  params?: any & { refreshId?: number };
  loadingItem?: JSX.Element;
  displayErrorRetry?: boolean;
  action: (payload: any) => Promise<any>;
  render: (props: {
    params: any;
    loading: boolean;
    data: any;
    error?: any;
    status?: number;
    refresh: () => void;
  }) => JSX.Element | string | number;
}

const Query: FC<IContQueryProps> = (props: IContQueryProps) => {
  const {
    action,
    render,
    params,
    loadingItem = (
      <div>
        <Spin></Spin>
      </div>
    ),
    displayErrorRetry = true,
  } = props;
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [refreshId, setRefreshId] = useState(0);

  useEffectAsync(
    () => {
      setLoading(true);
      setData(null);
      setError(null);
      params && (params.refreshId = undefined);
      return action(params);
    },
    [params, refreshId],
    {
      onSuccess: setData,
      onError: setError,
      onFinally: () => setLoading(false),
    }
  );

  return (
    <>
      {isLoading && loadingItem}
      <div style={{ display: !isLoading ? 'inherit' : 'none' }}>
        {!error || !displayErrorRetry ? (
          render({
            loading: false,
            params,
            data,
            error,
            refresh: () => setRefreshId(Math.random()),
          })
        ) : (
          <>
            <div>{`Error: ${
              error.error ? JSON.stringify(error.error) : `${error.message || error}`
            }`}</div>
            <br />
            <Button onClick={() => setRefreshId(Math.random())}>Refresh</Button>
          </>
        )}
      </div>
    </>
  );
};

export const ContQuery = (props: IContQueryProps) => {
  const { cacheKey } = props;
  return <Query key={cacheKey} {...props} />;
};
