import { useEffect, useState } from 'react';
import { usePrev } from '@/hooks/common/usePrev';

export const useEffectAsync = (
  func: () => Promise<any>,
  dependencies: Array<any>,
  options: {
    onSuccess?: (res: any) => void;
    onError?: (err: any) => void;
    onFinally?: () => void;
    onCleanup?: () => void;
  }
) => {
  const prevDependencies = usePrev(dependencies);
  const { onSuccess, onError, onFinally, onCleanup } = options;
  const [state, setState] = useState({
    isFirstTrigger: false,
    promise: null,
    data: undefined,
    error: undefined,
  });
  const updateState = (data: Partial<typeof state>) => setState({ ...state, ...data });

  useEffect(() => {
    let isActive = true;
    let pro = state.promise;

    const request = () => {
      pro = func();
      updateState({ promise: pro, data: undefined, error: undefined });
    };

    if (state.isFirstTrigger) {
      updateState({ isFirstTrigger: false });
      request();
    } else if (JSON.stringify(prevDependencies) !== JSON.stringify(dependencies)) {
      request();
    }

    (pro || (state.error ? Promise.reject(state.error) : Promise.resolve(state.data)))
      .then((res) => {
        if (isActive) {
          updateState({ data: res });
          onSuccess && onSuccess(res);
        }
      })
      .catch((err) => {
        if (isActive) {
          updateState({ error: err });
          onError && onError(err);
        }
      })
      .finally(() => {
        if (isActive) {
          updateState({ promise: null });
          onFinally && onFinally();
        }
      });

    return () => {
      onCleanup && onCleanup();
      isActive = false;
    };
  }, dependencies);
};
