import { userProfileApis } from '@/actions/users-profile';
import { CompDataTable } from '@/components/CompDataTables';
import { CompDownloadCsv } from '@/components/CompDownloadCsv';
import { ContQuery } from '@/containers/common/ContQuery';
import { useCommonState } from '@/hooks/app/useCommonState';
import { useRewardState } from '@/hooks/app/useRewardState';
import { VN_DATETIME_FORMAT, format } from '@/moment';
import { IUserProfileInfo } from '@/types/user.types';
import { DatePicker, Tag } from 'antd';
import { FC, useEffect, useState } from 'react';

export const ContUserRewardLogs: FC<{ info: IUserProfileInfo }> = ({ info }) => {
  const { id: userId } = info;
  const { page, pageSize, isMobileSize } = useCommonState();
  const [dates, setDates] = useState(null);
  const { prizeList } = useRewardState();

  const getParams = () => ({
    where: {
      claimed_by: userId || 0,
      ...(!dates
        ? {}
        : {
            AND: [
              ...(!dates[0]
                ? []
                : [
                    {
                      claimed_at: {
                        gt: dates[0].startOf('day').toDate(),
                      },
                    },
                  ]),
              ...(!dates[1]
                ? []
                : [
                    {
                      claimed_at: {
                        lte: dates[1].endOf('day').toDate(),
                      },
                    },
                  ]),
            ],
          }),
    },
    skip: (page - 1) * pageSize,
    take: pageSize,
  });
  const [params, setParams] = useState(getParams());

  useEffect(() => {
    setParams(getParams());
  }, [dates, page, pageSize]);

  return (
    <>
      <DatePicker.RangePicker style={{ marginTop: 8 }} value={dates} onChange={setDates} />
      <ContQuery
        cacheKey={`user-reward-list-${userId}`}
        action={userProfileApis.userRewardList}
        params={params}
        render={(res) => {
          return (
            <div>
              <CompDataTable
                columns={[
                  {
                    title: 'ID',
                    dataIndex: 'id',
                  },
                  {
                    title: 'Ngày',
                    dataIndex: 'claimed_at',
                    render: (val) => format(val, VN_DATETIME_FORMAT),
                  },
                  {
                    title: 'Loại giải',
                    dataIndex: 'prize_id',
                    render: (val) => prizeList.find((prize) => prize.id === val)?.name || val,
                  },
                  {
                    title: 'Code',
                    dataIndex: 'claimed_info',
                    render: (val) => val?.code,
                  },
                  {
                    title: 'Trạng thái',
                    dataIndex: 'status',
                    render: (val) => {
                      const color = {
                        ERROR: 'error',
                        SUCCESS: 'green',
                        WAITING: 'orange',
                        RUNNING: 'processing',
                      };
                      return <Tag color={color[val] || 'default'}>{val}</Tag>;
                    },
                  },
                ]}
                datasource={(res.data?.items || []).map((item) => ({
                  ...item,
                  mobileRowTitle:
                    prizeList.find((prize) => prize.id === item.prize_id)?.name || item.prize_id,
                }))}
                totals={res.data?.totals || 0}
                mobileMode={isMobileSize}
                buttons={[
                  <CompDownloadCsv
                    style={{ marginBottom: 8 }}
                    validator={() => ({
                      isValid: params.where.AND?.length === 2,
                      message: 'Vui lòng chọn ngày',
                    })}
                    dataKeys={['id', 'claimed_at', 'prize_id', 'status']}
                    headers={['ID', 'Ngày', 'Loại giải', 'Trạng thái']}
                    fetchApi={async () => {
                      return userProfileApis
                        .userRewardList({
                          ...params,
                          skip: 0,
                          take: Number.MAX_SAFE_INTEGER,
                        })
                        .then((res) =>
                          (res.items || []).map((item) => ({
                            ...item,
                            claimed_at: format(item.claimed_at, VN_DATETIME_FORMAT),
                          }))
                        );
                    }}
                  />,
                ]}
              />
            </div>
          );
        }}
      />
    </>
  );
};
