import { commonApis } from '@/actions/common';
import { loginActions } from '@/actions/login';
import { useLoginState } from '@/hooks/app/useLoginState';
import { useNavState } from '@/hooks/app/useNavState';
import { PoweroffOutlined } from '@ant-design/icons';
import { Affix, Button, Layout, Menu } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { version } from '../../package.json';
import { CompImageAsync } from './CompImageAsync';
import { useTranslate } from '@/i18n';
import { useAppInitialzing, useCommonState } from '@/hooks/app/useCommonState';

const NAV_BAR_WIDTH = 250;
const NAV_BAR_COLLAPSED_WIDTH = 80;
const { Sider } = Layout;

export const CompNavMenu: React.FC<{}> = (props) => {
  const $t = useTranslate();
  const { scopes, logoImg } = useLoginState();
  const [backends, take] = React.useState(null);
  const history = useHistory();
  const { isMobileSize } = useCommonState();
  const { items, currentPathName, collapsed } = useNavState();
  const { appInitialize } = useAppInitialzing();
  const [, currentNav, ..._subPaths] = currentPathName.split('/');

  const getLogoSrc = useCallback(() => Promise.resolve(logoImg), [logoImg]);

  useEffect(() => {
    commonApis.ping().then(take);
  }, []);

  if (!appInitialize.initialized || isMobileSize) {
    return null;
  }

  return (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={NAV_BAR_WIDTH}
        collapsedWidth={NAV_BAR_COLLAPSED_WIDTH}>
        <div
          style={{
            position: 'relative',
            margin: '16px',
            borderRadius: '6px',
            visibility: !collapsed ? 'visible' : 'hidden',
          }}>
          <CompImageAsync
            width={'100%'}
            fallback={'/logofull.png'}
            action={getLogoSrc}
            preview={false}
          />
        </div>
        <Menu
          style={{
            position: 'relative',
            height: 'calc(100vh - 180px)',
            overflow: 'auto',
          }}
          theme='dark'
          mode='inline'
          items={items
            .filter(
              (item) =>
                !item.scopes || item.scopes.every((requiredScope) => scopes.includes(requiredScope))
            )
            .map((item) => ({ ...item, label: $t(item.label) } as any))}
          selectedKeys={[`/${currentNav}`]}
          onClick={(e) => {
            history.push(e.key);
          }}
        />
        <div
          className='ant-layout-sider-trigger'
          style={{
            width: !collapsed ? NAV_BAR_WIDTH : NAV_BAR_COLLAPSED_WIDTH,
            cursor: 'default',
          }}>
          <Affix
            style={{
              display: !collapsed ? 'inherit' : 'none',
            }}
            offsetBottom={0}>
            <div>
              {!collapsed && (
                <div style={{ marginBottom: '5px', lineHeight: 1.5 }}>
                  <span
                    style={{
                      whiteSpace: 'pre-line',
                    }}>{`cms@${version}\nbackends@${backends?.version}`}</span>
                </div>
              )}
              <Button
                icon={<PoweroffOutlined />}
                type='ghost'
                style={{ color: '#fff', margin: '0 8px', fontSize: 8 }}
                color='#fff'
                size='small'
                onClick={loginActions.logoutAndReload}
              />
            </div>
          </Affix>
        </div>
      </Sider>
    </>
  );
};
