import { useSelector } from 'redux-zero/react';
import { IAppState } from '@/hooks/app/store';
import { useEffect } from 'react';
import { IShopItem, IShopState } from '@/types/shop.types';
import { setState, shopActions } from '@/actions/shop';

export const SHOP_ITEM_SELECTOR = '%campaignId-shop-item';
export const BRANCH_SELECTOR = 'branch';
export const SHOP_ITEM_INIT_VALUES: Omit<IShopItem, 'name' | 'displayName'> = {
  description: '',
  accountName: '',
  price: 0,
  isActive: true,
  rewards: [],
  props: '',
};

export const useShopState = () => {
  return { ...useSelector((state: IAppState) => state.shopState), setState } as IShopState & {
    setState: (data: Partial<IShopState>) => void;
  };
};

export const useShopItemList = (refresh = true) => {
  const { shopItemList } = useShopState();
  refresh &&
    useEffect(() => {
      shopActions.listShopItem();
    }, []);
  return { shopItemList };
};

export const useBranchList = (refresh = true) => {
  const { branchList } = useShopState();
  refresh &&
    useEffect(() => {
      shopActions.listBranch();
    }, []);
  return { branchList };
};

export const useShopItems = (refresh = true) => {
  const { shopItemList } = useShopItemList(refresh);
  return {
    shopItems: shopItemList.items.map(
      ({ label, value }) => ({ name: label, ...value } as IShopItem)
    ),
  };
};
