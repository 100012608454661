import { commonApis } from '@/actions/common';
import { setState } from '@/actions/login';
import { CompImageUpload } from '@/components/CompImageUpload';
import { useLoginState } from '@/hooks/app/useLoginState';
import { Divider } from '@material-ui/core';
import { Image, Typography } from 'antd';
import { useState } from 'react';

const { Text } = Typography;

export const ContUploadLogo = () => {
  const { logoImg, clientId } = useLoginState();
  const [_logoImg, setLogoImg] = useState();

  return (
    <>
      <Text keyboard>{logoImg}</Text>
      <Divider style={{ margin: 20 }} />
      <Image preview src={logoImg} width={'200px'} height={'200px'} />
      <Divider style={{ margin: 20 }} />
      <CompImageUpload
        action={async () =>
          commonApis.getUploadUrl(`icons/${clientId}/logo`).then((res) => {
            setLogoImg(res);
            return res;
          })
        }
        resizeHeight={150}
        resizeWidth={250}
        onFinish={() => {
          setState({ logoImg: _logoImg });
          window.location.href = window.location.href;
          location.reload();
        }}
      />
    </>
  );
};
