import { Card, Space } from 'antd';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CompLinkButton } from '@/components/CompLinkButton';
import { ContCheckScopes } from '@/containers/common/ContCheckScopes';
import { ContPrizesTable } from '@/containers/rewards/ContPrizesTable';
import { ContRewardsTable } from '@/containers/rewards/ContRewardInventoryTable';
import { ContRewardScheduler } from '@/containers/rewards/ContRewardScheduler';
import { ContRewardStats } from '@/containers/rewards/ContRewardStats';
import { ContPrizeTypeTable } from '@/containers/rewards/ContPrizeTypeTable';
import { ContVoucherInventory } from '@/containers/rewards/ContVoucherInventory';
import { useTranslate } from '@/i18n';
import { usePrizeListWithTypes } from '@/hooks/app/useRewardState';
import { useCampaignState } from '@/hooks/app/useCampaignState';
import { ContFallbackPrizes } from '@/containers/rewards/ContFallbackPrizes';
import { ContRewardDeliverMethod } from '@/containers/rewards/ContRewardDeliverMethod';
import { CompCampaignSelect } from '@/containers/rewards/CompCampaignSelect';

interface IPageRewardsProps {}

export const PageRewards: React.FC<IPageRewardsProps> = (props: IPageRewardsProps) => {
  const { currentCampaignId } = useCampaignState();
  const $t = useTranslate();
  usePrizeListWithTypes(true, [currentCampaignId]);

  return (
    <Card title={$t('reward.title')}>
      <div style={{ marginBottom: 8 }}>
        <CompCampaignSelect />
      </div>
      {currentCampaignId && (
        <ContCheckScopes scopes={['admin', 'gift-admin']}>
          <div style={{ overflow: 'auto' }}>
            <Space size='small'>
              <CompLinkButton to={`/rewards/stats`}>{$t('reward.stats.title')}</CompLinkButton>
              <CompLinkButton to={`/rewards/prizeTypes`}>
                {$t('reward.prizeType.title')}
              </CompLinkButton>
              <CompLinkButton to={`/rewards/prizes`}>{$t('reward.prize.title')}</CompLinkButton>
              <CompLinkButton to={`/rewards/fallback-prizes`}>
                {$t('reward.prize.fallbackPrizes')}
              </CompLinkButton>
              <CompLinkButton to={`/rewards/delivery-method`}>
                {$t('reward.prize.deliveryMethod')}
              </CompLinkButton>
              <CompLinkButton to={`/rewards/voucher`}>{$t('reward.voucher.title')}</CompLinkButton>
              <CompLinkButton to={`/rewards/inventory`}>
                {$t('reward.inventory.title')}
              </CompLinkButton>
              <CompLinkButton to={`/rewards/schedule`}>
                {$t('reward.schedule.title')}
              </CompLinkButton>
            </Space>
          </div>
          <Switch>
            <Route path={`/rewards/stats`}>
              <ContRewardStats />
            </Route>
            <Route path={`/rewards/prizeTypes`}>
              <ContPrizeTypeTable />
            </Route>
            <Route path={`/rewards/prizes`}>
              <ContPrizesTable />
            </Route>
            <Route path={`/rewards/fallback-prizes`}>
              <ContFallbackPrizes />
            </Route>
            <Route path={`/rewards/delivery-method`}>
              <ContRewardDeliverMethod />
            </Route>
            <Route path={`/rewards/voucher`}>
              <ContVoucherInventory />
            </Route>
            <Route path={`/rewards/inventory`}>
              <ContRewardsTable />
            </Route>
            <Route path={`/rewards/schedule`}>
              <ContRewardScheduler />
            </Route>
            <Route>
              <Redirect to={`/rewards/stats`} />
            </Route>
          </Switch>
        </ContCheckScopes>
      )}
    </Card>
  );
};
