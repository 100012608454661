import { userProfileActions } from '@/actions/users-profile';
import { useCampaignState } from '@/hooks/app/useCampaignState';
import { usePrizeList } from '@/hooks/app/useRewardState';
import { request } from '@/wsgw';
import { Button, Card, Descriptions, Form, Input, Tabs } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';

export const PageTestPlayGame: React.FC = () => {
  const [playByTurnForm] = useForm();
  const [playByVoucherForm] = useForm();
  const [result, setResult] = useState(null);
  const { currentCampaignId } = useCampaignState();
  const { prizeList } = usePrizeList();
  const searchUser = (credential: string) => {
    return userProfileActions.searchUser(credential, 'user');
  };

  return (
    <>
      <Card title='Test play game'>
        <Tabs
          tabBarStyle={{ margin: 0 }}
          defaultActiveKey='play-game'
          items={[
            {
              key: 'play-by-turn',
              label: 'Use turn balance',
              children: (
                <Card>
                  <Form
                    form={playByTurnForm}
                    preserve={false}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 12 }}
                    onFinish={async ({ credential }) => {
                      setResult(null);
                      const result = await searchUser(credential)
                        .then(({ id: userId }) => {
                          return request('gami', 'testLuckyDraw', {
                            campaignId: currentCampaignId,
                            userId,
                            credential,
                          });
                        })
                        .catch((err) => err);
                      setResult(result);
                    }}>
                    <Form.Item
                      name='credential'
                      label='Credential'
                      rules={[{ required: true, message: 'bắt buộc' }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 12 }} labelAlign='right'>
                      <Button type='primary' htmlType='submit'>
                        Play
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              ),
            },
            {
              key: 'play-by-voucher',
              label: 'Use voucher',
              children: (
                <Card>
                  <Form
                    form={playByVoucherForm}
                    preserve={false}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 12 }}
                    onFinish={async ({ credential, voucherCode }) => {
                      setResult(null);
                      const result = await searchUser(credential)
                        .then(({ id: userId }) => {
                          return request('gami', 'testLuckyDraw', {
                            campaignId: currentCampaignId,
                            userId,
                            credential,
                            voucherCode,
                          });
                        })
                        .catch((err) => err);
                      setResult(result);
                    }}>
                    <Form.Item
                      name='credential'
                      label='Credential'
                      rules={[{ required: true, message: 'bắt buộc' }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name='voucherCode'
                      label='Code'
                      rules={[{ required: true, message: 'bắt buộc' }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 12 }} labelAlign='right'>
                      <Button type='primary' htmlType='submit'>
                        Play
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              ),
            },
          ]}
        />
      </Card>
      <Card title='Result'>
        {result && (
          <Descriptions column={1} bordered size='small'>
            {Object.entries(result).map(([key, value]) => (
              <Descriptions.Item key={`${key}`} label={key}>
                {JSON.stringify(value)}
              </Descriptions.Item>
            ))}
          </Descriptions>
        )}
      </Card>
    </>
  );
};
