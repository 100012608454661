import { Button, Form, Input, Modal } from 'antd';
import { commonActions } from '@/actions/common';
import { useTranslate } from '@/i18n';
import { loginApis, loginActions } from '@/actions/login';
import { useRequestActionState } from '@/hooks/common/useRequestActionState';

export const ContChangePassword: React.FC = () => {
  const $t = useTranslate();
  const [form] = Form.useForm();
  const { loading, request: changePasswordAction } = useRequestActionState(
    loginApis.changePassword
  );

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      name='login'
      form={form}
      onFinish={async (values) => {
        if (loading) {
          return;
        }
        await changePasswordAction({
          secret: values.currentPassword,
          SECRET: values.password,
        }).then(() => {
          commonActions.cmsLog('Đổi mật khẩu');
          Modal.info({
            content: 'Success. Please login again.',
            onOk: loginActions.logoutAndReload,
          });
        });
      }}>
      <Form.Item
        name='currentPassword'
        label={$t('settings.changePassword.currentPassword')}
        rules={[
          {
            required: true,
            message: 'Please input your current password!',
          },
        ]}
        hasFeedback>
        <Input.Password />
      </Form.Item>
      <Form.Item
        name='password'
        label={$t('settings.changePassword.newPassword')}
        rules={[
          {
            required: true,
            message: 'Please input new password!',
          },
        ]}
        hasFeedback>
        <Input.Password />
      </Form.Item>

      <Form.Item
        name='confirm'
        label={$t('settings.changePassword.confirmPassword')}
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm new password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}>
        <Input.Password />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 12,
            offset: 6,
          },
        }}>
        <Button type='primary' htmlType='submit'>
          {$t('settings.changePassword.confirmChange')}
        </Button>
      </Form.Item>
    </Form>
  );
};
