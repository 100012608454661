// import 'react-app-polyfill/ie11';
// import 'core-js/features/array/find';
// import 'core-js/features/array/includes';
// import 'core-js/features/number/is-nan';
// import 'core-js/features/string/replace-all';

String.prototype.replaceAll = function (str, newStr) {
  return this;
};

export {};
