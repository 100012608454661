import { connectWsgw } from '@elofun/node-wsgw-client';
import { message } from 'antd';
import { WSGW_HOST } from '@/conf';
import { commonHelpers } from './actions/common';

export interface IRequestState {
  loading: boolean;
  error: string;
  data: any;
}

export interface IRequestOptions {
  timeout?: number;
  indicator?:
    | boolean
    | {
        start?: boolean;
        endSuccess?: boolean;
        endError?: boolean;
      };
}

export const wsgw = connectWsgw(WSGW_HOST);

const requestMarks = [];
export const request = (
  service: string,
  api: string,
  data: any = {},
  options?: IRequestOptions
) => {
  const { timeout = 10000 } = options || {};
  const indicator = {
    start: true,
    endSuccess: false,
    endError: true,
    ...(options?.indicator || ({} as any)),
  };
  typeof options?.indicator === 'boolean' &&
    Object.keys(indicator).forEach((key) => (indicator[key] = options?.indicator));
  const ts = Date.now();
  const color = commonHelpers.randomColors();
  indicator.start &&
    requestMarks.push(null) === 1 &&
    message.open({
      content: 'Loading...',
      type: 'loading',
      key: `common`,
      duration: 99,
    });
  localStorage.getItem('debug') &&
    console.log(`%c ${[service, api].join(' | ')}`, `color:${color};`, data);

  return wsgw
    .then((wsgw) =>
      wsgw.request(service, api, data, timeout).then((res) => {
        localStorage.getItem('debug') &&
          console.log(`%c ${[service, api, Date.now() - ts].join(' | ')}`, `color:${color};`, res);
        indicator.endSuccess &&
          message.open({
            content: 'Success',
            type: 'success',
            duration: 1,
          });
        return res;
      })
    )
    .catch((err) => {
      localStorage.getItem('debug') &&
        console.log(
          `%c ${[service, api, JSON.stringify(data), Date.now() - ts].join(' | ')}`,
          `color:${color};`,
          err
        );
      const msg = `${err?.error ? JSON.stringify(err?.error) : err?.message || err}`.slice(0, 150);
      indicator.endError &&
        message.open({
          content: `Error: ${msg}`,
          type: 'error',
          duration: 3,
        });
      throw err;
    })
    .finally(() => {
      indicator.start && requestMarks.pop();
      !requestMarks.length && message.destroy(`common`);
    });
};

export const mutateRequest = (
  service: string,
  api: string,
  data: any = {},
  options?: IRequestOptions
) => {
  return request(service, api, data, { ...options, indicator: true });
};
