import { campaignActions } from '@/actions/campaign';
import { useAllCampaignList, useCampaignState } from '@/hooks/app/useCampaignState';
import { Select } from 'antd';
import { FC } from 'react';

export const CompCampaignSelect: FC = () => {
  const { campaignList } = useAllCampaignList();
  const { currentCampaignId, setState } = useCampaignState();
  return (
    <Select
      style={{ width: '100%' }}
      defaultValue={!currentCampaignId.length ? null : currentCampaignId}
      placeholder='Select campaign'
      options={campaignList.items.map((list) => ({
        label: list.value.displayName,
        value: list.label,
      }))}
      onChange={(val) => {
        if (!currentCampaignId) {
          setState({ currentCampaignId: val });
          return;
        }

        campaignActions.changeCampaign(val);
      }}
    />
  );
};
