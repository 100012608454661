import { Tag } from 'antd';
import { FC, useState } from 'react';
import {
  useGroupedPrizeList,
  useLimitedPrizeList,
  useRewardState,
} from '@/hooks/app/useRewardState';
import { rewardActions } from '@/actions/reward';
import { useEffectAsync } from '@/hooks/common/useEffectAsync';
import { CompDataTable } from '@/components/CompDataTables';
import { useCommonState } from '@/hooks/app/useCommonState';

export const ContRewardStats: FC = () => {
  const { prizeTypes, prizeList } = useRewardState();
  const { limitedPrizeList } = useLimitedPrizeList();
  const { groupedPrizeList } = useGroupedPrizeList();
  const [inventories, setInventories] = useState({});
  const [rewardCounts, setRewardCounts] = useState<{ [prizeId: string]: number }>({});
  const { isMobileSize } = useCommonState();

  useEffectAsync(
    () => {
      return Promise.all([
        rewardActions.countWarehouse({ prizeIds: limitedPrizeList.map((item) => item.id) }),
        rewardActions.countReward({ prizeIds: limitedPrizeList.map((item) => item.id) }),
      ]);
    },
    [prizeList],
    {
      onSuccess: ([inventoryArr, rewardCountArr]) => {
        setInventories(
          inventoryArr.reduce((total, item) => ({ ...total, [item.label]: item.amount }), {})
        );
        setRewardCounts(
          rewardCountArr.reduce((total, item) => ({ ...total, [item.prize_id]: item.count }), {})
        );
      },
    }
  );

  return (
    <CompDataTable
      columns={[
        {
          dataIndex: 'name',
          key: 'name',
          title: 'Tên giải thưởng',
          render: (val, row: any) =>
            !row.children ? val : prizeTypes.find((item) => item.id === val)?.name || val,
        },
        {
          dataIndex: 'id',
          key: 'id',
          title: 'Mã giải thưởng',
        },
        {
          dataIndex: 'droprate',
          title: 'Xác xuất trúng (%)',
          align: 'center',
          render(val) {
            return val === undefined ? null : (
              <Tag color={val ? 'green' : 'default'}>{`${Number(val)}%`}</Tag>
            );
          },
        },
        {
          dataIndex: 'id',
          title: 'Số lượng quà đã trả',
          align: 'center',
          render(val, obj) {
            const count = rewardCounts[val] || 0;
            return val === undefined ? null : (
              <Tag color={count ? 'gold' : 'default'}>{count.toLocaleString()}</Tag>
            );
          },
        },
        {
          dataIndex: 'unlimited',
          title: 'Số lượng quà còn lại',
          align: 'center',
          render(val, obj: any) {
            const count = inventories[obj.id] || 0;
            return val === undefined ? null : val ? (
              <Tag color='orange'>{`không giới hạn`}</Tag>
            ) : (
              <Tag color={count === 0 ? 'red' : 'green'}>{count}</Tag>
            );
          },
        },
        {
          dataIndex: 'active',
          title: 'Trạng thái',
          align: 'center',
          render(val) {
            return val === undefined ? null : (
              <Tag color={(val && 'green') || 'error'}>{(val && 'Đang bật') || `Đang tắt`}</Tag>
            );
          },
        },
      ]}
      datasource={groupedPrizeList.map((item) => ({
        ...item,
        children: item.children.map((item) => ({ ...item, mobileRowTitle: item.name })),
        mobileRowTitle: prizeTypes.find((prize) => prize.id === item.name)?.name || item.name,
      }))}
      totals={groupedPrizeList.length}
      pagination={false}
      mobileMode={isMobileSize}
    />
  );
};
