import { useSelector } from 'redux-zero/react';
import { IAppState } from '@/hooks/app/store';
import { campaignActions, setState } from '@/actions/campaign';
import { ICampaignState, IMission } from '@/types/campaign.types';
import { useEffect } from 'react';

export const CAMPAIGN_SELECTOR = 'campaign';
export const CAMPAIGN_MISSION_SELECTOR = '%campaignId-mission';
export const MISSION_INIT_VALUES: Omit<IMission, 'name' | 'displayName'> = {
  category: '',
  description: '',
  deeplink: '',
  display: false,
  isActive: false,
  rewards: [],
  props: '',
};

export const useCampaignState = () => {
  return {
    ...useSelector((state: IAppState) => state.campaignState),
    setState,
  } as ICampaignState & { setState: (data: Partial<ICampaignState>) => void };
};

export const useAllCampaignList = (refresh = true) => {
  const { campaignList } = useCampaignState();
  refresh &&
    useEffect(() => {
      campaignActions.getAllCampaigns();
    }, []);
  return { campaignList };
};

export const useMissionList = (refresh = true) => {
  const { missionList } = useCampaignState();
  refresh &&
    useEffect(() => {
      campaignActions.listMission();
    }, []);
  return { missionList };
};

export const useMissions = (refresh = true) => {
  const { missionList } = useMissionList(refresh);
  return {
    missions: missionList.items.map(({ label, value }) => ({ name: label, ...value } as IMission)),
  };
};
