import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input, InputNumber, Modal, Select, Switch, Tag, message } from 'antd';
import { FC } from 'react';
import { commonActions, commonApis, commonHelpers } from '@/actions/common';
import { useGroupedPrizeList, useRewardState } from '@/hooks/app/useRewardState';
import { rewardActions } from '@/actions/reward';
import { SwitchState } from '@/renderers/RendererSwitch';
import { CompDataTable, EEditStates, IEditFormProps } from '@/components/CompDataTables';
import { campaignHelpers } from '@/actions/campaign';
import { useCommonState } from '@/hooks/app/useCommonState';
import { CompInputNumber } from '@/components/CompInputNumber';
import TextArea from 'antd/lib/input/TextArea';
import { useLoginState } from '@/hooks/app/useLoginState';
import { CompImageAsync } from '@/components/CompImageAsync';
import { CompImageUpload } from '@/components/CompImageUpload';

export const ContPrizesTable: FC = () => {
  const { prizeTypes } = useRewardState();
  const { groupedPrizeList } = useGroupedPrizeList();
  const campaignPrefix = campaignHelpers.getCampaignPrefix();
  const { isMobileSize } = useCommonState();
  const { clientId } = useLoginState();

  const renderEditForm = ({ editState, editRow, ...formProps }: IEditFormProps) => {
    const { id: prizeId, active, unlimited } = editRow || {};
    return (
      <Form {...formProps}>
        <Form.Item name='type' label='Loại quà' rules={[{ required: true, message: 'bắt buộc' }]}>
          <Select
            disabled={editState === EEditStates.EDIT}
            options={prizeTypes
              .map((item) => ({ label: item.name, value: item.id }))
              .sort((a, b) => (a.label > b.label ? 1 : -1))}
          />
        </Form.Item>
        <Form.Item
          name='id'
          label='Mã giải thưởng'
          rules={[{ required: true, message: 'bắt buộc' }]}>
          <TextArea size='small' disabled />
        </Form.Item>
        <Form.Item
          name='name'
          label='Tên giải thưởng'
          rules={[{ required: true, message: 'bắt buộc' }]}>
          <Input
            onChange={(val) => {
              editState === EEditStates.ADD &&
                formProps.form.setFieldValue(
                  'id',
                  `${campaignPrefix}${commonHelpers.normalizeTitle(val.target.value)}`
                );
            }}
          />
        </Form.Item>
        <Form.Item
          name='amount'
          label='Hàm lượng'
          rules={[{ required: true, message: 'bắt buộc' }]}>
          <CompInputNumber min={1} />
        </Form.Item>
        <Form.Item
          name='priority'
          extra='(1 for highest priority)'
          label='Độ ưu tiên'
          rules={[{ required: true, message: 'bắt buộc' }]}>
          <InputNumber style={{ width: '100%' }} min={1} />
        </Form.Item>
        <Form.Item
          name='droprate'
          label='Xác xuất trúng (%)'
          rules={[{ required: true, message: 'bắt buộc' }]}>
          <InputNumber style={{ width: '100%' }} min={0} />
        </Form.Item>
        <Form.Item name='active' label='Trạng thái'>
          <SwitchState initialChecked={active ?? false} />
        </Form.Item>
        <Form.Item name='unlimited' label='Không giới hạn'>
          <SwitchState
            initialChecked={unlimited ?? false}
            disabled={editState === EEditStates.EDIT}
          />
        </Form.Item>
        {editState === EEditStates.EDIT && (
          <Form.Item name='icon' label='Icon'>
            <CompImageUpload
              style={{ width: 200 }}
              resizeWidth={200}
              action={(_file) => commonApis.getUploadUrl(`icons/${clientId}/${prizeId}`)}
            />
          </Form.Item>
        )}
      </Form>
    );
  };

  return (
    <CompDataTable
      columns={[
        {
          dataIndex: 'id',
          title: 'Icon',
          align: 'center',
          render: (val, row: any) => {
            if (!val) {
              return null;
            }
            return (
              <CompImageAsync
                width={100}
                action={async () => row.icon}
                uploadOptions={{
                  action: (_file) => commonApis.getUploadUrl(`icons/${clientId}/${val}`),
                  resizeWidth: 200,
                }}
              />
            );
          },
        },
        {
          dataIndex: 'name',
          key: 'name',
          title: 'Tên giải thưởng',
          width: 150,
          render: (val, row: any) =>
            !row.children ? val : prizeTypes.find((item) => item.id === val)?.name || val,
        },
        {
          dataIndex: 'id',
          key: 'id',
          title: 'Mã giải thưởng',
          width: 150,
        },
        {
          dataIndex: ['prizeType', 'name'],
          key: 'prizeType',
          title: 'Loại quà',
        },
        {
          dataIndex: ['amount'],
          key: 'amount',
          title: 'Hàm lượng',
          align: 'center',
          width: 100,
          render: (val) => (val === undefined ? null : Number(val).toLocaleString()),
        },
        {
          dataIndex: 'priority',
          title: 'Độ ưu tiên',
        },
        {
          dataIndex: 'droprate',
          title: 'Xác xuất trúng (%)',
        },
        {
          dataIndex: 'unlimited',
          title: 'Không giới hạn',
          align: 'center',
          render: (val) => (val === undefined ? null : <Checkbox checked={val} disabled />),
        },
        {
          dataIndex: 'active',
          title: 'Trạng thái',
          align: 'center',
          render: (val, row: any) =>
            val !== undefined && (
              <>
                <Switch
                  checked={val}
                  title={(val && 'Bật') || 'Tắt'}
                  onChange={(checked) => {
                    Modal.confirm({
                      title: `Bạn có chắc muốn ${checked ? 'bật' : 'tắt'} giải thưởng ${name}?`,
                      icon: <ExclamationCircleOutlined />,
                      okText: `Có (${checked ? 'Bật' : 'Tắt'})`,
                      okType: 'primary',
                      cancelText: 'Không (bỏ qua)',
                      onOk: () =>
                        rewardActions
                          .upsertPrize({ ...row, active: checked })
                          .then(() =>
                            commonActions.cmsLog(`${checked ? 'Bật' : 'Tắt'} thả quả giải ${name}`)
                          ),
                    });
                  }}
                />
                <div style={{ margin: 4 }}>
                  <Tag color={(val && 'green') || 'error'}>{(val && 'Đang bật') || `Đang tắt`}</Tag>
                </div>
              </>
            ),
        },
      ]}
      datasource={groupedPrizeList.map((item) => ({
        ...item,
        children: item.children.map((item) => ({ ...item, mobileRowTitle: item.name })),
        mobileRowTitle: prizeTypes.find((prize) => prize.id === item.name)?.name || item.name,
      }))}
      totals={groupedPrizeList.length}
      pagination={false}
      editable={true}
      mobileMode={isMobileSize}
      editingForm={renderEditForm}
      onCreate={(values) =>
        rewardActions
          .upsertPrize(values)
          .then(() => commonActions.cmsLog(`Thêm giải ${values.name}`))
      }
      onEdit={(values) =>
        rewardActions
          .upsertPrize(values)
          .then(() => commonActions.cmsLog(`Sửa loại quà ${values.name}`))
      }
    />
  );
};
