import { Switch, SwitchProps } from 'antd';
import { useEffect, useState } from 'react';

export const RendererSwitch = (val: boolean) => {
  return <Switch checked={val} disabled={true} />;
};

export const SwitchState: React.FC<
  SwitchProps & {
    initialChecked: boolean;
    onChange?: (val: boolean) => void;
  }
> = ({ initialChecked, onChange, ...props }) => {
  const [ownChecked, setOwnChecked] = useState(initialChecked);

  useEffect(() => {
    setOwnChecked(initialChecked);
  }, [initialChecked]);

  return (
    <Switch
      {...props}
      checked={ownChecked}
      onChange={(val) => {
        setOwnChecked(val);
        onChange && onChange(val);
      }}
    />
  );
};
