import { FC, useState } from 'react';
import { CompDataTable, EEditStates, IEditFormProps } from '@/components/CompDataTables';
import { usePrizeType, useUnlimitedPrizeList } from '@/hooks/app/useRewardState';
import { Form, Select } from 'antd';
import { rewardApis } from '@/actions/reward';
import { useCommonState } from '@/hooks/app/useCommonState';
import { useCampaignState } from '@/hooks/app/useCampaignState';
import { useEffectAsync } from '@/hooks/common/useEffectAsync';

export const ContFallbackPrizes: FC = () => {
  const { page, pageSize, isMobileSize } = useCommonState();
  const { prizeTypes } = usePrizeType();
  const { unlimitedPrizeList } = useUnlimitedPrizeList();
  const { currentCampaignId } = useCampaignState();
  const [fallbackPrizeList, setFallbackPrizeList] = useState({ totals: 0, items: [] });
  const [refreshId, setRefreshId] = useState(0);

  const getParams = () => {
    return {
      campaignId: currentCampaignId,
      skip: (page - 1) * pageSize,
      take: pageSize,
    };
  };

  const refresh = () => setRefreshId(Math.random());

  useEffectAsync(
    async () => rewardApis.getFallbackPrizes(getParams()),
    [page, pageSize, refreshId],
    {
      onSuccess: (res) => {
        setFallbackPrizeList(res);
      },
    }
  );

  const renderEditForm = ({ editState, editRow, ...formProps }: IEditFormProps) => (
    <Form {...formProps}>
      <Form.Item
        name='prizeId'
        label='Giải thưởng'
        rules={[{ required: true, message: 'bắt buộc' }]}>
        <Select
          mode='multiple'
          disabled={editState === EEditStates.EDIT}
          options={unlimitedPrizeList
            .map((item) => ({ label: item.name, value: item.id }))
            .sort((a, b) => (a.label > b.label ? 1 : -1))}
        />
      </Form.Item>
    </Form>
  );

  return (
    <CompDataTable
      columns={[
        {
          dataIndex: 'prizeId',
          key: 'prizeId',
          title: 'Mã giải thưởng',
          width: 150,
        },
        {
          dataIndex: 'name',
          key: 'name',
          title: 'Tên giải thưởng',
          width: 150,
        },
        {
          dataIndex: 'type',
          key: 'type',
          title: 'Loại quà',
        },
      ]}
      datasource={fallbackPrizeList.items.map(({ id, label }) => {
        const prize = unlimitedPrizeList.find((prize) => prize.id === label);
        return {
          id,
          prizeId: label,
          name: prize?.name || label,
          type: prizeTypes.find((prizeType) => prizeType.id === prize?.type)?.name || label,
          mobileRowTitle: prize?.name || label,
        };
      })}
      totals={fallbackPrizeList.totals}
      editingForm={renderEditForm}
      onCreate={({ prizeId }) => {
        const prizeIds = !Array.isArray(prizeId) ? [prizeId] : prizeId;
        rewardApis.addFallbackPrizes({ campaignId: currentCampaignId, prizeIds }).finally(refresh);
      }}
      onDelete={(rows) => {
        rewardApis
          .removeFallbackPrizes({
            campaignId: currentCampaignId,
            listIds: rows.map((item) => item.id),
          })
          .finally(refresh);
      }}
      mobileMode={isMobileSize}
    />
  );
};
