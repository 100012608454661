import { FC } from 'react';
import { QrReader } from 'react-qr-reader';

export const CompQrScanner: FC<{
  active: boolean;
  onDetected: (val: string) => void;
}> = ({ active, onDetected }) => {
  return (
    <div>
      {active && (
        <QrReader
          constraints={{ facingMode: 'environment' }}
          onResult={(result, _error) => {
            if (result) {
              const text = result.getText();
              if (text) {
                onDetected(text);
              }
            }
          }}
        />
      )}
    </div>
  );
};
