import momentjs from 'moment';
import moment from 'moment-timezone';

export const UTC_OFFSET = 7;
export const TIMEZONE = 'Asia/Ho_Chi_Minh';
export const TZ_FORMAT = 'DD/MM/YYYY HH:mm:ss.SSS';
export const VN_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const tz = (date?: moment.MomentInput, format?: string) => {
  return moment(date, format).tz(TIMEZONE).utcOffset(UTC_OFFSET);
};

export const format = (date?: moment.MomentInput, format = TZ_FORMAT) => {
  return tz(date).format(format);
};

momentjs.locale('vi');
