import { Card, Tabs } from 'antd';
import React from 'react';
import { useTranslate } from '@/i18n';
import { ContShop } from '@/containers/shop/ContShop';
import { ContBranch } from '@/containers/shop/ContBranch';

export const PageShop: React.FC = () => {
  const $t = useTranslate();
  return (
    <Card title={$t('shop.title')}>
      <Tabs
        tabBarStyle={{ margin: 0 }}
        defaultActiveKey='shop-item'
        items={[
          {
            key: 'shop-item',
            label: $t('shop.shopItem'),
            children: <ContShop />,
          },
          {
            key: 'branch',
            label: $t('shop.branch'),
            children: <ContBranch />,
          },
        ]}
      />
    </Card>
  );
};
