import { useCommonState } from './useCommonState';
import { useLoginState } from './useLoginState';
import { useEffect } from 'react';
import { cappingActions, cappingHelpers, setState } from '@/actions/capping';
import { useSelector } from 'redux-zero/react';
import { IAppState } from './store';
import { ICappingState } from '@/types/capping.types';
import { useCampaignState } from './useCampaignState';

export const USER_GROUP_SELECTOR = '%campaignId-user-group';
export const PRIZE_GROUP_SELECTOR = '%campaignId-prize-group';
export const MISSION_GROUP_SELECTOR = '%campaignId-mission-group';

export enum CappingPeriod {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  OVERALL = 'OVERALL',
}

export enum CappingTarget {
  NORMAL = 'NORMAL',
}

export enum CappingGroup {
  INDIVIDUAL = 'INDIVIDUAL',
  OVERALL = 'OVERALL',
}

export const useCappingState = () => {
  return {
    ...useSelector((state: IAppState) => state.cappingState),
    setState,
  } as ICappingState & { setState: (data: Partial<ICappingState>) => void };
};

export const useUserGroupList = (refresh = true) => {
  const { page, pageSize } = useCommonState();
  const { clientId } = useLoginState();
  const { userGroupList } = useCappingState();
  const { currentCampaignId } = useCampaignState();

  refresh &&
    useEffect(
      () =>
        cappingActions.getUserGroupList(
          cappingHelpers.getGroupParams(
            page,
            pageSize,
            clientId,
            USER_GROUP_SELECTOR.replace('%campaignId', currentCampaignId)
          )
        ),
      []
    );
  return { userGroupList };
};

export const usePrizeGroupList = (refresh = true) => {
  const { page, pageSize } = useCommonState();
  const { clientId } = useLoginState();
  const { prizeGroupList } = useCappingState();
  const { currentCampaignId } = useCampaignState();

  refresh &&
    useEffect(
      () =>
        cappingActions.getPrizeGroupList(
          cappingHelpers.getGroupParams(
            page,
            pageSize,
            clientId,
            PRIZE_GROUP_SELECTOR.replace('%campaignId', currentCampaignId)
          )
        ),
      []
    );
  return { prizeGroupList };
};

export const useMissionGroupList = (refresh = true) => {
  const { page, pageSize } = useCommonState();
  const { clientId } = useLoginState();
  const { missionGroupList } = useCappingState();
  const { currentCampaignId } = useCampaignState();

  refresh &&
    useEffect(
      () =>
        cappingActions.getMissionGroupList(
          cappingHelpers.getGroupParams(
            page,
            pageSize,
            clientId,
            MISSION_GROUP_SELECTOR.replace('%campaignId', currentCampaignId)
          )
        ),
      []
    );
  return { missionGroupList };
};
