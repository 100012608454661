import store from '@/hooks/app/store';
import { ICampaignState } from '@/types/campaign.types';
import { IList } from '@/types/storage.types';
import { storageApis } from './storage';
import { mutateRequest } from '@/wsgw';
import { CAMPAIGN_MISSION_SELECTOR, CAMPAIGN_SELECTOR } from '@/hooks/app/useCampaignState';
import { userProfileApis } from './users-profile';
import { USER_CREDENTIAL_TYPE } from '@/hooks/app/useLoginState';
import crypto from 'crypto-js';
import {
  initCampaignState,
  initRewardState,
  initCappingState,
  initShopState,
} from '@/hooks/app/initState';

export const setState = (state: Partial<ICampaignState>) => {
  const prevState = store.getState().campaignState;
  store.setState({ campaignState: { ...prevState, ...state } });
};

export const campaignApis = {
  initCampaign: async (params: { campaignName: string }) =>
    mutateRequest('gami', 'initCampaign', params, { timeout: 60000 }),
  updateMission: async (params: IList) => mutateRequest('gami', 'updateMission', params),
  deleteMissions: async (params: { ids: Array<number> }) =>
    mutateRequest('gami', 'deleteMissions', params),
  completeMission: async (params: {
    id: number;
    credential?: string;
    paymentValue?: number;
    userId?: string;
  }) => {
    const userId =
      params.userId ||
      (await userProfileApis
        .searchUser({
          type: USER_CREDENTIAL_TYPE,
          credential: crypto.MD5(params.credential).toString(crypto.enc.Base64),
        })
        .then(({ id }) => id));
    return mutateRequest('gami', 'completeMissionForUser', { ...params, userId });
  },
};

export const campaignActions = {
  changeCampaign: (campaignId: string) => {
    store.setState({
      rewardState: { ...initRewardState },
      cappingState: { ...initCappingState },
      campaignState: {
        ...initCampaignState,
        campaignList: store.getState().campaignState.campaignList,
      },
      shopState: { ...initShopState },
    });
    setState({ currentCampaignId: campaignId });
  },
  getCampaigns: (params?: { page: number; pageSize: number }) => {
    const state = store.getState();
    const { clientId } = state.loginState;
    const page = params?.page || state.commonState.page;
    const pageSize = params?.pageSize || state.commonState.pageSize;

    return storageApis
      .getList({
        where: {
          client_id: clientId,
          user_id: CAMPAIGN_SELECTOR,
          label: {
            not: '',
          },
        },
        skip: (page - 1) * pageSize,
        take: pageSize,
      })
      .then((campaignList) => {
        setState({ campaignList });
        return campaignList;
      });
  },
  getAllCampaigns: () => {
    return campaignActions.getCampaigns({ page: 1, pageSize: Number.MAX_SAFE_INTEGER });
  },
  listMission: () => {
    const { currentCampaignId } = store.getState().campaignState;
    const { clientId } = store.getState().loginState;
    return storageApis
      .getList({
        where: {
          client_id: clientId,
          user_id: CAMPAIGN_MISSION_SELECTOR.replace('%campaignId', currentCampaignId),
          label: {
            not: '',
          },
        },
        skip: 0,
        take: Number.MAX_SAFE_INTEGER,
      })
      .then((missionList) => {
        setState({ missionList });
        return missionList;
      });
  },
  createMission: (params: Omit<IList, 'id'>) => {
    const campaignPrefix = campaignHelpers.getCampaignPrefix();
    return storageApis.addList({
      unique: true,
      data: {
        ...params,
        user_id: params.user_id.replace(
          new RegExp(`^(?!${campaignPrefix})(.*)$`),
          `${campaignPrefix}$1`
        ),
      },
    });
  },
  updateMission: (...params: Parameters<typeof campaignApis.updateMission>) =>
    campaignApis.updateMission(...params),
  deleteMissions: (...params: Parameters<typeof campaignApis.deleteMissions>) =>
    campaignApis.deleteMissions(...params),
};

export const campaignHelpers = {
  getCampaignPrefix: () => {
    const { currentCampaignId } = store.getState().campaignState;
    return `${currentCampaignId}-`;
  },
};
